import  jsPDF from 'jspdf'
import 'jspdf-autotable'
const moment = require('moment')

const printModel = {
	model58(pedido, empresa){
		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [400, 400*1200/48]
		})

		const fontSize = 20
		const margin = 8

		//Add company name
		pdf.setFontSize(fontSize)
		actualHeightPadding+=20
		const companyName = pdf.splitTextToSize(`${empresa.razao.toUpperCase()}`, 280)
		// Define text size
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, companyName, 'center')
		const companyNameHeight = pdf.getTextDimensions(companyName).h
		actualHeightPadding+=companyNameHeight+5

		// Add company address
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`END.: ${empresa.address.toUpperCase()}`, pdf.internal.pageSize.getWidth()-50)
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, addressText, 'center')
		const addressTextHeight = pdf.getTextDimensions(addressText).h
		actualHeightPadding+=addressTextHeight+5

		// Add order id
		const cod_ped = `PEDIDO ${parseInt(pedido.id).toString().padStart(4, '0')}`
		pdf.setFontStyle('bold')
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, cod_ped, 'center')
		pdf.setFontStyle('bold')
		actualHeightPadding+=fontSize+15

		// Add company whatsapp
		pdf.setFontSize(fontSize)
		const wppText = pdf.splitTextToSize(`${empresa.phone && empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(margin, actualHeightPadding, wppText, 'left')

		// Add date and time
		const date = `${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('DD/MM/YYYY')}`
		const time = `${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('HH:mm')}`
		pdf.text(pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, `${date}  ${time}`, 'right')
		actualHeightPadding+=10

		// Insert separator
		pdf.setLineDash([4,2])
		pdf.setLineWidth(2)
		pdf.line(margin,actualHeightPadding,pdf.internal.pageSize.getWidth()-margin,actualHeightPadding)

		// Get the items of order
		const itensArray = [].concat(...pedido.items.map(item=>{
			return [
				[{content: item.name.toUpperCase(), colSpan: 4, styles: { overflow: 'linebreak', fontSize} }],
				...(item.compositions.map(c=>{
					return [{content: c.name, colSpan: 1, styles:{overflow: 'linebreak', fontSize, cellPadding: {top: 0, right: 0, bottom: 4, left:10}}}, {content: Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(c.quantity), styles: {halign: 'right'}} ]
				})),
				...((item.obs)?[[{ content: `Obs.: ${item.obs}`, colSpan: 4, styles: {overflow: 'linebreak', fontSize}}]]:[]),
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.quantity)), styles: {minCellWidth: 30, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: (Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.price)), styles: {minCellWidth: 70, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.total), styles: {minCellWidth: 70, overflow: 'hidden', fontSize, halign: 'right'}}
				]
			]
		}))

		actualHeightPadding+=fontSize*0.5

		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: margin, bottom: 0, left: margin},
			styles: {fontSize, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-(margin*2)),
			head: [[{content:'DESCRIÇÃO'}, {content: 'QTD.', styles: {halign: 'right'}}, {content: 'VR. UNIT.', styles: {halign: 'right'}}, {content: 'TOTAL', styles: {halign: 'right'}}]],
			body: itensArray,
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return (row.height+2)
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			}
		})

		actualHeightPadding += fontSize+5

		// Insert separator
		pdf.setLineDash([4,2])
		pdf.setLineWidth(2)
		pdf.setDrawColor('black')
		pdf.line(margin,actualHeightPadding,pdf.internal.pageSize.getWidth()-margin,actualHeightPadding)


		// Insert totals

		pdf.setFontStyle('bold')
		// Total sum of items values
		actualHeightPadding+=25
		const totalItemsText = `TOTAL DOS ITENS: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[0].value)}`
		pdf.text(totalItemsText, pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, 'right')

		// Shipping value
		actualHeightPadding+=fontSize
		const ShippingText = `FRETE: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[1].value)}`
		pdf.text(ShippingText, pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, 'right')

		// Absolute total
		actualHeightPadding+=fontSize
		const TotalText = `TOTAL: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[2].value)}`
		pdf.text(TotalText, pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, 'right')

		pdf.setFontStyle('normal')

		actualHeightPadding+=fontSize
		actualHeightPadding+=20

		const paymentText = `PAGAMENTO : ${pedido.payment_method}`
		pdf.text(paymentText, margin, actualHeightPadding, 'left')

		actualHeightPadding+=fontSize
		actualHeightPadding+=fontSize
		// Insert obs
		if(pedido.obs){
			// Title
			pdf.setFontStyle('bold')
			pdf.text('OBS. DO PEDIDO', margin, actualHeightPadding, 'left')
			// Content
			actualHeightPadding+=fontSize
			pdf.setFontStyle('normal')
			const obsMulti = pdf.splitTextToSize(pedido.obs.toUpperCase(), pdf.internal.pageSize.getWidth()-20)
			pdf.text(obsMulti, margin, actualHeightPadding, {maxWidth: pdf.internal.pageSize.getWidth()-30, align: 'justify'})
			// Calculates the height of the obs field
			const obsMultiHeight = pdf.getTextDimensions(obsMulti).h+10
			actualHeightPadding+=obsMultiHeight
		}

		// Insert separator
		pdf.setLineDash([4,2])
		pdf.setLineWidth(2)
		pdf.setDrawColor('black')
		pdf.line(margin,actualHeightPadding,pdf.internal.pageSize.getWidth()-margin,actualHeightPadding)

		// Insert customer information
		// Name

		actualHeightPadding+=20

		const tipoValue = 'TIPO: ' + (pedido.is_delivery===1?'ENTREGA':'RETIRADA')
		pdf.text(tipoValue, 5, actualHeightPadding, 'left')
		actualHeightPadding+=pdf.getTextDimensions(tipoValue).h

		actualHeightPadding+=20

		const customerName = pdf.splitTextToSize(`CLIENTE: ${pedido.firstname.toUpperCase()} ${pedido.lastname.toUpperCase()}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerName, margin, actualHeightPadding, 'left')
		// Calculates the height of the customer name's field
		const customerNameHeight = pdf.getTextDimensions(customerName).h
		actualHeightPadding+=customerNameHeight+5

		// Shipping Address
		const customerAddress = pdf.splitTextToSize(`ENDEREÇO: ${pedido.shipping_address_1.toUpperCase()}`,pdf.internal.pageSize.getWidth()-60)
		pdf.text(customerAddress, margin, actualHeightPadding, 'left')
		// Calculates the height of the customer address's field
		const customerAddressHeight = pdf.getTextDimensions(customerAddress).h
		actualHeightPadding+=customerAddressHeight+5

		// Shipping Address 2
		const customerAddress2 = pdf.splitTextToSize(`BAIRRO: ${pedido.shipping_address_2.toUpperCase()}`,pdf.internal.pageSize.getWidth()-60)
		pdf.text(customerAddress2, margin, actualHeightPadding, 'left')
		// Calculates the height of the customer address 2's field
		const customerAddress2Height = pdf.getTextDimensions(customerAddress2).h
		actualHeightPadding+=customerAddress2Height+5

		// Customer Whatsapp
		const customerPhone = pdf.splitTextToSize(`TELEFONE: ${pedido.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerPhone, margin, actualHeightPadding, 'left')
		// Calculates the height of the customerPhone's field
		const customerPhoneHeight = pdf.getTextDimensions(customerPhone).h
		actualHeightPadding+=customerPhoneHeight+5


		// Customer email
		const customerEmail = pdf.splitTextToSize(`EMAIL: ${pedido.email.toUpperCase()}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerEmail, margin, actualHeightPadding, 'left')
		// Calculates the height of the customerEmail's field
		const customerEmailHeight = pdf.getTextDimensions(customerEmail).h
		actualHeightPadding+=customerEmailHeight


		// Insert separator
		pdf.setLineDash([4,2])
		pdf.setLineWidth(2)
		pdf.setDrawColor('black')
		pdf.line(margin,actualHeightPadding,pdf.internal.pageSize.getWidth()-margin,actualHeightPadding)

		// Insert Deliveryman if exists

		actualHeightPadding+=20

		if(pedido['entregador.nome']){
			actualHeightPadding+=15
			pdf.setLineDash([200,2])
			pdf.setLineWidth(2)
			pdf.setDrawColor('black')
			pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
			actualHeightPadding+=15
			const deliveryman = pdf.splitTextToSize(`ENTREGADOR: ${pedido['entregador.nome']}`, pdf.internal.pageSize.getWidth()-15)
			pdf.text(deliveryman, pdf.internal.pageSize.getWidth()/2 - margin, actualHeightPadding, 'center')
			// Calculates the height of the deliveryman's field
			const deliverymanHeight = pdf.getTextDimensions(deliveryman).h
			actualHeightPadding+=deliverymanHeight
			pdf.setLineDash([2,1])
			pdf.setLineWidth(2)
			pdf.setDrawColor('black')
			pdf.line(0,actualHeightPadding,pdf.internal.pageSize.getWidth(),actualHeightPadding)
			actualHeightPadding+=15
		}

		// Insert id number again
		pdf.text(pdf.internal.pageSize.getWidth()-20, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		actualHeightPadding+=10
		pdf.setLineDash([200,0])
		pdf.setLineWidth(2)
		pdf.setDrawColor('black')
		pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-35,actualHeightPadding)
		actualHeightPadding+=fontSize
		const customerHandwriting = pdf.splitTextToSize(`CLIENTE: ${pedido.firstname.toUpperCase()} ${pedido.lastname.toUpperCase()}`, 280)
		pdf.text(customerHandwriting, pdf.internal.pageSize.getWidth()/2 - margin, actualHeightPadding, 'center')
		// Calculates the height of the customerHandwriting's field
		const customerHandwritingHeight = pdf.getTextDimensions(customerHandwriting).h
		actualHeightPadding+=customerHandwritingHeight+(fontSize*0.5)

		// Insert water mark of system creator

		pdf.setFontSize(fontSize*0.8)
		const waterMark = pdf.splitTextToSize('Sistema desenvolvido pela Naxtool Tecnologia <www.naxtool.com.br>', 280)
		pdf.text(waterMark, pdf.internal.pageSize.getWidth()/2, actualHeightPadding, 'center')

		// Generates output
		var output = pdf.output('blob')

		// Shows the output on a new window
		window.open(URL.createObjectURL(output))
	},
	model8001(pedido, empresa){
		// Define the table cell font-size
		const cellFontSize = 14

		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [400, 400*420/80]
		})

		//Add company name
		pdf.setFontSize(18)
		actualHeightPadding+=20
		const companyName = pdf.splitTextToSize(`${empresa.razao.toUpperCase()}`, 280)
		// Define text size
		pdf.text(5, actualHeightPadding, companyName, 'left')
		const companyNameHeight = pdf.getTextDimensions(companyName).h
		actualHeightPadding+=companyNameHeight+5

		// Add order id
		pdf.setFontSize(16)
		const cod_ped = `Pedido ${parseInt(pedido.id).toString().padStart(4, '0')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		// Add company address
		pdf.setFontSize(14)
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`END.: ${empresa.address.toUpperCase()}`, pdf.internal.pageSize.getWidth()-50)
		pdf.text(pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, addressText, 'center')
		const addressTextHeight = pdf.getTextDimensions(addressText).h
		actualHeightPadding+=addressTextHeight+15

		// Add company whatsapp
		const wppText = pdf.splitTextToSize(`WhatsApp: ${empresa.phone && empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(5, actualHeightPadding, wppText, 'left')

		// Add date and time
		pdf.setFontSize(13)
		const date = `Data: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('DD/MM/YYYY')}`
		const time = `Hora: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('HH:mm')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, `${date}  ${time}`, 'right')
		actualHeightPadding+=5

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Get the items of order
		const itensArray = [].concat(...pedido.items.map(item=>{
			return [
				[
					{content: item.name.toUpperCase(), colSpan: 4, styles: { overflow: 'linebreak', fontSize:cellFontSize} }
				],
				...(item.compositions.map(c=>{
					return [{content: c.name, colSpan: 1, styles:{overflow: 'linebreak', fontSize: cellFontSize, cellPadding: {top: 0, right: 0, bottom: 4, left:10}}}, {content: Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(c.quantity), styles: {halign: 'right'}} ]
				})),
				...((item.obs)?[[{ content: `Obs.: ${item.obs}`, colSpan: 4, styles: {overflow: 'linebreak', fontSize: cellFontSize}}]]:[]),
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR').format(item.quantity)), styles: {minCellWidth: 40, overflow: 'hidden', 	fontSize:cellFontSize}},
					{content: (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.price)), styles: 	{minCellWidth: 60, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.total), styles: {minCellWidth: 	70, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}}
				]
			]
		}))

		// Insert table

		// Define a variable to calculate the value of height after the table

		actualHeightPadding += 5

		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: 15, bottom: 0, left: 5},
			styles: {fontSize: 14, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-25),
			head: [['Descrição', 'Qtd.', 'Vr. Unit', 'Total']],
			body: itensArray,
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return (row.height+2)
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			}
		})

		actualHeightPadding += 15

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding+5,pdf.internal.pageSize.getWidth()-15,actualHeightPadding+5)

		// Insert totals

		// Total sum of items values
		actualHeightPadding+=25
		pdf.setFontSize(16)
		const totalItemsText = `Total dos itens: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[0].value)}`
		pdf.text(totalItemsText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Shipping value
		actualHeightPadding+=15
		const ShippingText = `Frete: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[1].value)}`
		pdf.text(ShippingText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Absolute total
		actualHeightPadding+=15
		const TotalText = `Total: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[2].value)}`
		pdf.text(TotalText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		actualHeightPadding+=15

		actualHeightPadding+=30

		const paymentText = `PAGAMENTO : ${pedido.payment_method}`
		pdf.text(paymentText, 15, actualHeightPadding, 'left')

		actualHeightPadding+=30
		// Insert obs
		if(pedido.obs){
			// Title
			pdf.setFontStyle('bold')
			pdf.text('Obs. do pedido', 5, actualHeightPadding, 'left')
			// Content
			actualHeightPadding+=15
			pdf.setFontStyle('normal')
			pdf.setFontSize(14)
			const obsMulti = pdf.splitTextToSize(pedido.obs, pdf.internal.pageSize.getWidth()-20)
			pdf.text(obsMulti, 5, actualHeightPadding, {maxWidth: pdf.internal.pageSize.getWidth()-30, align: 'justify'})
			// Calculates the height of the obs field
			const obsMultiHeight = pdf.getTextDimensions(obsMulti).h
			actualHeightPadding+=obsMultiHeight
		}

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Insert customer information
		pdf.setFontSize(18)

		// Name
		actualHeightPadding+=20

		const tipoValue = 'TIPO: ' + (pedido.is_delivery===1?'ENTREGA':'RETIRADA')
		pdf.text(tipoValue, 5, actualHeightPadding, 'left')
		actualHeightPadding+=pdf.getTextDimensions(tipoValue).h

		actualHeightPadding+=20

		const customerName = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`,280)
		pdf.text(customerName, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer name's field
		const customerNameHeight = pdf.getTextDimensions(customerName).h
		actualHeightPadding+=customerNameHeight+5

		// Shipping Address
		const customerAddress = pdf.splitTextToSize(`Endereço: ${pedido.shipping_address_1}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address's field
		const customerAddressHeight = pdf.getTextDimensions(customerAddress).h
		actualHeightPadding+=customerAddressHeight+5

		// Shipping Address 2
		const customerAddress2 = pdf.splitTextToSize(`Bairro: ${pedido.shipping_address_2}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress2, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address 2's field
		const customerAddress2Height = pdf.getTextDimensions(customerAddress2).h
		actualHeightPadding+=customerAddress2Height+5

		// Customer Whatsapp
		const customerPhone = pdf.splitTextToSize(`Telefone: ${pedido.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerPhone, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerPhone's field
		const customerPhoneHeight = pdf.getTextDimensions(customerPhone).h
		actualHeightPadding+=customerPhoneHeight+5


		// Customer email
		const customerEmail = pdf.splitTextToSize(`Email: ${pedido.email}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerEmail, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerEmail's field
		const customerEmailHeight = pdf.getTextDimensions(customerEmail).h
		actualHeightPadding+=customerEmailHeight


		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Insert Deliveryman if exists

		actualHeightPadding+=20

		if(pedido['entregador.nome']){
			actualHeightPadding+=15
			pdf.setLineDash([200,0])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
			actualHeightPadding+=15
			const deliveryman = pdf.splitTextToSize(`Entregador: ${pedido['entregador.nome']}`, pdf.internal.pageSize.getWidth()-15)
			pdf.text(deliveryman, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
			// Calculates the height of the deliveryman's field
			const deliverymanHeight = pdf.getTextDimensions(deliveryman).h
			actualHeightPadding+=deliverymanHeight
			pdf.setLineDash([2,1])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(0,actualHeightPadding,pdf.internal.pageSize.getWidth(),actualHeightPadding)
			actualHeightPadding+=15
		}
		// Insert id number again
		pdf.setFontSize(16)
		pdf.text(pdf.internal.pageSize.getWidth()-20, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		actualHeightPadding+=10
		pdf.setLineDash([200,0])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
		actualHeightPadding+=15
		const customerHandwriting = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`, 280)
		pdf.text(customerHandwriting, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
		// Calculates the height of the customerHandwriting's field
		const customerHandwritingHeight = pdf.getTextDimensions(customerHandwriting).h
		actualHeightPadding+=customerHandwritingHeight+5

		// Insert water mark of system creator

		pdf.setFontSize(10)
		const waterMark = pdf.splitTextToSize('Sistema desenvolvido pela Naxtool Tecnologia <www.naxtool.com.br>', 280)
		pdf.text(waterMark, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')

		// Generates output
		var output = pdf.output('blob')

		// Shows the output on a new window
		window.open(URL.createObjectURL(output))
	},
	model8002(pedido, empresa){
		// Define the table cell font-size
		const cellFontSize = 14

		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [400, 400*420/80]
		})

		//Add company name
		pdf.setFontSize(18)
		actualHeightPadding+=20
		const companyName = pdf.splitTextToSize(`${empresa.razao.toUpperCase()}`, 280)
		// Define text size
		pdf.text(5, actualHeightPadding, companyName, 'left')
		const companyNameHeight = pdf.getTextDimensions(companyName).h
		actualHeightPadding+=companyNameHeight+5

		// Add order id
		pdf.setFontSize(16)
		const cod_ped = `Pedido ${parseInt(pedido.id).toString().padStart(4, '0')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		// Add company address
		pdf.setFontSize(14)
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`END.: ${empresa.address.toUpperCase()}`, pdf.internal.pageSize.getWidth()-50)
		pdf.text(pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, addressText, 'center')
		const addressTextHeight = pdf.getTextDimensions(addressText).h
		actualHeightPadding+=addressTextHeight+15

		// Add company whatsapp
		const wppText = pdf.splitTextToSize(`WhatsApp: ${empresa.phone && empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(5, actualHeightPadding, wppText, 'left')

		// Add date and time
		pdf.setFontSize(13)
		const date = `Data: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('DD/MM/YYYY')}`
		const time = `Hora: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('HH:mm')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, `${date}  ${time}`, 'right')
		actualHeightPadding+=5

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Get the items of order
		const itensArray = [].concat(...pedido.items.map(item=>{
			return [
				[
					{content: `${item.name.toUpperCase()}${(item.compositions?.length>0)?' - ':''}${item.compositions.map(c=>{
						return `${c.name} ${parseInt(c.quantity)}`
					}).join(' - ')}${item.obs?` - Obs: ${item.obs}`:''}`, colSpan: 4, styles: { overflow: 'linebreak', fontSize:cellFontSize} }
				],
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR').format(item.quantity)), styles: {minCellWidth: 40, overflow: 'hidden', 	fontSize:cellFontSize}},
					{content: (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.price)), styles: 	{minCellWidth: 60, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.total), styles: {minCellWidth: 	70, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}}
				]
			]
		}))

		// Insert table

		// Define a variable to calculate the value of height after the table

		actualHeightPadding += 5

		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: 15, bottom: 0, left: 5},
			styles: {fontSize: 14, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-25),
			head: [['Descrição', 'Qtd.', 'Vr. Unit', 'Total']],
			body: itensArray,
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return (row.height+2)
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			}
		})

		actualHeightPadding += 15

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding+5,pdf.internal.pageSize.getWidth()-15,actualHeightPadding+5)

		// Insert totals

		// Total sum of items values
		actualHeightPadding+=25
		pdf.setFontSize(16)
		const totalItemsText = `Total dos itens: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[0].value)}`
		pdf.text(totalItemsText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Shipping value
		actualHeightPadding+=15
		const ShippingText = `Frete: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[1].value)}`
		pdf.text(ShippingText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Absolute total
		actualHeightPadding+=15
		const TotalText = `Total: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[2].value)}`
		pdf.text(TotalText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		actualHeightPadding+=15

		actualHeightPadding+=30

		const paymentText = `PAGAMENTO : ${pedido.payment_method}`
		pdf.text(paymentText, 15, actualHeightPadding, 'left')

		actualHeightPadding+=30
		// Insert obs
		if(pedido.obs){
			// Title
			pdf.setFontStyle('bold')
			pdf.text('Obs. do pedido', 5, actualHeightPadding, 'left')
			// Content
			actualHeightPadding+=15
			pdf.setFontStyle('normal')
			pdf.setFontSize(14)
			const obsMulti = pdf.splitTextToSize(pedido.obs, pdf.internal.pageSize.getWidth()-20)
			pdf.text(obsMulti, 5, actualHeightPadding, {maxWidth: pdf.internal.pageSize.getWidth()-30, align: 'justify'})
			// Calculates the height of the obs field
			const obsMultiHeight = pdf.getTextDimensions(obsMulti).h
			actualHeightPadding+=obsMultiHeight
		}

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Insert customer information
		pdf.setFontSize(18)

		// Name
		actualHeightPadding+=20

		const tipoValue = 'TIPO: ' + (pedido.is_delivery===1?'ENTREGA':'RETIRADA')
		pdf.text(tipoValue, 5, actualHeightPadding, 'left')
		actualHeightPadding+=pdf.getTextDimensions(tipoValue).h

		actualHeightPadding+=20

		const customerName = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`,280)
		pdf.text(customerName, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer name's field
		const customerNameHeight = pdf.getTextDimensions(customerName).h
		actualHeightPadding+=customerNameHeight+5

		// Shipping Address
		const customerAddress = pdf.splitTextToSize(`Endereço: ${pedido.shipping_address_1}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address's field
		const customerAddressHeight = pdf.getTextDimensions(customerAddress).h
		actualHeightPadding+=customerAddressHeight+5

		// Shipping Address 2
		const customerAddress2 = pdf.splitTextToSize(`Bairro: ${pedido.shipping_address_2}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress2, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address 2's field
		const customerAddress2Height = pdf.getTextDimensions(customerAddress2).h
		actualHeightPadding+=customerAddress2Height+5

		// Customer Whatsapp
		const customerPhone = pdf.splitTextToSize(`Telefone: ${pedido.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerPhone, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerPhone's field
		const customerPhoneHeight = pdf.getTextDimensions(customerPhone).h
		actualHeightPadding+=customerPhoneHeight+5


		// Customer email
		const customerEmail = pdf.splitTextToSize(`Email: ${pedido.email}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerEmail, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerEmail's field
		const customerEmailHeight = pdf.getTextDimensions(customerEmail).h
		actualHeightPadding+=customerEmailHeight


		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Insert Deliveryman if exists

		actualHeightPadding+=20

		if(pedido['entregador.nome']){
			actualHeightPadding+=15
			pdf.setLineDash([200,0])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
			actualHeightPadding+=15
			const deliveryman = pdf.splitTextToSize(`Entregador: ${pedido['entregador.nome']}`, pdf.internal.pageSize.getWidth()-15)
			pdf.text(deliveryman, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
			// Calculates the height of the deliveryman's field
			const deliverymanHeight = pdf.getTextDimensions(deliveryman).h
			actualHeightPadding+=deliverymanHeight
			pdf.setLineDash([2,1])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(0,actualHeightPadding,pdf.internal.pageSize.getWidth(),actualHeightPadding)
			actualHeightPadding+=15
		}
		// Insert id number again
		pdf.setFontSize(16)
		pdf.text(pdf.internal.pageSize.getWidth()-20, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		actualHeightPadding+=10
		pdf.setLineDash([200,0])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
		actualHeightPadding+=15
		const customerHandwriting = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`, 280)
		pdf.text(customerHandwriting, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
		// Calculates the height of the customerHandwriting's field
		const customerHandwritingHeight = pdf.getTextDimensions(customerHandwriting).h
		actualHeightPadding+=customerHandwritingHeight+5

		// Insert water mark of system creator

		pdf.setFontSize(10)
		const waterMark = pdf.splitTextToSize('Sistema desenvolvido pela Naxtool Tecnologia <www.naxtool.com.br>', 280)
		pdf.text(waterMark, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')

		// Generates output
		var output = pdf.output('blob')

		// Shows the output on a new window
		window.open(URL.createObjectURL(output))
	},
	model80(pedido, empresa){
		// Define the table cell font-size
		const cellFontSize = 14

		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [400, 400*420/80]
		})

		//Add company name
		pdf.setFontSize(18)
		actualHeightPadding+=20
		const companyName = pdf.splitTextToSize(`${empresa.razao.toUpperCase()}`, 280)
		// Define text size
		pdf.text(5, actualHeightPadding, companyName, 'left')
		const companyNameHeight = pdf.getTextDimensions(companyName).h
		actualHeightPadding+=companyNameHeight+5

		// Add order id
		pdf.setFontSize(16)
		const cod_ped = `Pedido ${parseInt(pedido.id).toString().padStart(4, '0')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		// Add company address
		pdf.setFontSize(14)
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`END.: ${empresa.address.toUpperCase()}`, pdf.internal.pageSize.getWidth()-50)
		pdf.text(pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, addressText, 'center')
		const addressTextHeight = pdf.getTextDimensions(addressText).h
		actualHeightPadding+=addressTextHeight+15

		// Add company whatsapp
		const wppText = pdf.splitTextToSize(`WhatsApp: ${empresa.phone && empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(5, actualHeightPadding, wppText, 'left')
		// Add date and time
		pdf.setFontSize(13)
		const date = `Data: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('DD/MM/YYYY')}`
		const time = `Hora: ${moment(pedido.createdAt.replace(/.\d{3}Z/g, '')).format('HH:mm')}`
		pdf.text(pdf.internal.pageSize.getWidth()-15, actualHeightPadding, `${date}  ${time}`, 'right')
		actualHeightPadding+=5
		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Get the items of order
		const itensArray = [].concat(...pedido.items.map(item=>{
			return [
				[
					{content: item.name.toUpperCase(), colSpan: 4, styles: { overflow: 'linebreak', fontSize:cellFontSize} }
				],
				...(item.compositions.map(c=>{
					return [{content: `${parseInt(c.quantity)}x`, styles: {halign: 'right'}}, {content: c.name, colSpan: 1, styles:{overflow: 'linebreak', fontSize: cellFontSize, cellPadding: {top: 0, right: 0, bottom: 4, left:10}}} ]
				})),
				...((item.obs)?[[{ content: `Obs.: ${item.obs}`, colSpan: 4, styles: {overflow: 'linebreak', fontSize: cellFontSize}}]]:[]),
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR').format(item.quantity)), styles: {minCellWidth: 40, overflow: 'hidden', 	fontSize:cellFontSize}},
					{content: (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.price)), styles: 	{minCellWidth: 60, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.total), styles: {minCellWidth: 	70, overflow: 'hidden', fontSize:cellFontSize, halign: 'right'}}
				]
			]
		}))

		// Insert table

		// Define a variable to calculate the value of height after the table

		actualHeightPadding += 5

		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: 15, bottom: 0, left: 5},
			styles: {fontSize: 14, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-25),
			head: [['Descrição', 'Qtd.', 'Vr. Unit', 'Total']],
			body: itensArray,
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return (row.height+2)
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			}
		})

		actualHeightPadding += 15

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding+5,pdf.internal.pageSize.getWidth()-15,actualHeightPadding+5)

		// Insert totals

		// Total sum of items values
		actualHeightPadding+=25
		pdf.setFontSize(16)
		const totalItemsText = `Total dos itens: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[0].value)}`
		pdf.text(totalItemsText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Shipping value
		actualHeightPadding+=15
		const ShippingText = `Frete: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[1].value)}`
		pdf.text(ShippingText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		// Absolute total
		actualHeightPadding+=15
		const TotalText = `Total: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totals[2].value)}`
		pdf.text(TotalText, pdf.internal.pageSize.getWidth()-15, actualHeightPadding, 'right')

		actualHeightPadding+=15

		actualHeightPadding+=30

		const paymentText = `PAGAMENTO : ${pedido.payment_method}`
		pdf.text(paymentText, 15, actualHeightPadding, 'left')

		actualHeightPadding+=30

		actualHeightPadding+=30
		// Insert obs
		if(pedido.obs){
			// Title
			pdf.setFontStyle('bold')
			pdf.text('Obs. do pedido', 5, actualHeightPadding, 'left')
			// Content
			actualHeightPadding+=15
			pdf.setFontStyle('normal')
			pdf.setFontSize(14)
			const obsMulti = pdf.splitTextToSize(pedido.obs, pdf.internal.pageSize.getWidth()-20)
			pdf.text(obsMulti, 5, actualHeightPadding, {maxWidth: pdf.internal.pageSize.getWidth()-30, align: 'justify'})
			// Calculates the height of the obs field
			const obsMultiHeight = pdf.getTextDimensions(obsMulti).h
			actualHeightPadding+=obsMultiHeight
		}

		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		// Insert customer information
		pdf.setFontSize(18)

		// Name
		actualHeightPadding+=20

		const tipoValue = 'TIPO: ' + (pedido.is_delivery===1?'ENTREGA':'RETIRADA')
		pdf.text(tipoValue, 5, actualHeightPadding, 'left')
		actualHeightPadding+=pdf.getTextDimensions(tipoValue).h

		actualHeightPadding+=20

		const customerName = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`,280)
		pdf.text(customerName, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer name's field
		const customerNameHeight = pdf.getTextDimensions(customerName).h
		actualHeightPadding+=customerNameHeight+5

		// Shipping Address
		const customerAddress = pdf.splitTextToSize(`Endereço: ${pedido.shipping_address_1}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address's field
		const customerAddressHeight = pdf.getTextDimensions(customerAddress).h
		actualHeightPadding+=customerAddressHeight+5

		// Shipping Address 2
		const customerAddress2 = pdf.splitTextToSize(`Bairro: ${pedido.shipping_address_2}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerAddress2, 5, actualHeightPadding, 'left')
		// Calculates the height of the customer address 2's field
		const customerAddress2Height = pdf.getTextDimensions(customerAddress2).h
		actualHeightPadding+=customerAddress2Height+5

		// Customer Whatsapp
		const customerPhone = pdf.splitTextToSize(`Telefone: ${pedido.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerPhone, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerPhone's field
		const customerPhoneHeight = pdf.getTextDimensions(customerPhone).h
		actualHeightPadding+=customerPhoneHeight+5


		// Customer email
		const customerEmail = pdf.splitTextToSize(`Email: ${pedido.email}`,pdf.internal.pageSize.getWidth()-50)
		pdf.text(customerEmail, 5, actualHeightPadding, 'left')
		// Calculates the height of the customerEmail's field
		const customerEmailHeight = pdf.getTextDimensions(customerEmail).h
		actualHeightPadding+=customerEmailHeight


		// Insert separator
		pdf.setLineDash([4,1])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(5,actualHeightPadding,pdf.internal.pageSize.getWidth()-15,actualHeightPadding)

		actualHeightPadding+=20

		if(pedido['entregador.nome']){
			actualHeightPadding+=15
			pdf.setLineDash([200,0])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
			actualHeightPadding+=15
			const deliveryman = pdf.splitTextToSize(`Entregador: ${pedido['entregador.nome']}`, pdf.internal.pageSize.getWidth()-15)
			pdf.text(deliveryman, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
			// Calculates the height of the deliveryman's field
			const deliverymanHeight = pdf.getTextDimensions(deliveryman).h
			actualHeightPadding+=deliverymanHeight
			pdf.setLineDash([2,1])
			pdf.setLineWidth(1.5)
			pdf.setDrawColor('black')
			pdf.line(0,actualHeightPadding,pdf.internal.pageSize.getWidth(),actualHeightPadding)
			actualHeightPadding+=15
		}
		// Insert id number again
		pdf.setFontSize(16)
		pdf.text(pdf.internal.pageSize.getWidth()-20, actualHeightPadding, cod_ped, 'right')
		actualHeightPadding+=20

		actualHeightPadding+=10
		pdf.setLineDash([200,0])
		pdf.setLineWidth(1.5)
		pdf.setDrawColor('black')
		pdf.line(35,actualHeightPadding,pdf.internal.pageSize.getWidth()-50,actualHeightPadding)
		actualHeightPadding+=15
		const customerHandwriting = pdf.splitTextToSize(`Cliente: ${pedido.firstname} ${pedido.lastname}`, 280)
		pdf.text(customerHandwriting, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')
		// Calculates the height of the customerHandwriting's field
		const customerHandwritingHeight = pdf.getTextDimensions(customerHandwriting).h
		actualHeightPadding+=customerHandwritingHeight+5

		// Insert water mark of system creator

		pdf.setFontSize(10)
		const waterMark = pdf.splitTextToSize('Sistema desenvolvido pela Naxtool Tecnologia <www.naxtool.com.br>', 280)
		pdf.text(waterMark, pdf.internal.pageSize.getWidth()/2 - 15, actualHeightPadding, 'center')

		// Generates output
		var output = pdf.output('blob')

		// Shows the output on a new window
		window.open(URL.createObjectURL(output))
	}
}

export default function(pedido, empresa){


	switch (empresa.print_model) {
		case 0:
			printModel.model80(pedido,empresa)
			break;
		case 1:
			printModel.model8001(pedido,empresa)
			break;
		case 2:
			printModel.model8002(pedido,empresa)
			break;
		case 3:
			printModel.model58(pedido,empresa)
			break;
		default:
			break;
	}

}
