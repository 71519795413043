import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import './style.css'
import {FaWhatsapp, FaHome} from 'react-icons/fa'
import {FiGrid} from 'react-icons/fi'
import api from '../../services/api'
const moment = require('moment')

function InfoHeader() {
	const [empresa, setEmpresa] = useState({})
	const [listOpeningHours, setListOpeningHours] = useState([])
	const [listHollydays, setlistHollydays] = useState([])

	useEffect(()=>{
		api.get('company').then(response=>{
			setEmpresa(response.data)
		})
		api.get(`/openingDays/`).then(response=>{
			setListOpeningHours(response.data)
		})
		api.get(`/hollyday/`).then(response=>{
			setlistHollydays(response.data)
		})
	}, [])


	function getWeekDay(){
		const weekday = new Array(7);
		weekday[0] = "Domingo";
		weekday[1] = "Segunda-feira";
		weekday[2] = "Terça-feira";
		weekday[3] = "Quarta-feira";
		weekday[4] = "Quinta-feira";
		weekday[5] = "Sexta-feira";
		weekday[6] = "Sábado";
		return weekday[(new Date()).getDay()]
	}

	function getOpeningHour(){
		const actualHollyday = listHollydays.find(l=>(`${l.date}/${(new Date()).getFullYear()}`)===moment(new Date()).format('DD/MM/YYYY'))

		if(actualHollyday){
			const dayOpeningHours = listOpeningHours.filter(lop=>lop.is_hollyday===1)

			if(dayOpeningHours.length>0){
				const nowTimeString = (`${((new Date).getHours()).toString().padStart(2,'0')}:${((new Date).getMinutes()).toString().padStart(2,'0')}:${((new Date).getSeconds()).toString().padStart(2,'0')}`)

				const currentInterval = dayOpeningHours.find(doh=>(doh.time_start<nowTimeString&&nowTimeString<doh.time_end))

				if(currentInterval){
					return <>{`Aberto`} &bull; {`Fecha às ${moment(currentInterval.time_end, 'H:mm:ss').format('HH:mm')}`}</>
				}
				else{
					return `Fechado`
				}
			}
			else{
				return 'Fechado'
			}
		}
		else{
			const dayOpeningHours = listOpeningHours.filter(lop=>parseInt(lop.week_day)===parseInt((new Date()).getDay()))

			if(dayOpeningHours.length>0){
				const nowTimeString = (`${((new Date).getHours()).toString().padStart(2,'0')}:${((new Date).getMinutes()).toString().padStart(2,'0')}:${((new Date).getSeconds()).toString().padStart(2,'0')}`)

				const currentInterval = dayOpeningHours.find(doh=>(doh.time_start<nowTimeString&&nowTimeString<doh.time_end))

				if(currentInterval){
					return <>{`Aberto`} &bull; {`Fecha às ${moment(currentInterval.time_end, 'H:mm:ss').format('HH:mm')}`}</>
				}
				else{
					return `Fechado`
				}
			}
			else{
				return `Fechado`
			}
		}
	}


	return (
		<div className="InfoHeader">
		  <div className="InfoContainer">
			  <div className="logo">
				  <img src={`./${empresa.logo}`} alt=""/>
			  </div>
			  <div className="info">
				<div className="brandMessage">
					<h1>{empresa.razao}</h1>
					PRAZER EM SERVIR
				</div>
				<div className={`infoSectors ${(empresa.show_wait_time===1?'':'timeShowed')}`}>
					<div className="sector">
						<h1>Onde estamos</h1>
						{empresa.address}
					</div>
					<div className="sector" style={{display: (empresa.show_wait_time===1?'':'none')}}>
						<h1>Tempo de espera</h1>
						{moment(empresa.wait_time, 'HH:mm:ss').format('HH:mm')}
					</div>
					<div className="sector">
						<h1>Horário de Atendimento</h1>
						{getWeekDay()}
						<p>{(empresa.closed===1)?'Fechado':getOpeningHour()}</p>
					</div>
					<div className="whatsapp">
						<div className="wp-button">
							<a href={`https://wa.me/55${empresa.phone}`} target="_black">
								<FaWhatsapp size={35} color="#01e675"/>
								Fale Conosco
							</a>
						</div>
						<div>
							<Link className={`switchButton`} to={document.location.pathname.includes('categorias')?'/':'categorias'}>
							{document.location.pathname.includes('categorias')?<FaHome/>:<FiGrid/>} {document.location.pathname.includes('categorias')?'Home':'Categorias'}
							</Link>
						</div>
					</div>
				</div>
			  </div>
		  </div>
		</div>
	  );
}

export default InfoHeader
