import React, {useState, useEffect, useRef} from 'react'
import './style.css';
import api from '../../services/api'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import {FaBars} from 'react-icons/fa'
import Chart from "chart.js"
import imgAbertos from './images/ABERTOS.png'
import imgCancelados from './images/CANCELADO.png'
import imgFinalizado from './images/FINALIZADO.png'
import imgPedidos from './images/PEDIDOS.png'

const moment = require('moment')

function Admin_dash(){
	const auth = localStorage.getItem('auth')
	const [dtInit, setDtInit] = useState(moment().set('date', 1).format('YYYYMMDD'))
	const [dtEnd, setDtEnd] = useState(moment().format('YYYYMMDD'))
	const [orders, setOrders] = useState([])
	const [counterOrdersByDay, setCounterOrdersByDay] = useState([])

	const chartRef = useRef()

	useEffect(()=>{
		const chartCtx = chartRef.current.getContext('2d')

		new Chart(chartCtx, {
			type: "line",
				data: {
					labels: [...counterOrdersByDay.map(data=>{
						return moment(data.day).format('DD/MM')
					})],
					datasets: [
						{
							label: "Pedidos",
							borderColor: "#20c1ed",
							fill: false,
							data: [...counterOrdersByDay.map(data=>{
								return data.pedidos
							})]
						},
						{
							label: "Abertos",
							borderColor: "#18a55d",
							fill: false,
							data: [...counterOrdersByDay.map(data=>{
								return data.abertos
							})]
						},
						{
							label: "Cancelados",
							borderColor: "#db6050",
							fill: false,
							data: [...counterOrdersByDay.map(data=>{
								return data.cancelados
							})]
						},
						{
							label: "Finalizados",
							borderColor: "#4287f5",
							fill: false,
							data: [...counterOrdersByDay.map(data=>{
								return data.finalizados
							})]
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					elements: {
						line: {
							tension: 0
						}
					},
					legend: {
						display:false
					},
					layout: {
						width: '100%'
					}
				}
		})
	}, [counterOrdersByDay])

	

	useEffect(()=>{
		api.get(`order/list?dtInit=${dtInit}&dtEnd=${dtEnd}`, {
			headers:{
				'auth': auth
			}
		}).then(response=>{
			setOrders(response.data)
			const DateDiff = parseInt(dtEnd)-parseInt(dtInit)
			const arrayDays = [...Array(DateDiff+1).keys()].map(day=>{
				return (day+parseInt(dtInit)).toString()
			}).filter(day=>{
				return moment(day).isValid()
			})

			const data = arrayDays.map(day=>{
				return {
					day,
					pedidos: response.data.filter(order=>moment(order.createdAt).format('YYYYMMDD')===day).length,
					abertos: response.data.filter(order=>(moment(order.createdAt).format('YYYYMMDD')===day&&order.order_status_id!==1&&order.order_status_id!==4)).length,
					cancelados: response.data.filter(order=>(moment(order.createdAt).format('YYYYMMDD')===day&&order.order_status_id===1)).length,
					finalizados: response.data.filter(order=>(moment(order.createdAt).format('YYYYMMDD')===day&&order.order_status_id===4)).length,
				}
			})
			
			setCounterOrdersByDay(data)

		}).catch(error=>{

		})
	}, [dtInit, dtEnd, auth])


	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	return (
	<>
	<div className="pageCompleteWithMenu">
	<aside className="menu active">
		<MenuToggleAdmin/>
	</aside>
	<div className="headerMenuAdm">
			<div className="containerAdmin">
				<div className="openMenu" onClick={toggleMenuActive}>
					<FaBars size={30} color="white"/>
				</div>
			</div>
	</div>
	<div className="content">
		<div className="body admBody" style={{marginTop: "50px"}}>
			<div className="dash">
				<div className="dashCounters">
					<div className="counter counterPedidos">
						<div className="textContent">
							{orders.length}
							<h1>Pedidos</h1>
						</div>
						<img src={imgPedidos} alt="Imagem pedidos"/>
					</div>
					<div className="counter counterAbertos">
						<div className="textContent">
							{orders.filter(order=>(order.order_status_id!==1&&order.order_status_id!==4)).length}
							<h1>Abertos</h1>
						</div>
						<img src={imgAbertos} alt="Imagem abertos"/>
					</div>
					<div className="counter counterCancelados">
						<div className="textContent">
							{orders.filter(order=>(order.order_status_id===1)).length}
							<h1>Cancelados</h1>
						</div>
						<img src={imgCancelados} alt="Imagem cancelados"/>
					</div>
					<div className="counter counterFinalizados">
						<div className="textContent">
							{orders.filter(order=>(order.order_status_id===4)).length}
							<h1>Finalizados</h1>
						</div>
						<img src={imgFinalizado} alt="Imagem finalizados"/>
					</div>
				</div>
				<div className="dates">
					Intervalo: 
					<input type="date" value={moment(dtInit).format('YYYY-MM-DD')} onChange={event=>{
						if(moment(event.target.value).isValid()){
							if(parseInt(moment(event.target.value).format('YYYYMMDD'))<parseInt(moment(dtEnd).format('YYYYMMDD'))){
								setDtInit(moment(event.target.value).format('YYYYMMDD'))
							}
							else{
								setDtInit(moment(dtEnd, "YYYYMMDD").subtract(1, 'days').format('YYYYMMDD'))
							}
						}
					}}/>
					a
					<input type="date" value={moment(dtEnd).format('YYYY-MM-DD')} onChange={event=>{
						if(moment(event.target.value).isValid()){
							if(parseInt(moment(dtInit).format('YYYYMMDD'))<parseInt(moment(event.target.value).format('YYYYMMDD'))){
								setDtEnd(moment(event.target.value).format('YYYYMMDD'))
							}
							else{
								setDtEnd(moment(dtInit, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD'))
							}
						}
					}}/>
				</div>
				<div className="chart">
					<canvas id="mainChart" ref={chartRef}/>
				</div>
			</div>
		</div>
	</div>
	</div>
	</>
	)
}

export default Admin_dash