import jsPDF from 'jspdf'
import 'jspdf-autotable'

function printTableAdm(pdfTitle, header, data, totals){
	const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
		format: 'a4'
	})

	// Print title
	pdf.setFontSize(14)
	pdf.setFontType('bold')
	pdf.text(pdfTitle.toUpperCase(), pdf.internal.pageSize.width / 2, 25, 'center')

	//Table
	var columns = header

	var rows = (totals)?[...data, [{content: totals.title, colSpan: header.length-totals.values.length}, ...totals.values]]:data

	pdf.setFontSize(12)
	pdf.setFontType('normal')
	pdf.autoTable(columns, rows, {
		startY: 35,
		headerStyles: {
			halign: 'center',
			valign: 'middle',
			lineWidth: 0.1,
			textColor: [0,0,0],
			fillColor: [180, 180, 180],
			lineColor: [80,80,80]
		},
		bodyStyles: {
			overflow: 'linebreak',
			lineWidth: 0.1,
			fillColor: [214,214,214]
		},
        margin: {top: 10, right: 10, bottom: 35, left: 10},
        styles: {fontSize: 9, cellPadding: 2},
		tableWidth: (pdf.internal.pageSize.getWidth()-20),
		alternateRowStyles: {
			fillColor: [255,255,255]
		}
	})
	
	//Print page number

	for(var i=0; i<pdf.getNumberOfPages();i++){
		pdf.setPage(i+1)
		pdf.setFontSize(8)
		pdf.setFontType('bold')
		pdf.text(`Página ${pdf.internal.getCurrentPageInfo().pageNumber} de ${pdf.getNumberOfPages()}`, pdf.internal.pageSize.width - 10, pdf.internal.pageSize.height-10, 'right')
	}


	var output = pdf.output('blob')

	window.open(URL.createObjectURL(output))
}

export default printTableAdm