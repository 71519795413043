import React, {useState, useRef} from 'react'
import api from '../../services/api'
import './style.css'


function RecoverPage(){
	const url = new URL(document.location)
	const params = new URLSearchParams(url.search)
	const token = params.get('token')

	const [newPass, setNewPass] = useState('')
	const [newPassRepeat, setNewPassRepeat] = useState('')

	const newPassInputRepeat = useRef()

	function recoverPassword(event){
		event.preventDefault()
		if(newPass===newPassRepeat){
			api.post('/customer/passwrod/reset', {
				newPass,
				token
			}).then(()=>{
				alert('Nova senha salva com sucesso!')
				document.location = url.origin
			})
			.catch(()=>{
				alert('Erro ao cadastrar senha')
			})
		}
		else{
			newPassInputRepeat
			.current
			.setCustomValidity("Senhas precisam ser iguais!")

			newPassInputRepeat
			.current
			.reportValidity()
		}
	}

	return (
		<div className="containerRecover">
		{
			token?
				<div className="formRecover">
					<h1 className="recoverPass">Recuperar senha</h1>
					<form action="" onSubmit={recoverPassword}>
						<div className="input-group">
							<input type="password" id="password" value={newPass} required onChange={event=>setNewPass(event.target.value)}/>
							<label htmlFor="password">Nova senha</label>
						</div>
						<div className="input-group">
							<input type="password" ref={newPassInputRepeat} required id="repeatPassword" value={newPassRepeat} onChange={event=>{
									setNewPassRepeat(event.target.value)
									event.target.setCustomValidity("")
								}}/>
							<label htmlFor="repeatPassword">Repetir senha</label>
						</div>
						<button type="submit">Salvar</button>
					</form>
				</div>
			:
			<>
			<h1 className="invalid">Token não informado!</h1>
			</>
		}
		</div>
	)
}

export default RecoverPage
