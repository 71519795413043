import React from 'react';
import {useHistory} from 'react-router-dom'
import './style.css';
import api from '../../services/api'
import AdmListPedidos from '../../Components/AdmListPedidos'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'

import {FaBars} from 'react-icons/fa'

function Admin_dash(){

    const auth = localStorage.getItem('auth')

    const history = useHistory()

    if(!auth){
        history.push('/admin/login')
    }
    else{
        try{
            api.get('/user/auth', {
                headers:{
                    'auth': auth
                }
            })
            .catch(error=>{
                alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\n${error.response.data.message}`)
                localStorage.clear()
                history.push('/admin/login')
            })
        }        
        catch(error){
            alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\nError: ${error.data.message}`)
            localStorage.clear()
            history.push('/admin/login')
        }
    }

    function toggleMenuActive(){
        const menuContainer = document.querySelector('aside.menu')

        menuContainer.classList.toggle('active')
    }

    return (
        <>
        <div className="pageCompleteWithMenu">
        <aside className="menu active">
            <MenuToggleAdmin/>
        </aside>
        <div className="content">
            <div className="headerMenuAdm">
                    <div className="containerAdmin">
                        <div className="openMenu" onClick={toggleMenuActive}>
                            <FaBars size={30} color="white"/>
                        </div>
                    </div>
            </div>
            <div className="body admBody" style={{marginTop: "50px"}}>
                <AdmListPedidos/>
            </div>
        </div>
        </div>
        </>
    )
}

export default Admin_dash