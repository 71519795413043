import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../services/api'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import { FiSearch, FiX } from 'react-icons/fi'
import {FaBars} from 'react-icons/fa'
import printList from '../../generalFunctions/printTableAdm'
import EditCustomer from './EditCustomer'
import {CancelToken, isCancel} from 'axios'

function Admin_customers(){

	const [customers, setCustomers] = useState([])
	const [customerEdited, setCustomerEdited] = useState({})
	const [searchTerm, setSearchTerm] = useState('')

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		var cancel
		var query = ""
		if(searchTerm){
			query+=`?searchTerm=${searchTerm}`
		}

		api.get(`/customer/${query}`, {
			headers: {
				'auth': auth
			},
			cancelToken: new CancelToken(c=>cancel=c)
		}).then(({data: cust})=>{
			setCustomers(cust)
		}).catch(error=>{
            if (isCancel(error)) return
        })
	}, [auth, searchTerm])

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			const customer = customers.filter(customer=>parseInt(customer.id)===parseInt(id))[0]
			setCustomerEdited(customer)
			const modalEdit = document.querySelector('#modalEditCustomer')

			modalEdit.classList.add('active')
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	return (
	<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
			<div className="body admBody" style={{marginTop: "50px"}}>
				<div className="headerAdmPage">
					<div className="header">
						<div className="utilityBar">
							<div className="search">
								<h1>Lista de Clientes</h1>
								<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
								<button className="search">
									<FiSearch size={28} color="#4287f5"/>
								</button>
							</div>
							<div className="buttons">
								<div></div>
								<button className="utilityBtns" onClick={editItem}>Editar</button>
								<button className="utilityBtns" onClick={()=>{
									printList('Clientes', [
										{title: 'Id', dataKey: 'id'},
										{title: 'Nome', dataKey: 'name'},
										{title: 'Email', dataKey: 'email'},
										{title: 'Telefone', dataKey: 'telephone'},
										{title: 'Status', dataKey: 'statusName'}
									], customers.map(customer=>{
										customer.name = `${customer.firstname} ${customer.lastname}`
										customer.statusName = customer.status===1?'Ativo':'Inativo'
										return customer
									}))
								}}>Imprimir</button>
							</div>
						</div>
						<div className="tblLegenda">
							<div className="legendaCtt">
								<div className="statusIndicator blue"></div> - Ativo
							</div>
							<div className="legendaCtt">
								<div className="statusIndicator yellow"></div> - Inativo
							</div>
						</div>
					</div>
				</div>
				<div className="tableContainer">
				<table className="admItens">
					<thead>
						<tr>
							<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
							<th>Id</th>
							<th>Nome</th>
							<th>Email</th>
							<th>Telefone</th>
							<th>Cidade</th>
							<th>Bairro</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{customers.map(customer=>{
							return (
								<tr onClick={checkLine} onDoubleClick={()=>{
									editItem()
								}}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={customer.id}/></td>
									<td>{customer.id}</td>
									<td>{customer.firstname} {customer.lastname}</td>
									<td>{customer.email}</td>
									<td>{customer.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}</td>
									<td>{(customer.cidade?customer.cidade.nome:'')}</td>
									<td>{(customer.city_region?customer.city_region.name:'')}</td>
									<td><div className={(customer.status===1)?'statusIndicator blue':'statusIndicator yellow'}></div></td>
								</tr>
							)
						})}
					</tbody>
				</table>
				</div>
			</div>
		</div>
		</div>
		<div className="modalContent" id="modalEditCustomer">
			<div className="modal">
				<EditCustomer customer={customerEdited} saveCallback={closeModal}/>
				<button className="close" id="btnCloseModalEditCustomer" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
	</>
	)
}

export default Admin_customers
