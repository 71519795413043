import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../../services/api'

function EditCustomer({customer, saveCallback}) {

	const [customerGr, setCustomerGr] = useState((customer?customer.customerGroupId:''))
	const [status, setStatus] = useState((customer?customer.status:0))
	const [telephone, setTelephone] = useState((customer?customer.telephone:''))
	const [customerGroups, setCustomerGroups] = useState([])

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		if(customer){
			setTelephone(customer.telephone)
			setCustomerGr(customer.customerGroupId)
			setStatus(customer.status)
			api.get('/customerGroup/?status=1', {
				headers: {
					'auth': auth
				}
			}).then(({data: customerGrs})=>{
				setCustomerGroups(customerGrs)
			})
		}
		else{
			setTelephone('')
			setCustomerGr(1)
			setCustomerGroups([])
			setStatus(0)
		}
	}, [customer])

	function phoneMask(phone){
		if(phone){
			const onlyNumbers = phone.replace(/\D/g, '')
			if(onlyNumbers.length<=11){
				return onlyNumbers
				.replace(/^(\d{2})(\d{1,4})$/g, '($1) $2')
				.replace(/^(\d{2})(\d{4})(\d{1,4})$/g, '($1) $2-$3')
				.replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3')
			}
			else{
				return onlyNumbers
			}
		}
		else{
			return ''
		}
	}

	function saveCustomer(event){
		event.preventDefault()

		api.post(`/customer/change/${customer.id}`, {
			customerGroupId: customerGr,
			telephone,
			status
		}, {
			headers: {
				'auth': auth
			}
		}).then(()=>{
			window.location.reload()
		}).catch(error=>{
			alert(`Erro ao salvar usuário: ${error}`)
		})
	}

	return (
	<form action="" onSubmit={saveCustomer}>
		<h1 id="titleClient">Editar Cliente</h1>
		<div className="clientBody">
			<div className="input-group">
				<input type="text" value={(customer?`${customer.firstname} ${customer.lastname}`:'')} disabled/>
				<label htmlFor="clientName">Nome</label>
			</div>
			<div className="phoneLine">
				<div className="input-group">
					<input type="text" value={(customer?customer.email:'')} disabled/>
					<label htmlFor="clientName">Email</label>
				</div>
				<div className="input-group">
					<input type="text" value={phoneMask(telephone)} maxLength={15} onChange={event=>setTelephone(event.target.value.replace(/\D/g, ''))}/>
					<label htmlFor="">Telefone</label>
				</div>
			</div>
			<div className="group-line">
				<div className="input-group">
					<select name="customer_group" id="customer_group" value={customerGr} onChange={event=>setCustomerGr(event.target.value)}>
						{customerGroups.map(customerGroup=>{
							return <option value={customerGroup.id}>
								{customerGroup.group_name}
							</option>
						})}
					</select>
					<label htmlFor="clientCustomerGroup">Grupo de clientes</label>
				</div>
				<div className="input-group">
					<select name="customer_group" id="customer_group" value={status} onChange={event=>setStatus(event.target.value)}>
						<option value={1}>Ativo</option>
						<option value={0}>Inativo</option>
					</select>
					<label htmlFor="clientCustomerGroup">Status</label>
				</div>
			</div>
		</div>
		<div className="buttonsModal">
			<button className="cancelBtn" onClick={(event)=>{
					const modal = document.querySelector("div#modalEditCustomer")
					modal.classList.remove('active')
					event.preventDefault()
				}}>Cancelar</button>
			<button className="saveBtn">Salvar</button>
		</div>
	</form>
	)
}

export default EditCustomer
