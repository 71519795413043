import React, {useState, useEffect, useCallback} from 'react'
import ReactTimeout from 'react-timeout'
import './style.css';
import {useLocation} from 'react-router-dom'
import {FiSearch, FiX, FiPrinter} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import api from '../../services/api'
import AdmListPedSearchForm from '../AdmListPedSearchForm'
import EditOrderForm from '../AdmEditOrder'
import printBlobPedido from './printBlobPedido'
import printList from '../../generalFunctions/printTableAdm'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
const {orderByProp} = require('../../generalFunctions/orderByProp')
const crypto = require('crypto')
const moment = require('moment')

const ENC_KEY = "bf3c199c2470cb477d907b1e0967817b";

function ListProd(props){
	const auth = localStorage.getItem('auth')
	const location = useLocation()
	const [pedidos, setPedidos] = useState([])
	const [modalSearch, setModalSearch] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [empresa, setEmpresa] = useState({razao: ''})
	const [datetime, setDateTime] = useState(moment().format('YYYYMMDDHHmmss'))
	const [ordering, setOrdering] = useState('')

	var dtInit = (new URLSearchParams(location.search)).get('dtInit')
	var dtEnd = (new URLSearchParams(location.search)).get('dtEnd')
	if(!dtInit&&!dtEnd){
		dtInit = (new Date()).toISOString().replace(/T.+/g, '').replace(/\D/g, '')
		dtEnd = (new Date()).toISOString().replace(/T.+/g, '').replace(/\D/g, '')
	}

	const requestTime = useCallback(()=>{
		setDateTime(new Date())
		props.setTimeout(requestTime, 200)
	}, [props])

	useEffect(()=>{
		api.get('company').then(response=>{
			setEmpresa(response.data)
			document.title=response.data.fantasia
		})

		requestTime()

	}, [requestTime])

	useEffect(() => {
		if((new URLSearchParams(location.search)).get('searchTerm')) {
			setSearchTerm((new URLSearchParams(location.search)).get('searchTerm'))
		}
	 }, [location])

	useEffect(()=>{
		var encrypt = ((val) => {
			let cipher = crypto.createCipher('aes-256-cbc', ENC_KEY)
			let encrypted = cipher.update(val, 'utf8')
			encrypted += cipher.final('hex')
			return encrypted;
		  })
		var locationSearch = location.search
		if(searchTerm!==''){
			locationSearch = location.search.replace(/&searchTerm=[^&]{0,}&?/g, '')
			locationSearch+=`&searchTerm=${searchTerm}`
		}

		if(location.search){
			locationSearch=locationSearch.replace(/&?dtInit=\d{8}/g, '').replace(/&?dtEnd=\d{8}/g, '')
			api.get(`order/list${locationSearch}${dtInit?'&dtInit='+dtInit:''}${dtEnd?'&dtEnd='+dtEnd:''}`, {
				headers:{
					'auth': auth
				}
			}).then(response=>{
				var total = 0
				var totalFrete = 0
				var totalItens = 0
				var pedidosSanitized = response.data.map(pedido=>{
					total += parseFloat(pedido.total)
					totalFrete += ((pedido.totals.length>0)?parseFloat(pedido.totals[1]?.value||0):0)
					totalItens += ((pedido.totals.length>0)?parseFloat(pedido.totals[0]?.value||0):parseFloat(pedido.total))
					pedido.encryptedId = encrypt(pedido.id.toString())
					pedido.indicator = "statusIndicator"
					switch(pedido.order_status_id){
						case 0:
							pedido.indicator+=' green'
							break;
						case 1:
							pedido.indicator+=' red'
							break;
						case 2:
							pedido.indicator+=' yellow'
							break;
						case 3:
							pedido.indicator+=' cyan'
							break;
						case 4:
							pedido.indicator+=' blue'
							break;
						default:
							pedido.indicator+=' green'
					}
					return pedido
				})

				setPedidos([...pedidosSanitized, {total, totalFrete, totalItens, is_total: true}])
			})
		}
		else{

			api.get(`order/list?dtInit=${dtInit}&dtEnd=${dtEnd}&searchTerm=${searchTerm}`, {
				headers:{
					'auth': auth
				}
			}).then(response=>{
				var total = 0
				var totalFrete = 0
				var totalItens = 0
				var encrypt = ((val) => {
					let cipher = crypto.createCipher('aes-256-cbc', ENC_KEY)
					let encrypted = cipher.update(val, 'utf8')
					encrypted += cipher.final('hex')
					return encrypted;
				});
				var pedidosSanitized = response.data.map(pedido=>{
					total += parseFloat(pedido.total)
					totalFrete += ((pedido.totals.length>0)?parseFloat(pedido.totals[1]?.value):0)
					totalItens += ((pedido.totals.length>0)?parseFloat(pedido.totals[0]?.value):parseFloat(pedido.total))
					pedido.encryptedId = encrypt(pedido.id.toString())
					pedido.indicator = "statusIndicator"
					switch(pedido.order_status_id){
						case 0:
							pedido.indicator+=' green'
							break;
						case 1:
							pedido.indicator+=' red'
							break;
						case 2:
							pedido.indicator+=' yellow'
							break;
						case 3:
							pedido.indicator+=' cyan'
							break;
						case 4:
							pedido.indicator+=' blue'
							break;
						default:
							pedido.indicator+=' green'
					}
					return pedido
				})

				setPedidos([...pedidosSanitized, {total, totalFrete, totalItens}])
			})
		}

	}, [searchTerm, auth, location])

	function checkLine(event){

		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}



	function updateFilter(event){
		setSearchTerm(event.target.value)
	}

	function closeModalSearch(){
		const modal = document.querySelector("div#modalSearch")
		modal.classList.remove('active')
	}

	function openSearchModal(){
		const modal = document.querySelector("div#modalSearch")
		setModalSearch(<AdmListPedSearchForm/>)
		modal.classList.add('active')
	}

	function changeStatusOrder(){
		var encrypt = ((val) => {
			let cipher = crypto.createCipher('aes-256-cbc', ENC_KEY)
			let encrypted = cipher.update(val, 'utf8')
			encrypted += cipher.final('hex')
			return encrypted;
		})
		const input = document.querySelector('table tr td input:checked')
		if(input){
			const id = input.dataset.id
			api.get(`/order/show/${id}`).then((response=>{
				const order = response.data

				const modal = document.querySelector("div#modalSearch")

				order.encryptedId = encrypt(order.id.toString())
				setModalSearch(<EditOrderForm empresa={empresa} order={order}/>)
				modal.classList.add('active')
			})).catch()
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}


	return (
		<>
		<div className="headerAdmPage">
			<div className="header">
				<div className="utilityBar">
					<div className="search">
						<h1>Pedidos</h1>
						<input type="text" onChange={updateFilter}/>
						<button className="search usefullBtn" onClick={openSearchModal}>
							<FiSearch size={28} color="#4287f5"/>
						</button>
					</div>
					<div className="buttons">
						<div></div>
						<button className="utilityBtns" onClick={changeStatusOrder}>Editar</button>
						<button className="utilityBtns" onClick={()=>{
							printList(`Pedidos` , [
								{title: "Pedido", dataKey: 'id'},
								{title: "Cliente", dataKey: 'name'},
								{title: "Data", dataKey: 'date'},
								{title: "Hora", dataKey: 'time'},
								{title: "Vl. Pedido", dataKey: 'v_ped'},
								{title: "Frete", dataKey: 'frete'},
								{title: "Total", dataKey: 'totalvalue'},
								{title: "Status", dataKey: 'statusName'}
							], pedidos.map((pedido, index)=>{
								if(index<pedidos.length){
									pedido.name = `${pedido.firstname} ${pedido.lastname}`
									pedido.date = pedido.createdAt?moment(pedido.createdAt.replace(/.\d{3}$/g, ''), 'YYYY/MM/DDTHH:mm:ss').format('DD/MM/YYYY'):''
									pedido.time = pedido.createdAt?moment(pedido.createdAt.replace(/.\d{3}$/g, ''), 'YYYY/MM/DDTHH:mm:ss').format('HH:mm'):''
									if(pedido.totals){
										pedido.v_ped = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(((pedido.totals[0])?parseFloat(pedido.totals[0].value):parseFloat(pedido.total)))
										pedido.frete = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(((pedido.totals[1])?parseFloat(pedido.totals[1].value):0))
									}
									switch(pedido.order_status_id){
										case 0:
											pedido.statusName='Aberto'
											break;
										case 1:
											pedido.statusName='Cancelado'
											break;
										case 2:
											pedido.statusName='Em produção'
											break;
										case 3:
											pedido.statusName='Em entrega'
											break;
										case 4:
											pedido.statusName='Finalizado'
											break;
										default:
											pedido.statusName='Aberto'
									}

									pedido.totalvalue = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(pedido.total))
								}
								return pedido
							}).filter((pedido, index)=>{
								return index<pedidos.length-1
							}), {
								title: "Total",
								values: [
									Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(pedidos[pedidos.length-1].totalItens)),
									Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(pedidos[pedidos.length-1].totalFrete)),
									Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(pedidos[pedidos.length-1].total)),
									pedidos.length-1
								]})
						}}>Imprimir</button>
					</div>
				</div>
				<div className="filter">
					Filtro: {moment(dtInit).format("DD/MM/YYYY")} à {moment(dtEnd).format("DD/MM/YYYY")}
				</div>
				<div className="containerDateTime">
					<div className="tblLegenda">
						<div className="legendaCtt">
							<div className="statusIndicator green"></div> - Aberto
						</div>
						<div className="legendaCtt">
							<div className="statusIndicator red"></div> - Cancelado
						</div>
						<div className="legendaCtt">
							<div className="statusIndicator yellow"></div> - Em produção
						</div>
						<div className="legendaCtt">
							<div className="statusIndicator cyan"></div> - Em entrega
						</div>
						<div className="legendaCtt">
							<div className="statusIndicator blue"></div> - Finalizado
						</div>
					</div>
					<div className="datetime">
						<div>Data: {moment(datetime, 'YYYYMMDDHHmmss.SSS').format('DD/MM/YYYY')+'\t'}</div> <div title={moment(datetime).format('HH:mm:ss')}>Hora: {moment(datetime).format('HH:mm')}</div>
					</div>
				</div>
			</div>
		</div>
		<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total), prop:'id', reverse: ordering!=='id:up'}), ...(total?[total]:[])]
							})

							if(ordering==='id:up'){
								setOrdering('id:down')
							}
							else{
								setOrdering('id:up')
							}
						}}>Pedido {ordering==='id:up'?<FaChevronUp size={10}/>:(ordering==='id:down'?<FaChevronDown size={10}/>:'')}</th>
						<th>Ordem</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: (pedidos.filter(p=>!p.is_total).map(p=>{
									p.name = `${p.firstname} ${p.lastname}`

									return p
								})), prop:'name', reverse: ordering!=='name:up'}), ...(total?[total]:[])]
							})

							if(ordering==='name:up'){
								setOrdering('name:down')
							}
							else{
								setOrdering('name:up')
							}
						}}>Cliente {ordering==='name:up'?<FaChevronUp size={10}/>:(ordering==='name:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total), prop:'telephone', reverse: ordering!=='telephone:up'}), ...(total?[total]:[])]
							})

							if(ordering==='telephone:up'){
								setOrdering('telephone:down')
							}
							else{
								setOrdering('telephone:up')
							}
						}}>Whatsapp {ordering==='telephone:up'?<FaChevronUp size={10}/>:(ordering==='telephone:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total).map(p=>{
									p.dateInteger = moment(p.createdAt).format('YYYYMMDDHHMM')

									return p
								}), prop:'dateInteger', reverse: ordering!=='dateInteger:up'}), ...(total?[total]:[])]
							})

							if(ordering==='dateInteger:up'){
								setOrdering('dateInteger:down')
							}
							else{
								setOrdering('dateInteger:up')
							}
						}}>Data {ordering==='dateInteger:up'?<FaChevronUp size={10}/>:(ordering==='dateInteger:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total).map(p=>{
									p.dateInteger = moment(p.createdAt).format('YYYYMMDDHHMM')

									return p
								}), prop:'dateInteger', reverse: ordering!=='dateInteger:up'}), ...(total?[total]:[])]
							})

							if(ordering==='dateInteger:up'){
								setOrdering('dateInteger:down')
							}
							else{
								setOrdering('dateInteger:up')
							}
						}}>Hora {ordering==='dateInteger:up'?<FaChevronUp size={10}/>:(ordering==='dateInteger:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total).map(p=>{
									p.subtotal = parseFloat((p.totals.length>0)?p.totals[0].value:p.total)

									return p
								}), prop:'subtotal', reverse: ordering!=='subtotal:up'}), ...(total?[total]:[])]
							})

							if(ordering==='subtotal:up'){
								setOrdering('subtotal:down')
							}
							else{
								setOrdering('subtotal:up')
							}
						}}>Vl. Pedido {ordering==='subtotal:up'?<FaChevronUp size={10}/>:(ordering==='subtotal:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total).map(p=>{
									p.frete = parseFloat((p.totals.length>0)?p.totals[1].value:0)

									return p
								}), prop:'frete', reverse: ordering!=='frete:up'}), ...(total?[total]:[])]
							})

							if(ordering==='frete:up'){
								setOrdering('frete:down')
							}
							else{
								setOrdering('frete:up')
							}
						}}>Frete {ordering==='frete:up'?<FaChevronUp size={10}/>:(ordering==='frete:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total).map(p=>{
									p.total = parseFloat(p.total)

									return p
								}), prop:'total', reverse: ordering!=='total:up'}), ...(total?[total]:[])]
							})

							if(ordering==='total:up'){
								setOrdering('total:down')
							}
							else{
								setOrdering('total:up')
							}
						}}>Total {ordering==='total:up'?<FaChevronUp size={10}/>:(ordering==='total:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setPedidos(pedidos=>{
								const total = pedidos.find(p=>p.is_total)

								return [...orderByProp({array: pedidos.filter(p=>!p.is_total), prop:'order_status_id', reverse: ordering!=='order_status_id:up'}), ...(total?[total]:[])]
							})

							if(ordering==='order_status_id:up'){
								setOrdering('order_status_id:down')
							}
							else{
								setOrdering('order_status_id:up')
							}
						}}>Status {ordering==='order_status_id:up'?<FaChevronUp size={10}/>:(ordering==='order_status_id:down'?<FaChevronDown size={10}/>:'')}</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{pedidos.map((pedido, index)=>{
						if (index+1!==pedidos.length){
							return (
								<tr key={pedido.id} onClick={checkLine} onDoubleClick={changeStatusOrder}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={pedido.id}/></td>
									<td>{pedido.id}</td>
									<td>{index+1}</td>
									<td>{pedido.firstname} {pedido.lastname}</td>
									<td className="phone">
										<a href={`https://wa.me/+55${pedido.telephone}`} target="_blanck">{pedido.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}</a>
									</td>
									<td>{moment(pedido.createdAt.replace(/.\d{3}Z$/g, ''), 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')}</td>
									<td>{moment(pedido.createdAt.replace(/.\d{3}Z$/g, ''), 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(((pedido.totals.length>0)?pedido.totals[0]?.value||0:pedido.total))}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(((pedido.totals.length>0)?pedido.totals[1]?.value||0:0))}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(((pedido.totals.length>0)?pedido.totals[2]?.value||0:pedido.total))}</td>
									<td><div className={pedido.indicator}></div></td>
									<td><Link className="printIcon" title="Impressão Entregador" to={`#`} onClick={event=>{
										event.preventDefault()
										api.get(`/order/show/${pedido.id}`).then(response=>{
											var pedidoComplete = response.data

											printBlobPedido(pedidoComplete, empresa)
										}).catch(error=>{
											alert(`Erro ao buscar pedido para impressão: ${error}`)
										})
									}}><FiPrinter size={18} color="#4287f5"/></Link></td>
									<td><Link className="printIcon" title="Impressão Pedido" to={`/pedido/${pedido.encryptedId}`} target="_blanck"><FiPrinter size={18}/></Link></td>
								</tr>
								)
						}
						else{
							return (
								<tr key={index+'total'}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={0}/></td>
									<td colSpan={6}>Total</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totalItens)}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.totalFrete)}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.total)}</td>
									<td style={{justifyContent: 'center', textAlign: 'center'}}>{pedidos.length-1}</td>
									<td></td>
									<td></td>
								</tr>
							)
						}
					})}
				</tbody>
			</table>
		</div>
		<div className="modalContent" id="modalSearch">
			<div className="modal modalSearch">
			{modalSearch}
				<button className="close" onClick={closeModalSearch}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</>
	)
}

export default ReactTimeout(ListProd)
