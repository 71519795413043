module.exports= {
	orderByProp({array, prop,reverse}){
		if(reverse){
			return array.sort((a,b)=>{
				return (a[prop]>b[prop])?1:((a[prop]<b[prop])?-1:0)
			}).reverse()
		}
		else{
			return array.sort((a,b)=>{
				return (a[prop]>b[prop])?1:((a[prop]<b[prop])?-1:0)
			})
		}
	}
}
