import React, {useState, useEffect} from 'react'
import api from '../../../services/api'
import {FiX, FiXCircle} from 'react-icons/fi'
import './style.css'
import EditCityRegion from './EditCityRegion'

function EditCity({cityEdited}){

	const [id, setId] = useState(cityEdited?cityEdited.id:'')
	const [name, setName] = useState(cityEdited?cityEdited.nome:'')
	const [codIbge, setCodIbge] = useState(cityEdited?cityEdited.cod_ibge:'')
	const [loading, setLoading] = useState(false)
	const [frete, setFrete] = useState(cityEdited?Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(cityEdited.frete):'')
	const [cityRegions, setCityRegions] = useState([])
	const [editedRegion, setEditedRegion] = useState()

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		if(cityEdited){
			setId(cityEdited.id)
			setName(cityEdited.nome)
			setCodIbge(cityEdited.cod_ibge)
			setFrete(Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(cityEdited.frete))
			api.get(`/cityRegion/?cidadeId=${cityEdited.id}`).then(({data: regions})=>{
				setCityRegions(regions)
			})
		}
		else{
			setId('')
			setName('')
			setCodIbge('')
			setFrete('')
			setCityRegions([])
		}
	}, [cityEdited])

	function closeModal(event){
		const modal = event.target.closest("div#modalEditCity")
		modal.classList.remove('active')
	}

	function closeModalRegion(event){
		const modal = document.querySelector("div#modalEditCityRegion")
		modal.classList.remove('active')
	}

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function regionSaveCallback(region){
		const existentRegion = cityRegions.filter(r=>parseInt(r.id)===parseInt(region.id))[0]

		if(existentRegion){
			setCityRegions(cityRegions.map(r=>{
				if(parseInt(r.id)===parseInt(region.id)){
					return region
				}
				else{
					return r
				}
			}))
		}
		else{
			setCityRegions([...cityRegions,region])
		}

		closeModalRegion()
	}

	function handleSaveCity(event){
		setLoading(true)
		event.preventDefault()

		api.post(`/city/`, {
			id: (id&&id),
			name,
			codIbge,
			frete
		}, {
			headers: {
				'auth': auth
			}
		}).then(response=>{
			alert(`Cidade ${response.data.id} salva com sucesso!`)
			document.location.reload()
		}).catch(error=>{
			alert(`${error.response.data.message}`)
			setLoading(false)
		})
	}

	function editRegion(event){
		event.preventDefault()

		const input = document.querySelector('table.regionsTable tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/cityRegion/${id}`, {
				headers: {
					'auth': auth
				}
			}).then((response=>{
				setEditedRegion(response.data)
				const modal = document.querySelector("div#modalEditCityRegion")
				modal.classList.add('active')
			})).catch(error=>{
				alert(`${error.response.data.message}`)
			})
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function newRegion(event){
		event.preventDefault()
		if(id){

			setEditedRegion()
			const modal = document.querySelector("div#modalEditCityRegion")
			modal.classList.add('active')
		}
		else{
			alert('É necessário salvar cidade antes de adicionar bairros!')
		}
	}

	function deleteItem(event){
		event.preventDefault()

		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id
		if(window.confirm(`Deseja realmente deletar bairro ${id}?`)){
			api.delete(`/cityRegion/${id}`, {
				headers: {
					'auth': auth
				}
			}).then(()=>{
				alert(`Bairro ${id} deletado com sucesso!`)
				setCityRegions(cityRegions.filter(r=>parseInt(r.id)!==parseInt(id)))
			})
		}
	}

	return (
	<>
	{loading?<div className="loaderModal">
		<div className="spinner"></div>
	</div>:<></>}
		<div className="headerCategory">
			<h1>Cadastro de cidade</h1>
		</div>
		<form action="" onSubmit={handleSaveCity}>
			<div className="cadCity">
				<div className="formCategory">
					<div className="input-group">
						<input type="text" value={name} onChange={event=>setName(event.target.value)}/>
						<label htmlFor="">Nome</label>
					</div>
					<div className="input-group">
						<input type="text" value={codIbge} onChange={event=>setCodIbge(event.target.value)}/>
						<label htmlFor="">Cód. IBGE</label>
					</div>
					<div className="input-group">
						<input type="text" value={Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(frete)} onChange={event=>setFrete(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
						<label htmlFor="frete">Frete</label>
					</div>
				</div>
				<div className="regionsTableContainer">
					<div className="headerRegions">
						<h1>Bairros</h1>
						<div className="buttonsRegions">
							<button onClick={newRegion} className="addRegion">Novo</button>
							<button onClick={editRegion} className="editRegion">Editar</button>
						</div>
					</div>
					<table className="admItens regionsTable">
						<thead>
							<tr>
								<th>Cód.</th>
								<th>Nome</th>
								<th>Vr. Frete</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{cityRegions.map(region=>{
								return <tr onClick={checkLine} onDoubleClick={editRegion} key={region.id}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={region.id} disabled/></td>
									<td>{region.id}</td>
									<td>{region.name}</td>
									<td>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(region.shipping_value)}</td>
									<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
								</tr>
							})}
						</tbody>
					</table>
				</div>
				<div className="footerAddForm">
					<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
					<button type="submit" className="save">Salvar</button>
				</div>
			</div>
		</form>
		<div className="modalContent" id="modalEditCityRegion">
			<div className="modal">
				<EditCityRegion region={editedRegion} cidadeId={id} callBackSave={regionSaveCallback}/>
				<button className="close" id="btnCloseModalEditCityRegion" onClick={closeModalRegion}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
	</>
	)
}

export default EditCity
