import React, {useState, useEffect} from 'react'
import './style.css'
import {FiImage} from 'react-icons/fi'
import api from '../../../../../services/api'

function FormCadCompositionItem({composition, saveCallback=()=>{}}){

	const [name, setName] = useState('')
	const [image, setImage] = useState('')
	const [value, setValue] = useState(0)
	const [imageLoading, setImageLoading] = useState(false)

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		if(composition){
			setName(composition.name)
			setImage(composition.image)
			setValue(composition.value)
		}
		else{
			setName('')
			setImage('')
			setValue(0)
		}
	}, [composition])

	function getImageBase64(file){
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		})
	}

	function saveComposition(event){
		event.preventDefault()

		api.post('/compositionItem/', {
			...(composition?{id: composition.id}:{}),
			image,
			name,
			value
		}, {
			headers: {
				auth
			}
		}).then(({data})=>{
			saveCallback(data)
			setName('')
			setImage('')
			setValue(0)
			closeModal(event)
		})
	}

	function closeModal(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalAddCompositionItemContainer")
		modal.classList.remove('active')
	}

	function changePhoto(event){
		setImageLoading(true)
		const input = event.target

		getImageBase64(input.files[0]).then((data)=>{
			if(composition){
				api.post(`/compositionItem/setImage/${composition.id}`, {
					imageData: data
				},{
					headers: {
						auth
					}
				}).then(({data})=>{
					setImageLoading(false)
					setImage(`${window.location.origin.toString()}/assets/compositions/${data.shortName}`)
				})
			}
			else{
				alert(`Composição precisa ser salva para a adição de imagens!`)
				setImageLoading(false)
			}
		})

	}

	return (
	<>
		<form action="" onSubmit={saveComposition}>
			<h1 className="formLabel">Item adicional</h1>
			<div className="cadCompItem">
				<div className="fotoContainer">
					{imageLoading?<div className="loadingContainer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:10, width: '100%', height: '100%', position: 'absolute', background: '#50505055', borderRadius: '5px'}}>
								<div className="loading"></div>
							</div>:<></>}
							<img id="imageDisplay" className={image?"displayedImage":""} src={`${image}`} alt=""/>
							{(!imageLoading)?<label htmlFor="imageInputCompItem" className={image?"inputFileShowed overImage":"inputFileShowed"} onMouseEnter={event=>{
								event.target.classList.add('hovered')
							}} onMouseLeave={event=>{
								event.target.classList.remove('hovered')
							}}>
								<FiImage size={60}/>
								{composition?"Alterar foto":"Adicionar foto"}
							</label>:<></>}
							<input type="file" id="imageInputCompItem" className="inputFileHidden" value="" onChange={changePhoto} accept=".jpg,.png"/>
				</div>
				<div className="cadCompositionItem">
					<div className="input-group">
						<input type="text" value={name} onChange={event=>setName(event.target.value)}/>
						<label htmlFor="">Nome</label>
					</div>
					<div className="input-group">
						<input type="text" value={Intl.NumberFormat('pt-br', {minimumFractionDigits:2, maximumFractionDigits:2}).format(value)} onChange={event=>setValue(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
						<label htmlFor="">Valor</label>
					</div>
				</div>
			</div>
			<div className="footerAddForm">
				<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
				<button type="submit" onClick={saveComposition} className="save">Salvar</button>
			</div>
		</form>
	</>
	)
}

export default FormCadCompositionItem
