import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../services/api'
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import printList from '../../generalFunctions/printTableAdm'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import EditCity from './EditCity'

import {FaBars} from 'react-icons/fa'

function Admin_dash(){

	const [cities, setCities] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [cityEdited, setCityEdited] = useState('')

	useEffect(()=>{
		api.get(`/cities/${(searchTerm?`?searchTerm=${searchTerm}`:'')}`).then(response=>{
			setCities(response.data)
		})
	}, [searchTerm])

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/city/${id}`).then((response=>{
				setCityEdited(response.data)
				const modal = document.querySelector("div#modalEditCity")
				modal.classList.add('active')
			})).catch(error=>{
				alert(`${error.response.data.message}`)
			})
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	async function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		const auth = localStorage.getItem('auth')

		if(window.confirm(`Deletar cadastro de cidade ${id}?`)){
			try{
				await api.delete(`/city/${id}`, {
					headers: {
						'auth': auth
					}
				})
				setCities(cities.filter(city=>parseInt(city.id)!==parseInt(id)))
				alert(`Cadastro de cidade ${id} deletado com sucesso!`)
			}
			catch(error){
				alert(`Erro ao deletar cadastro de cidade ${id}: ${error}!`)
			}
		}
	}

	function newItem(){
		setCityEdited('')

		const modal = document.querySelector("div#modalEditCity")
		modal.classList.add('active')
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	return (
		<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
			<div className="body admBody" style={{marginTop: "50px"}}>
			<div className="headerAdmPage">
				<div className="header">
					<div className="utilityBar">
						<div className="search">
							<h1>Lista de cidades</h1>
							<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
							<button className="search" onClick={()=>{
								const modal = document.querySelector('div#modalProductSearch')
								modal.classList.add('active')
							}}>
								<FiSearch size={28} color="#4287f5"/>
							</button>
						</div>
						<div className="buttons">
							<button className="utilityBtns" onClick={newItem}>Novo</button>
							<button className="utilityBtns" onClick={editItem}>Editar</button>
							<button className="utilityBtns" onClick={()=>{
								printList('Cidades', [
									{title: 'Cód. IBGE', dataKey: 'cod_ibge'},
									{title: 'Cidade', dataKey: 'nome'},
									{title: 'Vr. Frete', dataKey: 'frete_vr'}
								], cities.map(city=>{
									city.frete_vr = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(city.frete)
									return city
								}))
							}}>Imprimir</button>
						</div>
					</div>
					<div className="tblLegenda">
						<div className="legendaCtt">
							<div className="statusIndicator blue"></div> - Ativo
						</div>
						<div className="legendaCtt">
							<div className="statusIndicator yellow"></div> - Inativo
						</div>
					</div>
				</div>
			</div>
			<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th>Cód. IBGE</th>
						<th className="eightFilled">Cidade</th>
						<th>Vr. Frete</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{cities.map(city=>{
						return <tr onClick={checkLine} onDoubleClick={editItem} key={city.id}>
							<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={city.id} disabled/></td>
							<td>{city.cod_ibge}</td>
							<td>{city.nome}</td>
							<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(city.frete)}</td>
							<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
						</tr>
					})}
				</tbody>
			</table>
			</div>
		</div>
		<div className="modalContent" id="modalEditCity">
			<div className="modal">
				<EditCity cityEdited={cityEdited}/>
				<button className="close" id="btnCloseModalEditCity" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</div>
		</div>
	</>)
}

export default Admin_dash
