import React, {useState, useEffect} from 'react';
import './style.css';
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import api from '../../services/api'
import AdmAddNewCategory from './AdmAddNewCategory'
import printList from '../../generalFunctions/printTableAdm'

function ListCat(){
	const [modalCat, setModalCat] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [categories, setCategories] = useState([])

	const auth = localStorage.getItem('auth')

	async function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		if(window.confirm(`Deletar item ${id}?`)){
			try{
				await api.delete(`/categories/${id}`, {
					headers: {
						'auth': auth
					}
				})
				alert(`Item ${id} deletado com sucesso!`)
				setCategories(categories=>{
					return categories.filter(category=>parseInt(category.id)!==parseInt(id))
				})
			}
			catch(error){
				alert(`Erro ao deletar item ${id}: ${error}!`)
			}
		}
	}

	useEffect(()=>{

		var completion = searchTerm?`?searchTerm=${searchTerm}`:''

		api.get(`/categories/${completion}`).then(response=>{
			setCategories(response.data)
		})
	}, [searchTerm])

	function updateFilter(event){
		setSearchTerm(event.target.value)
	}

	function checkLine(event){

		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/categories/show/${id}`, {
				headers: {
					'auth': auth
				}
			}).then((response=>{
				const item = response.data

				const modal = document.querySelector("div#modalCatCadForm")
				setModalCat(<AdmAddNewCategory item={item}/>)
				modal.classList.add('active')
			})).catch(error=>{
				alert(`Erro ao buscar categoria: ${error.response.data.message}`)
			})
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function addItem(){
		const modal = document.querySelector("div#modalCatCadForm")
		setModalCat(<AdmAddNewCategory/>)
		modal.classList.add('active')
	}

	function closeModal(){
		const modal = document.querySelector("div#modalCatCadForm")
		modal.classList.remove('active')
	}


	return (
		<>
		<div className="headerAdmPage">
			<div className="header">
				<div className="utilityBar">
					<div className="search">
						<h1>Lista de categorias</h1>
						<input type="text" onChange={updateFilter}/>
						<button className="search">
							<FiSearch size={28} color="#4287f5"/>
						</button>
					</div>
					<div className="buttons">
						<button className="utilityBtns" onClick={addItem}>Novo</button>
						<button className="utilityBtns" onClick={editItem}>Editar</button>
						<button className="utilityBtns" onClick={()=>{
							printList('Categorias', [
								{title: "Código", dataKey: 'id'},
								{title: "Descrição", columnWidth: 280, dataKey: 'name'},
								{title: "Status", dataKey: 'status_value'},
							], categories.map(category=>{
								category.status_value = (category.status===1)?'Ativo':"Inativo"
								return category
							}))
						}}>Imprimir</button>
					</div>
				</div>
				<div className="tblLegenda">
					<div className="legendaCtt">
						<div className="statusIndicator blue"></div> - Ativo
					</div>
					<div className="legendaCtt">
						<div className="statusIndicator yellow"></div> - Inativo
					</div>
				</div>
			</div>
		</div>
		<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th>Código</th>
						<th style={{minWidth: "85%"}}>Descrição</th>
						<th>Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{categories.map(category=>{
						return <tr onClick={checkLine} onDoubleClick={editItem}>
							<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={category.id}/></td>
							<td>{category.id}</td>
							<td>{category.name}</td>
							<td><div className={category.status===1?"statusIndicator blue":"statusIndicator yellow"}></div></td>
							<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
						</tr>
					})}
				</tbody>
			</table>
		</div>
		<div className="modalContent" id="modalCatCadForm">
			<div className="modal modalCat">
			{modalCat}
				<button className="close" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</>
	)
}

export default ListCat
