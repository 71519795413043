import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import api from '../../services/api'
import './style.css'

function SearchFormModal(props) {
	const params = new URLSearchParams(window.location.search)
	const [searchTerm, setSearchTerm] = useState(params.has('searchTerm')?params.get('searchTerm'):'')
	const [entregadores, setEntregadores] = useState([])
	const [status, setStatus] = useState(params.has('status')?params.get('status'):'')
	const [deliveryMan, setDeliveryMan] = useState(params.has('entregador')?params.get('entregador'):'')
	const [dtInit, setDtInit] = useState(params.has('dtInit')?params.get('dtInit').replace(/^(\d{4})(\d{2})(\d{2})$/g, '$1-$2-$3'):(new Date()).toISOString().replace(/T.+/g, ''))
	const [dtEnd, setDtEnd] = useState(params.has('dtEnd')?params.get('dtEnd').replace(/^(\d{4})(\d{2})(\d{2})$/g, '$1-$2-$3'):(new Date()).toISOString().replace(/T.+/g, ''))
	
	const history = useHistory()

	const auth = localStorage.getItem('auth')


	function handleSearch(event){
		event.preventDefault()
		history.push({
			pathname: '/admin/dashboard/pedidos/',
			search: `?dtInit=${dtInit.replace(/\D/g, '')}&dtEnd=${dtEnd.replace(/\D/g, '')}${searchTerm?'&searchTerm='+searchTerm:''}${deliveryMan?'&entregador='+deliveryMan:''}${status?'&status='+status:''}`
		})
		closeModalSearch()
	}

	function closeModalSearch(){
		const modal = document.querySelector("div#modalSearch")
		modal.classList.remove('active')
	}

	useEffect(()=>{
		api.get(`/deliveryman/`, {
			headers: {
				'auth': auth
			}
		}).then(response=>{
			setEntregadores(response.data)
		})
	}, [auth])

	return (<>
	<div className="headerModal">
		Pesquisa
	</div>
	<form action="" onSubmit={handleSearch}>
	<div className="bodySearch">
		<div className="input-group spaced">
			<input type="text" name="searchTerm" id="searchTerm" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
			<label htmlFor="searchTerm">Nome</label>
		</div>
		<div className="input-group spaced">
			<select name="entregador" id="entregador" value={deliveryMan} onChange={event=>setDeliveryMan(event.target.value)}>
				<option value="">Selecione um entregador...</option>
				{entregadores.map(entregador=>{
					return <option value={entregador.id}>{entregador.nome}</option>
				})}
			</select>
			<label htmlFor="entregador">Entregador</label>
		</div>
		<div className="input-group spaced">
            <select name="status" id="status" value={status} onChange={event=>setStatus(event.target.value)}>
                <option value="">Todos</option>
                <option value="0,2,3">Todos não cancelados/finalizados</option>
                <option value="0">Abertos</option>
                <option value="1">Cancelados</option>
                <option value="2">Em produção</option>
                <option value="3">Em entrega</option>
                <option value="4">Finalizado</option>
            </select>
			<label htmlFor="status">Status</label>
        </div>
		<div className="input-group">
			<input type="date" name="dtInit" id="dtInit" value={dtInit} onChange={(event)=>{
					if(event.target.value<=dtEnd){
						setDtInit(event.target.value)
					}
					else{
						alert(`Data inicial não pode ser maior que data final!`)
					}
				}}/>
			<label htmlFor="dtInit">Dt. Inicio</label>
		</div>
		<div className="input-group">
			<input type="date" name="dtEnd" id="dtEnd" value={dtEnd} onChange={(event)=>{
					if(event.target.value>=dtInit){
						setDtEnd(event.target.value)
					}
					else{
						alert(`Data final não pode ser maior que data inicial!`)
					}
				}}/>
			<label htmlFor="dtEnd">Dt. Fim</label>
		</div>
	</div>
	<div className="footerAddForm">
		<button type="button" className="cancel" onClick={closeModalSearch}>Cancelar</button>
		<button type="submit" className="save">Pesquisar</button>
	</div>
	</form>
	</>)
}

export default SearchFormModal