import React, {useState, useEffect} from 'react';
import {FiPrinter} from 'react-icons/fi'
import './style.css';
import api from '../../services/api'
import printOrder from './printOrder'
const ENC_KEY = "bf3c199c2470cb477d907b1e0967817b";
const crypto = require('crypto')
const moment = require('moment')

function Order(props) {
	var params
	const [itemListContent, setItemListContent] = useState([])
	const [total, setTotal] = useState(0)
	const [frete, setFrete] = useState(0)
	const [subTotal, setSubTotal] = useState(0)
	const [empresa, setEmpresa] = useState({})
	const [cliente, setCliente] = useState({})
	const [pedido, setPedido] = useState({})
	params = props.match.params

	var decrypt = ((encrypted) => {
	  let decipher = crypto.createDecipher('aes-256-cbc', ENC_KEY);
	  let decrypted = decipher.update(encrypted, 'hex', 'utf8');
	  return (decrypted + decipher.final('utf8'));
	});

	var id = ''

	try{
		id = decrypt(params.id)
	}
	catch(error){
		id=null
	}

	async function handlePrint(event){
		const orderElement = document.querySelector('div.item')

		await printOrder(orderElement, {empresa, pedido, cliente, subTotal, frete, total}, id)
	}

	useEffect(()=>{
		async function getPedido(){
			api.get(`/order/show/${id}`).then(response=>{
				let itens = response.data.items
				setItemListContent(itens.map(item=>{
					return (
						<>
						<tr>
							<td>{item.name}</td>
							<td>{Intl.NumberFormat('pt-BR').format(item.quantity)}</td>
							<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.price)}</td>
							<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.quantity*item.price)}</td>
						</tr>
						<tr>
							<td colspan={4}>{item.obs}</td>
						</tr>
						</>
					)
				}))
				setTotal(response.data.total)

				const valueSubTotal = response.data.totals.filter(total=>total.code==='sub_total')[0].value
				const valueFrete = response.data.totals.filter(total=>total.code==='shipping')[0].value

				if(valueFrete){
					setFrete(valueFrete)
					setSubTotal(valueSubTotal)
				}
				else{
					setFrete(0)
					setSubTotal(response.data.total)
				}
				setCliente({
					nome: `${response.data.firstname} ${response.data.lastname}`,
					telefone: response.data.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')
				})
				setPedido({
					data: response.data.createdAt,
					entrega: (response.data.shipping_address_1!==''?`${response.data.shipping_address_1}, ${response.data.shipping_address_2}`:''),
					items: response.data.items,
					obs: response.data.obs
				})
				}).catch(error=>{
					document.querySelector('.ItemContainer').innerText=`Erro 404: Pedido ${params.id} não encontrado!`
				})
			api.get('/company').then(response=>{
				setEmpresa(response.data)
			})
	}
	getPedido()
	}, [id, params.id])

	return (
		<div className={`ItemContainer ${empresa.theme}`}>
			<div className="item">
			<span className="company_name_pedido">{empresa.razao} </span>
			<span>End: {empresa.address}</span>
			<span>WhatsApp: {empresa.phone && empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}</span>
			<span>Data: {pedido.data && (moment(pedido.data).format('DD/MM/YYYY HH:mm'))}</span>
			<div className="spanGroup">
				<span className="time_wait">{empresa.show_wait_time_printing===1?`Tempo de espera: ${moment(empresa.wait_time, 'HH:mm:ss').format('HH:mm')}`:''}</span>
				<span className="order_num">{empresa.show_wait_time_printing===1?(pedido.data?`Hora do pedido: ${moment(pedido.data.replace(/.\d{3}Z$/g, ''), 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}`:`Hora do pedido: `):`${empresa.print_title&&((empresa.print_title.charAt(0).toUpperCase()+empresa.print_title.slice(1).toLowerCase()))} ${id}`}</span>
			</div>
	{(empresa.show_wait_time_printing===1)&pedido.data!==undefined?<span>Previsão de chegada: {moment(pedido.data, 'YYYY-MM-DD HH:mm:ss')
	.add(parseInt(moment(empresa.wait_time, 'HH:mm:ss').format('ss'))+(parseInt(moment(empresa.wait_time, 'HH:mm:ss').format('mm'))*60)+(parseInt(moment(empresa.wait_time, 'HH:mm:ss').format('HH'))*3600), 'seconds')
	.format('HH:mm')}</span>:''}
			<table className="itens itensPedidoFin">
				<thead>
					<tr>
						<th>Descrição</th>
						<th>Qtd.</th>
						<th>Vr. Unit</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{itemListContent}
					<tr>
						<td colspan={3}>Total dos itens</td>
						<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(subTotal)}</td>
					</tr>
					<tr>
						<td colspan={3}>Frete</td>
						<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(frete)}</td>
					</tr>
					<tr>
						<td colspan={3}>Total</td>
						<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(total)}</td>
					</tr>
				</tbody>
			</table>
			<span>{pedido.obs}</span>
			<span>Cliente: {cliente.nome}</span>
			{(pedido.entrega!=='')?<span>Entrega: {pedido.entrega}</span>:''}
			<span>Telefone cliente: {cliente.telefone}</span>
			<button onClick={handlePrint} className="print"><FiPrinter size={18} color="#888"/></button>
			</div>
		</div>
		)
}

export default Order