import React, {useState, useEffect, useRef, useCallback, Suspense} from 'react'
import './style.css'
import useProductCategorySearch from './useProductCategorySearch'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {FiPlusCircle, FiX, FiAlertTriangle} from 'react-icons/fi'
import {FaChevronRight, FaChevronDown} from 'react-icons/fa'
import openDescriptionImg from '../../assets/mclique.png'
import api from '../../services/api'
const ItemModal = React.lazy(()=>import('../ItemModal'))

function CategoryPage(){

	const [toogle, setToogle] = useState(0)
	const [empresa, setEmpresa] = useState({})
	const [categories, setCategories] = useState([])
	const [modalContent, setModalContent] = useState([])
	const [description, setDescription] = useState('')
	const [page, setPage] = useState(1)

	const search = useLocation().search

	const location = useLocation()
	const history = useHistory()

	const {
		products,
		setProducts,
		hasMore,
		loading,
		error
	} = useProductCategorySearch(location.hash, page)

	const observer = useRef()

	const lastProductDiv = useCallback(node=>{
		if (loading) return
		if(observer.current) observer.current.disconnect()
		observer.current=new IntersectionObserver(entries=>{
			if(entries[0].isIntersecting && hasMore){
				setPage(prevPage=>prevPage+1)
			}
		})
		if (node) observer.current.observe(node)
	}, [loading, hasMore])

    const [hasSecondPrice, setHasSecondPrice] = useState(false)
	var userToken = localStorage.getItem('userToken')

	useEffect(()=>{
		if(userToken){
			api.get('/customer/validate', {
				headers: {
					auth: userToken
				}
			}).then(({data})=>{
				setHasSecondPrice(parseInt(data.customer_group.second_price)===1)
			}).catch((error)=>{
				setHasSecondPrice(false)
				localStorage.removeItem('userToken')
			})
		}
		else{
			setHasSecondPrice(false)
		}

		const productId = (new URLSearchParams(search).get('productId'))

		if(productId){
			api.get(`products/show/${productId}`).then(({data})=>{
				if(data){
					addItemToCart(data)
				}
			})
		}

	}, [userToken, search, location.hash])

	useEffect(()=>{
		api.get(`/categories/?onlyActives=true`).then(response=>{
			setCategories(response.data)
		})
		api.get('/company').then(response=>{
			setEmpresa(response.data)
		})
	}, [])

	useEffect(()=>{
		setPage(1)
	}, [location])

	async function addItemToCart(product){
       if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))){
		await api.get(`products/show/${product.id}`).then(response=>{
			const modal = document.querySelector("div#modalAdd")
			setModalContent(
				<Suspense fallback={()=>{}}>
					<ItemModal item={response.data}/>
				</Suspense>
			)
			modal.classList.add('active')
		})
	   }
	}

	function showStock(item){
		if(empresa.show_estoque===1){
			return (<p className="estoque">Qtd.: {Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.quantity)}</p>)
		}
		else{
			return (<></>)
		}
	}

	function openDescription(event){
		const modal = document.querySelector("div#modalDetailsDirect")
		if(modal){
			modal.classList.add('active')
		}
	}

	function closeModal(event){
		event.preventDefault()
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}
	return (
		<div className="categoryPage">
			<aside className={`categoriesListing ${toogle?'active':''}`}>
				<div className={`toogleCategories`} onClick={()=>{
					setToogle(toogle=>{
						return toogle?0:1
					})
				}}>
					{toogle?<FaChevronDown size={35} color="#666"/>:<FaChevronRight size={35} color="#666"/>} Categorias
				</div>
				<ul>
					{categories.map(category=>{
						return <li key={category.id}>
							<Link to={`#${category.id}`}>
								<img src={`./assets/categories/${category.image}`} alt=""/>
								{category.name}
							</Link>
						</li>
					})}
				</ul>
			</aside>
			<div className="bodyListItens">
				<ul>
					{products.map((product, index)=>{
						if(products.length===(index+1)){
							if((parseInt(empresa.show_out_of_stock)===0||parseInt(empresa.stock_control_type)===0)){
								return <li ref={lastProductDiv} className="item" key={product.id} onClick={()=>{
									history.push({
										hash:location.hash,
										search: `productId=${product.id}`
									})
								}}>
									<div className="image">
										<img src={product.image?`./assets/${product.image}`:'./assets/default_product.png'} alt=""/>
									</div>
									<div className="bodyItem">
										<h1>{product.name}</h1>
										<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
											event.preventDefault()
											if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))){
												setDescription(product.description)
												openDescription(event)
											}
										}}>
											Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
										</div>
									</div>
									<div className="footerItem">
										{((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))?
										<div className="out_of_stock">
											Indisponível
										</div>
										:
										<>
										{(empresa.flag_show_price)?(
											<p className="price">{(parseInt(product.composite_product)===1
												&&
												parseInt(product.composition_type)!==0
												&&
												product.compositions?.length>0)
												?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...product.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?product.second_price:product.price)}</p>
										):''}
										{showStock(product)}
										<button className="add">
											<FiPlusCircle size={35} color="green"/>
										</button>
										</>
										}
									</div>
								</li>
							}
							else{
								if(product.quantity>0){
									return <li ref={lastProductDiv} className="item" key={product.id} onClick={()=>{
										addItemToCart(product.id)
									}}>
										<div className="image">
											<img src={product.image?`./assets/${product.image}`:'./assets/default_product.png'} alt=""/>
										</div>
										<div className="bodyItem">
											<h1>{product.name}</h1>
											<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
												event.preventDefault()
												if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))){
													setDescription(product.description)
													openDescription(event)
												}
											}}>
												Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
											</div>
										</div>
										<div className="footerItem">
											{((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))?
											<div className="out_of_stock">
												Indisponível
											</div>
											:
											<>
											{(empresa.flag_show_price)?(
												<p className="price">{(parseInt(product.composite_product)===1
													&&
													parseInt(product.composition_type)!==0
													&&
													product.compositions?.length>0)
													?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...product.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?product.second_price:product.price)}</p>
											):''}
											{showStock(product)}
											<button className="add">
												<FiPlusCircle size={35} color="green"/>
											</button>
											</>
											}
										</div>
									</li>
								}
							}
						}
						else{
							if((parseInt(empresa.show_out_of_stock)===0||parseInt(empresa.stock_control_type)===0)){
								return <li className="item" key={product.id} onClick={()=>{
									history.push({
										hash:location.hash,
										search: `productId=${product.id}`
									})
								}}>
									<div className="image">
										<img src={product.image?`./assets/${product.image}`:'./assets/default_product.png'} alt=""/>
									</div>
									<div className="bodyItem">
										<h1>{product.name}</h1>
										<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
											event.preventDefault()
											if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))){
												setDescription(product.description)
												openDescription(event)
											}
										}}>
											Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
										</div>
									</div>
									<div className="footerItem">
										{((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))?
										<div className="out_of_stock">
											Indisponível
										</div>
										:
										<>
										{(empresa.flag_show_price)?(
											<p className="price">{(parseInt(product.composite_product)===1
												&&
												parseInt(product.composition_type)!==0
												&&
												product.compositions?.length>0)
												?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...product.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?product.second_price:product.price)}</p>
										):''}
										{showStock(product)}
										<button className="add">
											<FiPlusCircle size={35} color="green"/>
										</button>
										</>
										}
									</div>
								</li>
							}
							else{
								if(product.quantity>0){
									return <li className="item" key={product.id} onClick={()=>{
										addItemToCart(product.id)
									}}>
										<div className="image">
											<img src={product.image?`./assets/${product.image}`:'./assets/default_product.png'} alt=""/>
										</div>
										<div className="bodyItem">
											<h1>{product.name}</h1>
											<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
												event.preventDefault()
												if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))){
													setDescription(product.description)
													openDescription(event)
												}
											}}>
												Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
											</div>
										</div>
										<div className="footerItem">
											{((parseInt(empresa.show_out_of_stock)===1&&parseInt(product.quantity)<=0))?
											<div className="out_of_stock">
												Indisponível
											</div>
											:
											<>
											{(empresa.flag_show_price)?(
												<p className="price">{(parseInt(product.composite_product)===1
													&&
													parseInt(product.composition_type)!==0
													&&
													product.compositions?.length>0)
													?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...product.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?product.second_price:product.price)}</p>
											):''}
											{showStock(product)}
											<button className="add">
												<FiPlusCircle size={35} color="green"/>
											</button>
											</>
											}
										</div>
									</li>
								}
							}
						}
					})}
					<div className="loaderContainer" style={(loading&&!error)?{display: 'flex'}:{display: 'none'}}>
						<div className="loader"></div>
					</div>
					<div className="errorChargingItems" style={(error)?{display: 'flex'}:{display: 'none'}}>
						<FiAlertTriangle size={25} color="red"/> Erro ao carregar itens
					</div>
				</ul>
			</div>
			<div className="modalContent" id="modalAdd">
                <div className="modal">{modalContent}
                <button className="close" onClick={(event)=>{
					closeModal(event)
					history.push({
						hash: location.hash,
						search: ``
					})
				}}>
                    <FiX size="30" color="#ccc" />
                </button>
                </div>
            </div>
			<div className="modalContent" id="modalDetailsDirect" style={{zIndex:1080}}>
				<div className="modal">
					<div className="headerModal">
						Detalhamento do item
					</div>
					<div className="contentBodyProduct">
					<div className="body">
						<div dangerouslySetInnerHTML={{__html: description}} className="descriptionOfItem">
						</div>
					</div>
					</div>
					<button className="close" onClick={(event)=>{
						closeModal(event)
						}}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
		</div>
	)
}

export default CategoryPage
