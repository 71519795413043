import React, {useState, useEffect, useRef, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import './style.css'
import api from '../../services/api'
import useSearchProduct from './useSearchProduct'
import {FiAlertTriangle} from 'react-icons/fi'
function SearchModal() {

	const history = useHistory()

	const [searchTerm, setSearchTerm] = useState('')
	const [empresa, setEmpresa] = useState({})
	const [category, setCategory] = useState('')
	const [categories, setCategories] = useState([])
	const [page, setPage] = useState(1)

	const {
		items,
		hasMore,
		loading,
		error
	} = useSearchProduct(page, searchTerm, category)

	const observer = useRef()

	const lastProductDiv = useCallback(node=>{
		if (loading) return
		if(observer.current) observer.current.disconnect()
		observer.current=new IntersectionObserver(entries=>{
			if(entries[0].isIntersecting && hasMore){
				setPage(prevPage=>prevPage+1)
			}
		})
		if (node) observer.current.observe(node)
	}, [loading, hasMore])

	useEffect(()=>{
		api.get(`/categories/?onlyActives=true`).then(response=>{
			setCategories(response.data)
		})
		api.get('/company').then(response=>{
			setEmpresa(response.data)
		})
	}, [])

	useEffect(()=>{
		setPage(1)
	}, [category, searchTerm])

	const [hasSecondPrice, setHasSecondPrice] = useState(false)
	var userToken = localStorage.getItem('userToken')

	useEffect(()=>{
		if(userToken){
			api.get('/customer/validate', {
				headers: {
					auth: userToken
				}
			}).then(({data})=>{
				setHasSecondPrice(parseInt(data.customer_group.second_price)===1)
			}).catch(()=>{
				setHasSecondPrice(false)
				localStorage.removeItem('userToken')
			})
		}
		else{
			setHasSecondPrice(false)
		}
	}, [userToken])



	useEffect(()=>{
		api.get(`/categories/`).then(response=>{
			setCategories(response.data)
		})

		api.get('/company/').then(response=>{
			if(response.data.flag_screen_type===1){
				setCategory(document.location.hash.replace('#', ''))
			}
		})
	}, [])

	return (
		<>
		<div className="containerSearch">
			<div className="header">
				<h1>Pesquisar produto</h1>
			</div>
			<div className="body">
				<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
				<select name="" id="" style={{fontSize:"22px", color: "#444", fontFamily: 'Roboto'}} value={category} onChange={event=>setCategory(event.target.value)}>
					<option value="">Selecione uma categoria...</option>
					{categories.map(cat=>{
						return <option key={cat.id} value={cat.id}>{cat.name}</option>
					})}
				</select>
				<div className="listItensSearch">
					{items.map((item, index)=>{
						if((empresa.show_out_of_stock===0||empresa.stock_control_type===0)){
							return (
								<div ref={(items.length===(index+1)?lastProductDiv:undefined)} className="containerItem" key={item.id} data-id={item.id} onClick={()=>{
									history.push(`?productId=${item.id}`)
								}}>
									<div className="itemLeft">
										<h1>{item.name}</h1>
										<div className="price">{(parseInt(item.composite_product)===1
									&&
									parseInt(item.composition_type)!==0
									&&
									item.compositions?.length>0)
									?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</div>
									{(empresa.show_out_of_stock==1&&item.quantity<=0)?<>
									<div className="notDisp">Indisponível</div>
									</>:<></>}
									</div>
									<div className="itemRight">
										<div className="image">
											<img src={`./assets/${item.image}`} alt=""/>
										</div>
									</div>
								</div>
							)
						}
						else{
							if(item.quantity>0){
								return (
									<div ref={(items.length===(index+1)?lastProductDiv:undefined)} className="containerItem"  key={item.id} data-id={item.id} onClick={()=>{
										history.push(`?productId=${item.id}`)
									}}>
										<div className="itemLeft">
										<h1>{item.name}</h1>
										<div className="price">{(parseInt(item.composite_product)===1
											&&
											parseInt(item.composition_type)!==0
											&&
											item.compositions?.length>0)
											?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</div>
										</div>
										<div className="itemRight">
											<div className="image">
												<img src={`./assets/${item.image}`} alt=""/>
											</div>
										</div>
									</div>
								)
							}
						}
					})}
					{(loading&&!error)?<div className="loaderContainer" style={{paddingBottom: "25px"}}>
						<div className="loader"></div>
					</div>:<></>}
					{(error)?<div className="errorChargingItems" style={{paddingBottom: "25px"}}>
						<FiAlertTriangle size={25} color="red"/> Erro ao carregar itens
					</div>:<></>}
				</div>
			</div>
		</div>
		</>
	)
}


export default SearchModal
