import axios from 'axios'
import config from './config.json'

var baseUrl = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Development:
    baseUrl = config.backendUrl
} else {
    //Production:
    baseUrl = window.location.origin.toString()
    
}

const api = axios.create({baseURL: baseUrl})

export default api
