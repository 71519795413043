import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import './style.css'
import api from '../../services/api'
import {FiImage, FiSave} from 'react-icons/fi'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'

import {FaBars} from 'react-icons/fa'

function Admin_dash(){

	const [imageLogo, setImageLogo] = useState('')
	const [imageBanner, setImageBanner] = useState(``)
	const [razao, setRazao] = useState('')
	const [fantasia, setFantasia] = useState('')
	const [cnpj, setCnpj] = useState('')
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [titleExtra1, setTitleExtra1] = useState('')
	const [titleExtra2, setTitleExtra2] = useState('')
	const [extra1, setExtra1] = useState('')
	const [extra2, setExtra2] = useState('')
	const [theme, setTheme] = useState('')
	const [showEstoque, setShowEstoque] = useState('')
	const [closed, setClosed] = useState(0)
	const [wait_time, setWaitTime] = useState('00:00:00')
	const [show_wait_time_printing, setShowWaitTimePrinting] = useState(0)
	const [showWaitTime, setShowWaitTime] = useState(0)
	const [flag_show_price, setFlag_show_price] = useState('')
	const [top_size, setTopSize] = useState(0)
	const [flag_show_image_outStock, setFlag_show_image_outStock] = useState('')
	const [stock_control_type, setStockControlType] = useState(0)
	const [print_title, setPrintTitle] = useState('')
	const [print_model, setPrintModel] = useState('')
	const [extra_image_1, setExtraImage1] = useState('')
	const [extra_image_2, setExtraImage2] = useState('')
	const [version, setVersion] = useState('')

	useEffect(()=>{
		api.get('/company').then(response=>{
			setRazao(response.data.razao)
			setFantasia(response.data.fantasia)
			setImageLogo(`${window.location.origin.toString()}/${response.data.logo}`)
			setImageBanner(`${window.location.origin.toString()}/${response.data.banner}`)
			setCnpj(response.data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5'))
			setClosed(response.data.closed)
			setAddress(response.data.address)
			setShowEstoque(response.data.show_estoque)
			setTitleExtra1(response.data.extra_field_title_1)
			setTitleExtra2(response.data.extra_field_title_2)
			setExtra1(response.data.extra_field_1)
			setExtra2(response.data.extra_field_2)
			setTheme(response.data.theme)
			setPhone(response.data.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, "($1) $2-$3"))
			setShowWaitTime(response.data.show_wait_time)
			setWaitTime(response.data.wait_time)
			setShowWaitTimePrinting(response.data.show_wait_time_printing)
			setFlag_show_price(response.data.flag_show_price)
			setTopSize(response.data.top_size)
			setFlag_show_image_outStock(response.data.show_out_of_stock)
			setStockControlType(response.data.stock_control_type)
			setPrintTitle(response.data.print_title)
			setPrintModel(response.data.print_model)
			setExtraImage1(response.data.extra_image_1)
			setExtraImage2(response.data.extra_image_2)
		})
	}, [])

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		api.get('/appVersion/', {
			headers: {
				auth
			}
		}).then(({data})=>{
			setVersion(data.version)
		})
	}, [auth])
	const history = useHistory()

	if(!auth){
		history.push('/admin/login')
	}
	else{
		try{
			api.get('/user/auth', {
				headers:{
					'auth': auth
				}
			})
			.catch(error=>{
				alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\n${error.response.data.message}`)
				localStorage.clear()
				history.push('/admin/login')
			})
		}
		catch(error){
			alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\nError: ${error.data.message}`)
			localStorage.clear()
			history.push('/admin/login')
		}
	}

	async function handleSave(event){
		event.preventDefault()
		await api.post('company', {
			imgBanner: imageBanner,
			imgLogo: imageLogo,
			closed,
			razao,
			fantasia,
			phone: phone.replace(/\D/g, ''),
			cnpj: cnpj.replace(/\D/g, ''),
			address,
			show_estoque: showEstoque,
			extra_field_1: extra1,
			extra_field_2: extra2,
			extra_field_title_1: titleExtra1,
			extra_field_title_2: titleExtra2,
			theme,
			wait_time,
			show_wait_time_printing,
			showWaitTime,
			flag_show_price,
			top_size,
			show_out_of_stock: flag_show_image_outStock,
			stock_control_type,
			print_title,
			print_model,
			extra_image_1,
			extra_image_2
		}).then(response=>{
			alert('Registro atualizado com sucesso!')
			window.location.reload()
		}).catch(error=>{
			alert(`Erro ao gravar registro: ${error.response.data.message}`)
		})
	}

	function getImageBase64(file){
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		})
	}

	function changeImageLogo(event){
		const input = event.target
		getImageBase64(input.files[0]).then(data=>{
			setImageLogo(data)
		})
	}

	function changeImageBanner(event){
		const input = event.target
		getImageBase64(input.files[0]).then(data=>{
			setImageBanner(data)
		})
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	function getImageContent(image){
		if((/^data:image\/([a-zA-Z]*);base64,([^"]*)/g).test(image)){
			return image
		}
		else{
			return window.location.origin.toString()+'/'+image
		}
	}

	return (
		<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
			<div className="body admBody" style={{marginTop: "50px"}}>
			<div className="body configBody">
			<div className="config">
				<div className="headerAdmPage">
					<div className="header">
						<h1>Configuração</h1>
					</div>
				</div>
				<div className="formTopContainer">
					<div className="banner">
						<img src={imageBanner} alt=""/>
						<div className="logoImageFixed">
							<div className="logo">
								<img src={imageLogo} alt="" id="imageLogo"/>
								<label htmlFor="imageLogoInput" id="imageLogoLabel">
									<FiImage size={40} color="white"/>
									Trocar Imagem
								</label>
								<input type="file" id="imageLogoInput" onChange={changeImageLogo}/>
							</div>
						</div>
						<label htmlFor="imageBannerInput" id="imageBannerLabel"><FiImage size={22} color="white"/>Adicionar Imagem</label>
						<input type="file" id="imageBannerInput" onChange={changeImageBanner}/>
					</div>
				</div>
				<form action="" className="configForm" onSubmit={handleSave}>
					<div className="input-group inputLarge">
						<input type="text" name="razao" id="razao" value={razao} onChange={event=>setRazao(event.target.value)}/>
						<label htmlFor="razao" required={true}>Razão Social</label>
					</div>
					<div className="input-group">
						<input type="cnpj" required={true} pattern="\d{2}.\d{3}.\d{3}/\d{4}-\d{2}" onInvalid={event=>{
							event.target.setCustomValidity('Informe um CNPJ válido!')
						}}
						onInput={event=>{
							event.target.setCustomValidity('')
						}} value={cnpj} onChange={event=>setCnpj(event.target.value.replace(/\D/g, '')
						.replace(/(^\d{2})(\d{1,3})$/g, '$1.$2')
						.replace(/(^\d{2})(\d{3})(\d{1,3})$/g, '$1.$2.$3')
						.replace(/(^\d{2})(\d{3})(\d{3})(\d{1,4})$/g, '$1.$2.$3/$4')
						.replace(/(^\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})$/g, '$1.$2.$3/$4-$5'))} name="cnpj" id="cnpj" maxLength={18}/>
						<label htmlFor="cnpj">CNPJ</label>
					</div>
					<div className="input-group inputLarge">
						<input type="text" name="razao" id="razao" value={fantasia} onChange={event=>setFantasia(event.target.value)}/>
						<label htmlFor="razao" required={true}>Nome Fantasia</label>
					</div>
					<div className="input-group">
						<input type="phone" name="phone" pattern="\(\d{2}\) \d{4,5}-\d{1,4}" maxLength={15} value={phone} onChange={event=>setPhone(event.target.value.replace(/\D/g, "").replace(/^(\d{1,2})$/g, '($1').replace(/^(\d{2})(\d{1,4})$/g, '($1) $2').replace(/^(\d{2})(\d{4})(\d{1,4})$/g, '($1) $2-$3').replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3'))} id="phone"/>
						<label htmlFor="phone">Telefone (Whatsapp)</label>
					</div>
					<div className="input-group inputLarge">
						<input type="text" name="address" value={address} onChange={event=>setAddress(event.target.value)} id="address"/>
						<label htmlFor="address">Endereço</label>
					</div>
					<div className="input-group">
						<select name="topHeader" id="topHeader" value={top_size} onChange={event=>setTopSize(event.target.value)}>
							<option value={0}>Recuo Pequeno</option>
							<option value={30}>Recuo Médio</option>
							<option value={60}>Recuo Grande</option>
						</select>
						<label htmlFor="topHeader">Recuo:</label>
					</div>
					<div className="input-group">
						<select name="" id="" value={showEstoque} onChange={event=>{
							setShowEstoque(event.target.value)
						}}>
							<option value="1">Sim</option>
							<option value="0">Não</option>
						</select>
						<label htmlFor="">Exibir estoque?</label>
					</div>
					<div className="input-group" style={{gridColumnStart: 2, gridColumnEnd:4}}>
						<div className="selector">
						<select name="theme" id="theme" value={theme} onChange={event=>setTheme(event.target.value)}>
							<option value="blue_theme">Azul</option>
							<option value="red_theme">Vermelho</option>
							<option value="brown_theme">Marrom</option>
							<option value="black_theme">Preto</option>
							<option value="orange_theme">Laranja</option>
							<option value="purple_theme">Roxo</option>
							<option value="green_theme">Verde</option>
							<option value="dark_olive_green_theme">Verde Musgo</option>
						</select>
						<div className={`indicator ${theme}`}></div>
						</div>
						<label htmlFor="theme">Tema</label>
					</div>
					<div style={{display: "flex", flexDirection: 'column'}}>
						<div style={{display: "flex", alignItems: "center"}}>
							<input type="checkbox" id="wait_time" checked={showWaitTime===1} onChange={event=>{
								setShowWaitTime(((event.target.checked===true)?1:0))
								}}/>
							<label htmlFor="wait_time">Exibir tempo de espera</label>
						</div>
						<div style={{display: "flex", alignItems: "center"}}>
							<input type="checkbox" id="flag_show_prices" checked={flag_show_price===1} onChange={event=>{
								setFlag_show_price(((event.target.checked===true)?1:0))
								}}/>
							<label htmlFor="flag_show_prices">Exibir preços na tela principal</label>
						</div>
						<div style={{display: "flex", alignItems: "center"}}>
							<input type="checkbox" id="closed" checked={closed===1} onChange={event=>{
								setClosed(((event.target.checked===true)?1:0))
								}}/>
							<label htmlFor="closed">Expediente encerrado</label>
						</div>
					</div>
					<div className="input-group inputLarge">
						<textarea name="" id="" value={extra1} onChange={event=>setExtra1(event.target.value)}/>
						<input type="text" value={titleExtra1} onChange={event=>setTitleExtra1(event.target.value)}/>
						<label htmlFor="">Campo extra 1</label>
					</div>
					<div style={{
							gap: 10,
							display: 'flex',
							flexDirection: 'column'
						}}>
						<div className="input-group">
							<select name="" id="" value={print_title} onChange={event=>{
								setPrintTitle(event.target.value)
							}}>
								<option value="pedido">Pedido</option>
								<option value="orçamento">Orçamento</option>
								<option value="cotação">Cotação</option>
							</select>
							<label htmlFor="">Título de impressão</label>
						</div>
						<div className="input-group">
							<select name="print_model" id="" value={print_model} onChange={event=>{
								setPrintModel(event.target.value)
							}}>
								<option value="0">Modelo 1 (impressora 80)</option>
								<option value="1">Modelo 2 (impressora 80)</option>
								<option value="2">Modelo 3 (impressora 80)</option>
								<option value="3">Modelo impressora 58</option>
							</select>
							<label htmlFor="print_model">Modelo de impressora</label>
						</div>
					</div>
					<div className="input-group inputLarge">
						<textarea name="" id="" value={extra2} onChange={event=>setExtra2(event.target.value)}/>
						<input type="text" value={titleExtra2} onChange={event=>setTitleExtra2(event.target.value)}/>
						<label htmlFor="">Campo extra 2</label>
					</div>
					<div className="input-group">
						<div style={{display: "flex", alignItems: "center"}}>
							<input type="checkbox" id="showPrintingTime" checked={show_wait_time_printing===1} onChange={event=>{
								setShowWaitTimePrinting(((event.target.checked===true)?1:0))
								}}/>
							<label htmlFor="showPrintingTime">Exibir na impressão</label>
						</div>
						<input type="time" value={wait_time} onChange={event=>setWaitTime(event.target.value)} id="waitTime"/>
						<label htmlFor="waitTime">Tempo de espera</label>
					</div>
					<div className="input-group inputLarge images">
						<div className="extraImage">
							<img className="extraImagesContent" src={getImageContent(extra_image_1)} alt=""/>
							<label className="imageLabel" htmlFor="extraImageInput1"><FiImage color="white" size={22}/>Alterar imagem</label>
							<input type="file" id="extraImageInput1" onChange={(event)=>{
								const input = event.target
								getImageBase64(input.files[0]).then(data=>{
									setExtraImage1(data)
								})
							}}/>
						</div>
						<div className="extraImage">
							<img className="extraImagesContent" src={getImageContent(extra_image_2)} alt=""/>
							<label className="imageLabel" htmlFor="extraImageInput2"><FiImage color="white" size={22}/>Alterar imagem</label>
							<input type="file" id="extraImageInput2" onChange={(event)=>{
								const input = event.target
								getImageBase64(input.files[0]).then(data=>{
									setExtraImage2(data)
								})
							}}/>
						</div>
					</div>
					<div className="stock-control">
						<div style={{display: "flex", alignItems: "center"}}>
							<input type="checkbox" id="flag_show_out_of_stock" checked={flag_show_image_outStock===1} onChange={event=>{
								setFlag_show_image_outStock(((event.target.checked===true)?1:0))
								}}/>
							<label htmlFor="flag_show_out_of_stock">Controlar estoque</label>
						</div>
						<div className="input-group">
							<select disabled={!flag_show_image_outStock} value={stock_control_type} onChange={event=>setStockControlType(event.target.value)}>
								<option value={0}>Exibir como Indisponível</option>
								<option value={1}>Não exibir produto</option>
							</select>
							<label id={(!flag_show_image_outStock)?'disabled':''}>Opções:</label>
						</div>
					</div>
				<div className="footerForm">
					<button type="submit" className="save">
						<FiSave size={22} color="white"/>
						Salvar
					</button>
				</div>
				</form>
			</div>
			<footer className="footerVersion">
				Versão {version}
			</footer>
		</div>
			</div>
		</div>
		</div>

		</>

	)
}

export default Admin_dash
