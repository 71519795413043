import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Home from './pages/Home'
import Categorias from './pages/Categorias'
import Order from './pages/Order'
import Admin_login from './pages/Admin_login'
import Admin_dash from './pages/Admin_dash'
import Admin_dash_prod from './pages/Admin_dash_prod'
import Admin_dash_pedidos from './pages/Admin_dash_pedidos'
import Admin_dash_config from './pages/Admin_dash_config'
import Admin_dash_categorias from './pages/Admin_dash_categorias'
import Admin_dash_usuario from './pages/Admin_dash_usuario'
import Admin_dash_cidade from './pages/Admin_dash_cidade'
import Admin_dash_formas_pag from './pages/Admin_dash_formas_pag'
import Admin_dash_entregador from './pages/Admin_dash_entregador'
import Admin_dash_rel_entrega from './pages/Admin_dash_rel_entrega'
import Admin_customers from './pages/Admin_customers'
import Admin_customer_group from './pages/Admin_customer_group'
import Admin_horario_func from './pages/Admin_horario_func'
import Admin_feriados from './pages/Admin_feriados'
import RecoverPage from './pages/RecoverPage'
import api from './services/api'

export default function Routes(){

	document.querySelector('meta[name=description]').remove()
	api.get('/company').then(response=>{
		document.querySelector('head').append(`<meta name="description" content="${response.data.razao}, Telefone: ${response.data.phone}">`)
	})

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Home}/>
				<Route exact path="/categorias" component={Categorias}/>
				<Route exact path="/pedido/:id" component={Order}/>
				<Route exact path="/admin/" component={Admin_login}/>
				<Route exact path="/admin/login" component={Admin_login}/>
				<Route exact path="/admin/dashboard" component={Admin_dash}/>
				<Route exact path="/admin/dashboard/produtos" component={Admin_dash_prod}/>
				<Route exact path="/admin/dashboard/pedidos" component={Admin_dash_pedidos}/>
				<Route exact path="/admin/dashboard/categorias" component={Admin_dash_categorias}/>
				<Route exact path="/admin/dashboard/config" component={Admin_dash_config}/>
				<Route exact path="/admin/dashboard/usuarios" component={Admin_dash_usuario}/>
				<Route exact path="/admin/dashboard/clientes" component={Admin_customers}/>
				<Route exact path="/admin/dashboard/grupos/cliente" component={Admin_customer_group}/>
				<Route exact path="/admin/dashboard/cidades" component={Admin_dash_cidade}/>
				<Route exact path="/admin/dashboard/formasPagamento" component={Admin_dash_formas_pag}/>
				<Route exact path="/admin/dashboard/entregadores" component={Admin_dash_entregador}/>
				<Route exact path="/admin/dashboard/relatorios/entrega" component={Admin_dash_rel_entrega}/>
				<Route exact path="/admin/dashboard/horariosFunc/" component={Admin_horario_func}/>
				<Route exact path="/admin/dashboard/feriados/" component={Admin_feriados}/>
				<Route exact path="/customer/reset/" component={RecoverPage}/>
			</Switch>
		</BrowserRouter>
	)
}
