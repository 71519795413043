import React, { Suspense } from 'react';
import './styles.css'
import {FaBars} from 'react-icons/fa'
const MenuToggleAdmin = React.lazy(()=>import('../../Components/menuToggleAdmin'))
const AdmListUsuarios = React.lazy(()=>import('../../Components/AdmListUsuarios'))

function Admin_dash(){
    document.title="Clarion Pedido - Usuários"
    function toggleMenuActive(){
        const menuContainer = document.querySelector('aside.menu')

        menuContainer.classList.toggle('active')
    }
    return (
        <>
        <div className="pageCompleteWithMenu">
        <aside className="menu active">
            <Suspense fallback={()=>{}}>
				<MenuToggleAdmin/>
			</Suspense>
        </aside>
        <div className="headerMenuAdm">
                <div className="containerAdmin">
                    <div className="openMenu" onClick={toggleMenuActive}>
                        <FaBars size={30} color="white"/>
                    </div>
                </div>
        </div>
        <div className="content">
            <div className="body admBody" style={{marginTop: "50px"}}>
                <Suspense fallback={()=>{}}>
					<AdmListUsuarios/>
				</Suspense>
            </div>
        </div>
        </div>
        </>
    )
}

export default Admin_dash
