import React, {useState, useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom'
import './style.css'
import {FiBox, FiList, FiSettings, FiLogOut, FiGrid, FiUser, FiCreditCard, FiUsers, FiClock} from 'react-icons/fi'
import {FaDesktop,FaCity, FaTruckMoving, FaClipboardList} from 'react-icons/fa'
import companyLogo from './Clariontec.png'
import api from '../../services/api'
function MenuToggle(){
	const history = useHistory()

	const auth = localStorage.getItem('auth')

	const [user, setUser] = useState(null)

	useEffect(()=>{
		if(!auth){
			history.push('/admin/')
		}
		else{
			try{
				api.get('/user/auth', {
					headers:{
						'auth': auth
					}
				})
				.then(response=>{
					setUser(response.data)
				})
				.catch(error=>{
					alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\n${error.response.data.message}`)
					localStorage.clear()
					history.push('/admin/')
				})
			}
			catch(error){
				alert(`Sessão inválida: token de autenticação fornecido não é válido ou está expirado! Faça login novamente!\nError: ${error.data.message}`)
				localStorage.clear()
				history.push('/admin/')
			}
		}
	}, [auth, history])

	function handleLogout(){
		if(window.confirm('Deseja realmente sair?')){
			localStorage.removeItem('auth')

			history.push('/admin/')
		}
	}
	return (
		<>
		<header>
			<img src={companyLogo} alt=""/>
		</header>
		<div className="user">
			<div className="imageUser">
				<img src={`${window.location.origin.toString()}/assets/users/${user&&user.image}`} alt=""/>
			</div>

			<div className="nameUser">{user&&user.firstname}</div>
		</div>
		<ul className="menuToggleAdmin">
			<li>
				<Link to='/admin/dashboard'><FaDesktop/> DashBoard</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/pedidos/?status=0,2,3'><FiList/> Pedido</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/produtos'><FiBox/> Produto</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/categorias'><FiGrid/> Categoria</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/formasPagamento'><FiCreditCard/> Forma de pagamento</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/entregadores'><FaTruckMoving/> Entregador</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/cidades'><FaCity/> Frete</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/usuarios'><FiUser/> Usuários</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/clientes'><FiUsers/> Clientes</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/grupos/cliente'><FiUsers/> Grupos de Cliente</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/relatorios/entrega'><FaClipboardList/> Relatório de entrega</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/feriados'><FiClock/> Feriados</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/horariosFunc'><FiClock/> Funcionamento</Link>
			</li>
			<li>
				<Link to='/admin/dashboard/config'><FiSettings/> Configuração</Link>
			</li>
			<li>
				<Link to='#' onClick={handleLogout}><FiLogOut/> Sair</Link>
			</li>
		</ul>
		</>
	)
}

export default MenuToggle
