import React, {useState, useEffect, useRef} from 'react'
import 'react-tabs/style/react-tabs.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import './style.css'
import {FiShoppingCart, FiPlusCircle, FiMinusCircle, FiX, FiUser} from 'react-icons/fi'
import {FaMoneyBill} from 'react-icons/fa'
import api from '../../services/api'
import axios from 'axios'
const moment = require('moment')
const crypto = require('crypto')

const ENC_KEY = "bf3c199c2470cb477d907b1e0967817b";

function CartModal(props){
	const [customerLogged, setCustomerLogged] = useState()
	var userToken = localStorage.getItem('userToken')

	const [nome, setNome] = useState(customerLogged?`${customerLogged.firstname} ${customerLogged.lastname}`:'')
	const [telefone, setTelefone] = useState(customerLogged?customerLogged.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3'):'')
	const [email, setEmail] = useState(customerLogged?customerLogged.email:'')
	const [endereco, setEndereco] = useState(customerLogged?customerLogged.address:'')
	const [bairro, setBairro] = useState(customerLogged?customerLogged.cityRegionId:'')
	const [cidade, setCidade] = useState(customerLogged?customerLogged.cidadeId:'')
	const [frete, setFrete] = useState(0)
	const [cep, setCep] = useState(customerLogged?customerLogged.cep.replace(/(\d{5})(\d{3})/g, '$1-$2'):'')
	const [obs, setObs] = useState('')
	const [empresa, setEmpresa] = useState({})
	const [cityList, setCityList] = useState([])
	const [cityRegionList, setCityRegionList] = useState([])
	const [itens, setItens] = useState([])
	const [paymentMethods, setPaymentMethods] = useState([])
	const [paymentMethod, setPaymentMethod] = useState('')
	const [seller, setSeller] = useState(0)
	const [selectedTab, setSelectedTab] = useState(0)
	const [entregaRetirada, setEntregaRetirada] = useState(0)

	const [listOpeningHours, setListOpeningHours] = useState([])
	const [listHollydays, setlistHollydays] = useState([])
	const [loading, setLoading] = useState(false)

	const [hasAlert, setHasAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const inputNome = useRef()
	const inputCep = useRef()
	const inputCidade = useRef()
	const inputBairro = useRef()
	const inputTelefone = useRef()
	const inputPaymentMthd = useRef()
	const inputAddress = useRef()

	useEffect(()=>{
		api.get('company').then(response=>{
		  setEmpresa(response.data)
		  document.title=response.data.fantasia
		})
		api.get('user/soft').then(response=>{
			if(response.data.length>0){
				setSeller(response.data[0].id)
			}
		})
		api.get(`/openingDays/`).then(response=>{
			setListOpeningHours(response.data)
		})
		api.get(`/hollyday/`).then(response=>{
			setlistHollydays(response.data)
		})
	},[])

	useEffect(()=>{
		if(userToken){
			api.get('/customer/validate', {
				headers: {
					auth: userToken
				}
			}).then(({data})=>{
				setCustomerLogged(data)
			}).catch(()=>{
				setCustomerLogged()
				localStorage.removeItem('userToken')
			})
		}
		else{
			setCustomerLogged()
		}
	}, [userToken])

	useEffect(()=>{
		if(customerLogged){
			setNome(`${customerLogged.firstname} ${customerLogged.lastname}`)
			setTelefone(customerLogged.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3'))
			setEmail(customerLogged.email)
			setBairro(customerLogged.cityRegionId)
			setCidade(customerLogged.cidadeId)
			setCep(customerLogged.cep.replace(/(\d{5})(\d{3})/g, '$1-$2'))
			setEndereco(customerLogged.address)
		}
		else{
			setNome()
			setTelefone()
			setEmail()
			setBairro()
			setCidade()
			setCep()
			setEndereco()
		}
	}, [customerLogged])

	function verifyIsOpen(){


		const actualHollyday = listHollydays.find(l=>(`${l.date}/${(new Date()).getFullYear()}`)===moment(new Date()).format('DD/MM/YYYY'))

		if(actualHollyday){
			const dayOpeningHours = listOpeningHours.filter(lop=>lop.is_hollyday===1)

			if(dayOpeningHours.length>0){
				const nowTimeString = (`${((new Date()).getHours()).toString().padStart(2,'0')}:${((new Date()).getMinutes()).toString().padStart(2,'0')}:${((new Date()).getSeconds()).toString().padStart(2,'0')}`)

				const currentInterval = dayOpeningHours.find(doh=>(doh.time_start<nowTimeString&&nowTimeString<doh.time_end))

				if(currentInterval){
					return true
				}
				else{
					return false
				}
			}
			else{
				return false
			}
		}
		else{
			const dayOpeningHours = listOpeningHours.filter(lop=>parseInt(lop.week_day)===parseInt((new Date()).getDay()))
			if(dayOpeningHours.length>0){
				const nowTimeString = (`${((new Date()).getHours()).toString().padStart(2,'0')}:${((new Date()).getMinutes()).toString().padStart(2,'0')}:${((new Date()).getSeconds()).toString().padStart(2,'0')}`)

				const currentInterval = dayOpeningHours.find(doh=>(doh.time_start<nowTimeString&&nowTimeString<doh.time_end))

				if(currentInterval){
					return true
				}
				else{
					return false
				}
			}
			else{
				return false
			}
		}
	}

	const setToTalBuyed = props.setToTalBuyed

	useEffect(()=>{
		if(itens){
			var total = itens.map(item=>{
				return calculateTotalWithCompositions(item)
			}).reduce((a, b)=>{
				return a+b
			}, 0)
			setToTalBuyed(total)
			if(cidade){
				api.get(`/city/${cidade}`).then(response=>{
					if(bairro){
						api.get(`/cityRegion/${bairro}`).then(({data: region})=>{
							if(region.shipping_value){
								if(itens.length>0){
									setFrete(region.shipping_value)
									total=itens.map(item=>{
										return calculateTotalWithCompositions(item)
									}).reduce((a, b)=>{
										return a+b
									}, 0)
									total+=parseFloat(region.shipping_value)
									setToTalBuyed(total)
								}
							}
							else{
								if(itens.length>0){
									setFrete(response.data.frete)
									total=itens.map(item=>{
										return calculateTotalWithCompositions(item)
									}).reduce((a, b)=>{
										return a+b
									}, 0)
									total+=parseFloat(response.data.frete)
									setToTalBuyed(total)
								}
							}
						})
						.catch(()=>{
							if(itens.length>0){
								setFrete(response.data.frete)
								total=itens.map(item=>{
									return calculateTotalWithCompositions(item)
								}).reduce((a, b)=>{
									return a+b
								}, 0)
								total+=parseFloat(response.data.frete)
								setToTalBuyed(total)
							}
						})
					}
					else{
						setFrete(response.data.frete)
						total=itens.map(item=>{
							return calculateTotalWithCompositions(item)
						}).reduce((a, b)=>{
							return a+b
						}, 0)
						total+=parseFloat(response.data.frete)
						setToTalBuyed(total)
					}
				}).catch(()=>{
					setFrete(0)
				})
			}
			else{
				setFrete(0)
			}
		}
	}, [cidade, bairro, itens, setToTalBuyed])

	useEffect(()=>{
		api.get(`/cityRegion/?cidadeId=${cidade}`).then(({data: regions})=>{
			setCityRegionList(regions)
		})
	}, [cidade])

	async function sendOrder(id){

		if(empresa.closed===1){
			alert("Não é possível fazer pedido com o expediente encerrado!")
			return false
		}

		if(!id){
			id=seller
		}

		setSeller(id)

		var encrypt = ((val) => {
		  let cipher = crypto.createCipher('aes-256-cbc', ENC_KEY)
		  let encrypted = cipher.update(val, 'utf8')
		  encrypted += cipher.final('hex')
		  return encrypted;
		});



		var itens = JSON.parse(localStorage.getItem('itens'))
		await api.get('company').then(response=>{
			setEmpresa(response.data)
		})

		try {

		var customer = {
			...(customerLogged ? {
				customer_id: customerLogged.id
			}:{}),
			nome,
			telefone,
			email,
			endereco,
			bairro: cityRegionList.find(cr=>parseInt(cr.id)===parseInt(bairro))?cityRegionList.find(cr=>parseInt(cr.id)===parseInt(bairro)).name:'',
			cityRegionId: bairro,
			cep,
			cidade
		}

		var response = await api.post('/order/new', {
			customer,
			obs,
			itens,
			store_name: empresa.fantasia,
			store_url: window.location.origin.toString(),
			frete,
			sub_total: props.total-frete,
			total: props.total,
			seller: id,
			paymentMethod,
			is_delivery: (entregaRetirada===0?1:0)
		}, {
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'content-type': 'application/json',
				"Access-Control-Allow-Origin": "*"
			}
		  })

		const encryptedId = encrypt(response.data.id.toString())
		var text = encodeURI(
`Pedido: ${response.data.id}
Forma de pagamento: ${paymentMethod}
Valor: ${Intl.NumberFormat('pt-BR', {style: 'currency', currency:'BRL'}).format(props.total)}
Link do pedido: ${window.location.origin.toString()}/pedido/${encryptedId}`
)
		localStorage.removeItem('itens')
		setItens()
		setToTalBuyed(0)

		var userPhone = ''

		api.get('user/soft').then(response=>{
			userPhone = response.data.filter(user=>parseInt(user.id)===parseInt(id))[0].whatsapp
			setLoading(false)
			setHasAlert(true)
			setAlertMessage(`Pedido cadastrado com sucesso!`)
			document.location=`https://wa.me/55${userPhone}?text=${text}`
		})
		}
		catch(error){
			if(error.response){
				setHasAlert(true)
				setAlertMessage(`Erro ao cadastrar pedido: ${error.response.data.message}`)
				setLoading(false)
			}
			else{
				setHasAlert(true)
				setAlertMessage(`Erro ao cadastrar pedido: ${error}`)
				setLoading(false)
			}
		}

		setLoading(false)

	}

	function validateFields(){
		if(entregaRetirada===0){
			if(nome&&cep&&cidade&&telefone&&endereco&&bairro){
				if(!(/\d{5}-\d{3}/g).test(cep)){
					inputCep.current.setCustomValidity(`Informe um cep válido!`)
					inputCep.current.reportValidity()
					inputCep.current.focus()
					return false
				}
				if(!(/\(\d{2}\) \d{4,5}-\d{4}/g).test(telefone)){
					inputTelefone.current.setCustomValidity(`Informe um telefone válido!`)
					inputTelefone.current.reportValidity()
					inputTelefone.current.focus()
					return false
				}
				return true
			}
			else{
				if(!nome) {
					inputNome.current.reportValidity()
					inputNome.current.focus()
					return false
				}
				if(!cep){
					inputCep.current.reportValidity()
					inputCep.current.focus()
					return false
				}
				if(!telefone){
					inputTelefone.current.reportValidity()
					inputTelefone.current.focus()
					return false
				}
				if(!cidade){
					inputCidade.current.reportValidity()
					inputCidade.current.focus()
					return false
				}
				if(!endereco){
					inputAddress.current.reportValidity()
					inputAddress.current.focus()
					return false
				}
				if(!bairro){
					inputBairro.current.reportValidity()
					inputBairro.current.focus()
					return false
				}
			}
		}
		else{
			if(nome&&telefone){
				if(!(/\(\d{2}\) \d{4,5}-\d{4}/g).test(telefone)){
					inputTelefone.current.setCustomValidity(`Informe um telefone válido!`)
					inputTelefone.current.reportValidity()
					inputTelefone.current.focus()
					return false
				}
				return true
			}
			else{
				if(!nome) {
					inputNome.current.reportValidity()
					inputNome.current.focus()
					return false
				}
				if(!telefone){
					inputTelefone.current.reportValidity()
					inputTelefone.current.focus()
					return false
				}
			}
		}
	}

	function openModalListUsers(event){
		event.preventDefault()

		api.get('user/soft').then(response=>{
			if(response.data.length>1){
				const modal = document.querySelector("div#modalListUsers")
				modal.classList.add('active')
			}
			else{
				sendOrder()
			}
		})
	}

	useEffect(()=>{
		api.get(`/cities`).then(({data: cities})=>{
			setCityList(cities)
		})
		api.get('cityRegion').then(({data: regions})=>{
			setCityRegionList(regions)
		})

		async function itemSetting(){
			Promise.all(
				JSON.parse(localStorage.getItem('itens'))?.map(async i=>{
					try{
						const item = await api.get(`/products/show/${i.id}`)
						return {
							...(item.data),
							...(i)
						}
					}
					catch(error){
						return {
							...(i)
						}
					}
				})||[]
			).then(data=>{
				setItens(data)
			})
		}

		itemSetting()

		api.get(`paymentmethod/search?status=1`).then(response=>{
			setPaymentMethods(response.data)
		})
	}, [])

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function closeModalCart(){
		const modal = document.querySelector("div#modalCart")
		modal.classList.remove('active')
	}

	function deleteItem(i){
		if(itens){
			if(itens.length>1){
				var itemsUpdated = itens.filter((c,index)=>index!==i)
				setItens(itemsUpdated)
				var total = itemsUpdated.map(item=>{
					return calculateTotalWithCompositions(item)
				}).reduce((a, b)=>{
					return a+b
				}, 0)
				setToTalBuyed(total)
				localStorage.setItem('itens', JSON.stringify(itemsUpdated))
			}
			else{
				setItens()
				setToTalBuyed(0)
				setFrete(0)
				localStorage.removeItem('itens')
			}
		}
	}

	function clearValues(){
		setEndereco('')
		setBairro('')
		setCep('')
		setCidade('')
	}

	function calculateTotalWithCompositions(itemCart){
		if(parseInt(itemCart.composite_product)===1){
			switch (itemCart.composition_type) {
				case 0:
					return itemCart.qtd*itemCart.price+(itemCart.qtd*(itemCart.compositions?.map(c=>{
						return c.total
					}).reduce((a,b)=>a+b, 0))||0)||0
				case 1:
					return itemCart.qtd*itemCart.price+(itemCart.compositions?.filter(c=>c.qtd>0)?.length?(itemCart.qtd*Math.max(...(itemCart.compositions?.map(c=>{
						return c.composition_item.value
					})))):0)||0
				case 2:
					return itemCart.qtd*itemCart.price+(itemCart.qtd*((itemCart.compositions?.filter(c=>c.qtd>0).length>0)?(itemCart.compositions?.map(c=>{
						return c.total
					})?.reduce((a,b)=>a+b, 0))/itemCart.compositions?.filter(c=>c.qtd>0)?.map(c=>{
						return c.qtd
					})?.reduce((a,b)=>a+b, 0):0))||0

				default:
					return itemCart.qtd*itemCart.price
			}
		}
		else{
			return itemCart.qtd*itemCart.price
		}
	}

	function handleDecreaseItemOnCart(button){
		const index = button.closest('.containerQtd').dataset.id
		var itens = JSON.parse(localStorage.getItem('itens'))
		if(itens){
			if(itens[index]){
				if(itens[index]['qtd']>1){
					itens[index]['qtd']=itens[index]['qtd']-1
					itens[index]['total']=calculateTotalWithCompositions(itens[index])
					localStorage.setItem('itens', JSON.stringify(itens) )
					setItens(itens=>{
						return itens.map((item, i)=>{
							if(parseInt(i)===parseInt(index)&&item['qtd']>1){
								item['qtd']=item['qtd']-1
								item['total']=calculateTotalWithCompositions(item)
							}
							return item
						})
					})
				}
			}
		}
	}

	function handleIncreaseItemOnCart(button){
		const index = button.closest('.containerQtd').dataset.id
		var itens = JSON.parse(localStorage.getItem('itens'))
		if(itens){
			if(itens[index]){
				itens[index]['qtd']=itens[index]['qtd']+1
				itens[index]['total']=calculateTotalWithCompositions(itens[index])
				localStorage.setItem('itens', JSON.stringify(itens))
				setItens(itens=>{
					return itens.map((item, i)=>{
						if(parseInt(i)===parseInt(index)){
							item['qtd']=item['qtd']+1
							item['total']=calculateTotalWithCompositions(item)
						}
						return item
					})
				})
			}
		}
	}

	function ListUsers(){
		const [users, setUsers] = useState([])

		useEffect(()=>{
			api.get('user/soft').then(response=>{
				setUsers(response.data)
			})
		}, [])

		async function selectUser(event){
			const id = event.target.closest('div.userContainer').dataset.id
			closeModal(event)

			setSeller(id)

			sendOrder(id)
		}

		return (
			<>
				<div className="headerModal">Selecione o vendedor</div>
				<div className="containerUsersPad">
					<div className="usersContainer">
					{users.map(user=>{
						return <>
							<div className="userContainer" key={user.id} data-id={user.id} onClick={selectUser}>
								<div className="imageUser">
									<img src={`./assets/users/${user.image}`} alt=""/>
								</div>
								<div className="usernameTitle">{user.nome}</div>
							</div>
						</>
					})}
					</div>
				</div>
			</>
		)
	}

	return (
		<>
		{loading?<div className="loaderProduct">
				<div className="loader"></div>
			</div>:<></>}
		<div className="headerCategory" id="cartTitle">
			<h1>{empresa.print_title?
				<>{empresa.print_title.charAt(0).toUpperCase() + empresa.print_title.slice(1)}</>:
				<></>
			}</h1>
		</div>
		<div className="containerDiv">
		<div className="cartContainer">
			{
				(itens?.length>0) ?
				<>
					<form action="" id="formCart" onSubmit={openModalListUsers}>
					<Tabs selectedIndex={selectedTab} onSelect={index=>setSelectedTab(index)} id="CartTabs">
						<TabList>
							<Tab value='Cart'><FiShoppingCart size={26}/> <span>Carrinho</span></Tab>
							<Tab value="Info"><FiUser size={26}/> <span>Cliente</span></Tab>
							<Tab value="Payment"><FaMoneyBill id="moneyBill" size={26}/> <span>Pagamento</span></Tab>
						</TabList>
						<TabPanel value="Cart" index={0}>
							<div className="CartTabPanel">
								<ul className="items">
									{itens.map((item, index)=>{
										return <li key={`${item.id}${index}`} className="cartCard">
											<div className="upperSection">
												<img src={item.image?`./assets/${item.image}`:'./assets/default_product.png'} alt=""/>
												{item.name}
											</div>
											{item.compositions?<>
												{item.compositions?.length>0?
													<div className="compositions">
														{
															item.compositions.map(c=>{
																return <div className="composition">
																	<span className="compositionName">{c.composition_item.name}</span>
																	<span>{c.qtd}</span>
																	<span className="totalComposition">{item.composition_type===0?Intl.NumberFormat('pt-br', {style: 'currency',currency:'BRL'}).format(c.total||0):Intl.NumberFormat('pt-br', {style: 'currency',currency:'BRL'}).format(c.composition_item.value||0)}</span>
																</div>
															})
														}
													</div>
												:<></>}
											</>:<></>}
											<div className="prices">
												<span className="value">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(calculateTotalWithCompositions(item))}</span>
												<div className="containerQtd" data-id={index}>
													<button onClick={(event)=>{
														event.preventDefault()
														handleDecreaseItemOnCart(event.target)
														}}><FiMinusCircle size={22} color="red"/></button>
													<span>
													{
														(item.enable_selling_decimal===1&&item.editing_quantity)?
														<input type="text" value={
															Intl
															.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
															.format(item.qtd)
														} onChange={event=>{
															var itens = JSON.parse(localStorage.getItem('itens'))
															if(itens){
																if(itens[index]){
																	itens[index]['qtd']=itens[index]['qtd']+1
																	itens[index]['total']=calculateTotalWithCompositions(itens[index])
																	localStorage.setItem('itens', JSON.stringify(itens))
																	setItens(itens=>{
																		return itens.map((item, i)=>{
																			if(parseInt(i)===parseInt(index)){
																				item['qtd']=parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1'))
																				item['total']=calculateTotalWithCompositions(item)
																			}
																			return item
																		})
																	})
																}
															}
														}}
														onBlur={()=>{
															var itens = JSON.parse(localStorage.getItem('itens'))
															if(itens){
																setItens(itens=>{
																	return itens.map((item, i)=>{
																		delete itens[i].editing_quantity
																		localStorage.setItem('itens', JSON.stringify(itens))
																		delete item.editing_quantity
																		return item
																	})
																})
															}
														}}
														/>
														:<span onDoubleClick={()=>{
															var itens = JSON.parse(localStorage.getItem('itens'))
															if(itens){
																setItens(itens=>{
																	return itens.map((item, i)=>{
																		if(parseInt(i)===parseInt(index)&&itens[i].enable_selling_decimal===1){
																			itens[i].editing_quantity=true
																			localStorage.setItem('itens', JSON.stringify(itens))
																			item.editing_quantity=true
																		}
																		else{
																			delete itens[i].editing_quantity
																			localStorage.setItem('itens', JSON.stringify(itens))
																			delete item.editing_quantity
																		}
																		return item
																	})
																})
															}
														}}>{
															Intl
															.NumberFormat('pt-br')
															.format(item.qtd)
														}</span>
													}
													</span>
													<button onClick={(event)=>{
														event.preventDefault()
														handleIncreaseItemOnCart(event.target)}

														}><FiPlusCircle size={22} color="green"/></button>
												</div>
											</div>
											<button className="delete" onClick={(event)=>{
													event.preventDefault()
													deleteItem(index)
												}}>
												<FiX size={20} color="#888"/>
											</button>
										</li>
									})}
								</ul>
							</div>
						</TabPanel>
						<TabPanel value="Info" index={1}>
							<div className="CartTabPanel">
								<Tabs selectedIndex={entregaRetirada} onSelect={index=>{
										setEntregaRetirada(index)
										if(index===0){
											if(customerLogged){
												setBairro(customerLogged.cityRegionId)
												setCidade(customerLogged.cidadeId)
												setCep(customerLogged.cep.replace(/(\d{5})(\d{3})/g, '$1-$2'))
												setEndereco(customerLogged.address)
											}
										}
										else{
											clearValues()
										}
										}
									} id="entregaRetiradaTabs">
									<TabList className="tabsRetirada">
										<div className="containerOptionsRetirada">
											<Tab value={0} id={entregaRetirada===0?'selected':''}>Entrega</Tab>
											<Tab value={1} id={entregaRetirada===1?'selected':''}>Retirada</Tab>
										</div>
									</TabList>
								<div className="input-group">
									<input id="clientName" type="text" ref={inputNome} value={nome} onChange={event=>setNome(event.target.value)} required/>
									<label htmlFor="clientName">Nome</label>
								</div>
								<div className="emailLine">
									<div className="input-group">
										<input id="email" value={email} onChange={event=>setEmail(event.target.value)} type="text"/>
										<label htmlFor="email">Email</label>
									</div>
									<div className="input-group">
										<input id="phone" type="text" ref={inputTelefone} value={telefone} onChange={event=>setTelefone(event.target.value.replace(/\D/g, '').replace(/^(\d{2})(\d{1,4})$/g, '($1) $2').replace(/^(\d{2})(\d{4})(\d{1,4})$/g, '($1) $2-$3').replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3'))} maxLength={15} required/>
										<label htmlFor="phone">Telefone</label>
									</div>
								</div>
									<TabPanel>
									<div className="cepLine">
										<div className="input-group">
											<input id="cep" type="text" ref={inputCep} value={cep} required onChange={event=>setCep(event.target.value.replace(/\D/g, '').replace(/^(\d{5})(\d{1,3})$/g, '$1-$2'))} maxLength={9} onBlur={event=>{
												axios.get(`https://brasilapi.com.br/api/cep/v1/${event.target.value.replace(/\D/g, '')}`)
												.then(({data: cepData})=>{
													const selectedCity = cityList.find(c=>c.nome.indexOf(cepData.city)>-1)
													if(selectedCity){
														setCidade(selectedCity.id)
													}
													const selectedCityRegion = cityRegionList.find(cr=>cr.name.indexOf(cepData.neighborhood)>-1)
													if(selectedCityRegion){
														setBairro(selectedCityRegion.id)
													}
													setEndereco(cepData.street)
												})
												.catch(error=>{})
											}}/>
											<label htmlFor="cep">CEP</label>
										</div>
										<div className="input-group">
											<select value={cidade} ref={inputCidade} name="city" id="city" onChange={event=>{
												setCidade(event.target.value)
												setBairro('')
												}} required>
												<option value="">Selecione a cidade...</option>
												{cityList.map(c=>{
													return <option key={c.id} value={c.id}>{c.nome}</option>
												})}
											</select>
											<label htmlFor="city">Cidade</label>
										</div>
									</div>
									<div className="input-group">
										<select name="bairro" ref={inputBairro} value={bairro} onChange={event=>{
												setBairro(event.target.value)
												const selectedCityRegion = cityRegionList.find(cr=>parseInt(cr.id)===parseInt(event.target.value))
												if(selectedCityRegion){
													setCidade(selectedCityRegion.cidadeId)
												}
											}} id="bairro" required>
											<option value="">Selecione um bairro...</option>
											{cityRegionList.map(cr=>{
												return <option key={cr.id} value={cr.id}>{cr.name}</option>
											})}
										</select>
										<label htmlFor="bairro">Bairro</label>
									</div>
									<div className="input-group">
										<input type="text" ref={inputAddress} required value={endereco} onChange={event=>setEndereco(event.target.value)}/>
										<label htmlFor="">Endereço</label>
									</div>
									</TabPanel>
									<TabPanel></TabPanel>
								</Tabs>
							</div>
						</TabPanel>
						<TabPanel value="Payment" index={2}>
							<div className="CartTabPanel">
								<div className="input-group">
									<select name={paymentMethod} ref={inputPaymentMthd} required onChange={event=>setPaymentMethod(event.target.value)} id="">
										<option value="">Selecione a forma de pagamento...</option>
										{
											paymentMethods.map(pm=>{
												return <option key={pm.id} value={pm.nome}>{pm.nome}</option>
											})
										}
									</select>
									<label htmlFor="">Pagamento</label>
								</div>
							</div>
						</TabPanel>
						<div className="buttonsCartTabs">
							<div className="obsContainer">
								<div className="input-group obsSet">
									<textarea placeholder="Observação" rows="5" name="obsModal" id="obsModal" value={obs} onChange={event=>setObs(event.target.value)}></textarea>
									<label htmlFor="obsModal">Observação</label>
								</div>
							</div>
							<div className="total">
								{(frete>0)?<>Total dos itens: <span>{Intl.NumberFormat('pt-br',{style: 'currency', currency: 'BRL'}).format(props.total-frete)}</span></>:<></>}
								{(frete>0)?<>Frete: <span>{Intl.NumberFormat('pt-br',{style: 'currency', currency: 'BRL'}).format(frete)}</span></>:<></>}
								Total: <span>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(props.total)}</span>
							</div>
							<div className="btns">
								<p></p>
								<button onClick={(event)=>{
									event.preventDefault()
									if(selectedTab<2){
										if(selectedTab!==1||validateFields()){
											setSelectedTab(selectedTab+1)
										}
									}
									else{
										if(!paymentMethod){
											inputPaymentMthd.current.reportValidity()
											inputPaymentMthd.current.focus()
											return false
										}
										if(verifyIsOpen()){
											setLoading(true)
											openModalListUsers(event)
										}
										else{
											alert(`Expediente encerrado: Não é possível encerrar pedido!`)
										}
									}
									}}>Fechar Carrinho</button>
							</div>
						</div>
					</Tabs>
					</form>

				</> :
						<div className="Empty">
							<FiShoppingCart size={80}/>
							Carrinho Vazio
							<button onClick={closeModalCart} className="backToStoreBtn">Retornar para loja</button>
						</div>
			}
		<div className="modalContent" id="modalListUsers">
			<div className="modal">
			<ListUsers/>
			<button className="close" onClick={closeModal}>
				<FiX size="30" color="#ccc"/>
			</button>
			</div>
		</div>
		</div>
		</div>
		<div className={`modalContent ${hasAlert?'active':''}`}>
			<div className="modalAlert">
				<span>
					{alertMessage}
				</span>
				<button className="closeBtn"
				onClick={()=>{
					setHasAlert(false)
				}}
				><FiX/></button>
			</div>
		</div>
		</>
	)
}

export default CartModal
