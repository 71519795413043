import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../services/api'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import {FaBars} from 'react-icons/fa'
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import AddNewOpeningHour from './AddNewOpeningHour'
import printList from '../../generalFunctions/printTableAdm'
const moment = require('moment')

function AdminHorarioFunc(){
	const [listOpeningHours, setListOpeningHours] = useState([])
	const [day, setDay] = useState()
	const [editedOpeningHour, setEditedOpeningHour] = useState()

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		api.get(`/openingDays/${(day?`?day=${day}`:'')}`).then(response=>{
			setListOpeningHours(response.data)
		})
	}, [day])

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	function addNew(){
		const modal = document.querySelector("div#modalEditOpeningHour")
		modal.classList.add('active')
		setEditedOpeningHour()
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		if(table){
			var trItens = table.querySelectorAll('tbody tr')

			trItens = Array.from(trItens)

			trItens.forEach((item)=>{
				if(item.querySelector('input').checked){
					item.classList.add('selected')
				}
				else{
					item.classList.remove('selected')
				}
			})
		}
	}

	function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		if(window.confirm(`Deletar item ${id}?`)){
			try {
				api.delete(`/openingDays/${id}`, {
					headers: {
						auth
					}
				}).then(()=>{
					setListOpeningHours(listOpeningHours.filter(loh=>parseInt(loh.id)!==parseInt(id)))
				})
			} catch (error) {

			}
		}
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			const item = listOpeningHours.find(loh=>parseInt(loh.id)===parseInt(id))
			setEditedOpeningHour(item)
			const modal = document.querySelector("div#modalEditOpeningHour")
			modal.classList.add('active')
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	return (
	<>
	<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
			<div className="body admBody" style={{marginTop: "50px"}}>
				<div className="headerAdmPage">
					<div className="header">
						<div className="utilityBar">
							<div className="search">
								<h1>Horários de Funcionamento</h1>
								<select name="dayOfWeek" className="day_of_wek_searcher" value={day} onChange={event=>setDay(event.target.value)} id="dayOfWeek">
									<option value="">Selecione um dia da semana...</option>
									<option value={0}>Domingo</option>
									<option value={1}>Segunda</option>
									<option value={2}>Terça</option>
									<option value={3}>Quarta</option>
									<option value={4}>Quinta</option>
									<option value={5}>Sexta</option>
									<option value={6}>Sábado</option>
									<option value={7}>Feriado</option>
								</select>
								<button className="search">
									<FiSearch size={28} color="#4287f5"/>
								</button>
							</div>
							<div className="buttons">
								<button onClick={addNew} className="utilityBtns">Novo</button>
								<button className="utilityBtns" onClick={editItem}>Editar</button>
								<button className="utilityBtns" onClick={()=>{
									printList('Horários de funcionamento', [
										{title: 'Dia da semana', dataKey: 'week_day_name'},
										{title: 'Hora início', dataKey: 'time_start'},
										{title: 'Hora fim', dataKey: 'time_end'}
									], listOpeningHours.map(l=>{
										switch (parseInt(l.week_day)) {
											case 0:
												l.week_day_name="Domingo"
												break;
											case 1:
												l.week_day_name="Segunda"
												break;
											case 2:
												l.week_day_name="Terça"
												break;
											case 3:
												l.week_day_name="Quarta"
												break;
											case 4:
												l.week_day_name="Quinta"
												break;
											case 5:
												l.week_day_name="Sexta"
												break;
											case 6:
												l.week_day_name="Sábado"
												break;
											default:
												l.week_day_name="Feriado"
												break;
										}
										if(l.is_hollyday===1){
											l.week_day_name="Feriado"
										}

										l.time_start = moment(l.time_start, 'HH:mm:ss').format('HH:mm')
										l.time_end = moment(l.time_end, 'HH:mm:ss').format('HH:mm')

										return l
									}))
								}}>Imprimir</button>
							</div>
						</div>
					</div>
				</div>
				<div className="tableContainer">
					<table className="admItens">
						<thead>
							<tr>
								<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
								<th>Dia da semana</th>
								<th>Hora início</th>
								<th>Hora fim</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{listOpeningHours.map(l=>{

								switch (l.week_day) {
									case 0:
										l.week_day_name="Domingo"
										break;
									case 1:
										l.week_day_name="Segunda"
										break;
									case 2:
										l.week_day_name="Terça"
										break;
									case 3:
										l.week_day_name="Quarta"
										break;
									case 4:
										l.week_day_name="Quinta"
										break;
									case 5:
										l.week_day_name="Sexta"
										break;
									case 6:
										l.week_day_name="Sábado"
										break;
									default:
										l.week_day_name="Feriado"
										break;
								}

								if(l.is_hollyday===1){
									l.week_day_name="Feriado"
								}

								return <tr key={l.id} onClick={checkLine} onDoubleClick={editItem}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={l.id}/></td>
									<td>{l.week_day_name}</td>
									<td>{moment(l.time_start, 'HH:mm:ss').format('HH:mm')}</td>
									<td>{moment(l.time_end, 'HH:mm:ss').format('HH:mm')}</td>
									<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
								</tr>
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
		</div>
		<div className="modalContent" id="modalEditOpeningHour">
			<div className="modal">
				<AddNewOpeningHour  saveCallback={setListOpeningHours} editedOH={editedOpeningHour} listOpeningHours={listOpeningHours}/>
				<button className="close" id="btnCloseModalEditOpeningHour">
					<FiX size="30" color="#ccc" onClick={closeModal}/>
				</button>
			</div>
		</div>
	</>
	)
}

export default AdminHorarioFunc
