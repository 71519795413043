import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../services/api'
import printBlobPedido from '../AdmListPedidos/printBlobPedido'
import {FiPrinter} from 'react-icons/fi'
const moment = require('moment')

function EditOrderForm(props){
	const order = props.order
	const empresa = props.empresa
	const auth = localStorage.getItem('auth')

	const [status, setStatus] = useState(order.order_status_id)
	const [entregadores, setEntregadores] = useState([])
	const [deliveryMan, setDeliveryMan] = useState(order.entregador?order.order_status_id:'')
	const [shipping_city, setShippingCity] = useState(order.shipping_city?order.shipping_city:'')
	const [shipping_city_region, setShippingCityRegion] = useState(order.shipping_city_region?order.shipping_city_region:'')
	const [cities, setCities] = useState([])
	const [city_regions, setCityRegions] = useState([])
	const [salesMans, setSalesMans] = useState([])
	const [paymentMethods, setPaymentMethods] = useState([])
	const [vendedor, setVendedor] = useState(order.vendedor?order.vendedor:0)
	const [items, setItems] = useState(order.items?order.items:[])
	const [frete, setFrete] = useState(()=>{
	return order.totals?order.totals.filter(order=>{
				return order.title==="Frete"
			}).map(order=>{
				return order.value
			}).join():0
		}
	)


	var total = 0
	useEffect(()=>{
		setStatus(order.order_status_id)
		setDeliveryMan(order.entregador)
		setVendedor(order.vendedor)
		setItems(order.items)
		setShippingCity(order.shipping_city)
		setFrete(order.totals.filter(order=>{
			return order.title==="Frete"
		}).map(order=>{
			return order.value
		}).join())
		api.get(`/deliveryman/`, {
			headers: {
				auth
			}
		}).then(response=>{
			setEntregadores(response.data)
		})

		api.get(`/cities/`).then(response=>{
			setCities(response.data)
		})

		api.get(`/cityRegion/`).then(response=>{
			setCityRegions(response.data)
			const selectedRegion = response.data.find(cr=>cr.name.indexOf(order.shipping_address_2)>-1)
			if(selectedRegion){
				setShippingCityRegion(selectedRegion.id)
			}
		})

		api.get('/user/soft').then(response=>{
			setSalesMans(response.data)
		})

		api.get('/paymentmethod/search/').then(({data})=>{
			setPaymentMethods(data)
		})
	}, [order, auth])

	useEffect(()=>{
		if(shipping_city){
			if(shipping_city_region){
				const cityRegionSelected = city_regions.find(c=>parseInt(c.id)===parseInt(shipping_city_region))

				if(cityRegionSelected){
					setFrete(cityRegionSelected.shipping_value)
				}
				else{
					const citySelected = cities.find(c=>parseInt(c.id)===parseInt(shipping_city))
					if(citySelected){
						setFrete(citySelected.frete)
					}
					else{
						setFrete(0)
					}
				}
			}
			else{
				const citySelected = cities.find(c=>parseInt(c.id)===parseInt(shipping_city))
				if(citySelected){
					setFrete(citySelected.frete)
				}
				else{
					setFrete(0)
				}
			}
		}
	}, [shipping_city, shipping_city_region, cities, city_regions])

	function closeModalSearch(){
		const modal = document.querySelector("div#modalSearch")
		modal.classList.remove('active')
	}

	function handleSave(event){
		event.preventDefault()

		const cityRegionSelected = city_regions.find(c=>parseInt(c.id)===parseInt(shipping_city_region))

		api.post(`order/changeStatus/${order.id}`, {
			status,
			entregador: deliveryMan,
			vendedor,
			shipping_city,
			shipping_address_2: cityRegionSelected?cityRegionSelected.name:'',
			frete,
			items
		}, {
			headers:{
				'auth': auth
			}
		}).then(()=>{
			alert(`Pedido ${order.id} salvo com sucesso!`)
			window.location.reload()
		}).catch(error=>{
			alert(`Erro ao salvar pedido: ${error.response.data.message}`)
		})
	}

	return (<>
		<div className="headerModal">
			Pedido
		</div>
		<form action="" onSubmit={handleSave} className="formScrollable">
		<div className="bodyPedido">
			<div className="input-group">
				<input type="text" value={order.id||''} disabled/>
				<label htmlFor="">Cód. Pedido</label>
			</div>
			<div className="input-group nameSpaced">
				<input type="text" value={`${order.firstname} ${order.lastname}`} disabled/>
				<label htmlFor="">Nome</label>
			</div>
			<div className="input-group">
				<select name="status" id="status" value={status||''} onChange={event=>setStatus(event.target.value)}>
					<option value={0}>Aberto</option>
					<option value={1}>Cancelado</option>
					<option value={2}>Em produção</option>
					<option value={3}>Em entrega</option>
					<option value={4}>Finalizado</option>
				</select>
				<label htmlFor="status">Status</label>
			</div>
			<div className="input-group">
				<button onClick={event=>{
					event.preventDefault()
					api.get(`/order/show/${order.id}`).then(({data: orderCplt})=>{
						printBlobPedido(orderCplt, empresa)
					})
				}} title="Impressão entregador" className="btnsPrints"><FiPrinter size={18} color="#4287f5"/> Impressão entreg.</button>
			</div>
			<div className="input-group">
				<button onClick={event=>{
					event.preventDefault()
					window.open(`/pedido/${order.encryptedId}`)
				}} title="Imprimir Pedido" className="btnsPrints"><FiPrinter size={18}/> Imprimir Pedido</button>
			</div>
			<div className="input-group">
				<input type="text" value={Intl.NumberFormat('pt-BR', {style:'currency', currency:'BRL'}).format(order.total)} disabled/>
				<label htmlFor="status">Total</label>
			</div>
			<div className="input-group">
				<input type="date" value={moment(order.createdAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')} disabled/>
				<label htmlFor="status">Data</label>
			</div>
			<div className="input-group">
				<select name="entregador" id="entregador" value={deliveryMan||''} onChange={event=>setDeliveryMan(event.target.value)}>
					<option value="">Selecione um entregador...</option>
					{entregadores.map(entregador=>{
						return <option key={entregador.id} value={entregador.id}>{entregador.nome}</option>
					})}
				</select>
				<label htmlFor="entregador">Entregador</label>
			</div>
			<div className="input-group">
				<input type="text" id="phone" disabled value={order.telephone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}/>
				<label htmlFor="phone">Telefone</label>
			</div>
			<div className="input-group">
				<select name="cidade" id="cidade" value={shipping_city||''} onChange={event=>{
					setShippingCity(event.target.value)
				}}>
					<option value="">Selecione uma cidade</option>
					{cities.map(city=>{
						return <option key={city.id} value={city.id}>{city.nome}</option>
					})}
				</select>
				<label htmlFor="cidade">Cidade</label>
			</div>
			<div className="input-group">
				<select name="cityRegion" id="cityRegion" value={shipping_city_region||''} onChange={event=>setShippingCityRegion(event.target.value)}>
					<option value="">Selecione um bairro</option>
					{city_regions.map(cr=>{
						return <option key={cr.id+cr.name} value={cr.id}>{cr.name}</option>
					})}
				</select>
				<label htmlFor="cityRegion">Bairro</label>
			</div>
			<div className="input-group">
				<select name="vendedor" id="vendedor" value={vendedor||0} onChange={event=>setVendedor(event.target.value)}>
					{salesMans.map(slMan=>{
						return <option key={slMan.id} value={slMan.id}>{slMan.nome}</option>
					})}
				</select>
				<label htmlFor="vendedor">Vendedor</label>
			</div>
			<div className="input-group">
				<select disabled name="paymentMethod" id="paymentMethod" value={order.payment_code||''}>
					{paymentMethods.map(pm=>{
						return <option key={pm.id} value={pm.id}>{pm.nome}</option>
					})}
				</select>
				<label htmlFor="paymentMethod">Forma de pagamento</label>
			</div>
			<div className="input-group">
				<select name="entregaRetirada" id="entregaRetirada" disabled value={order.is_delivery||''}>
					<option value={1}>Entrega</option>
					<option value={0}>Retirada</option>
				</select>
				<label htmlFor="entregaRetirada">Tipo de pedido:</label>
			</div>
			<div className="spaced">
				<table className='admItens'>
					<thead>
						<tr>
							<th>Item</th>
							<th>Quantidade</th>
							<th>Preço Unit.</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{items.map(item=>{
							var totalItem = parseFloat(item.total)
							total+=totalItem
							return (
								<>
								<tr key={item.name+item.id}>
									<td>{item.name}</td>
									<td className="qtdInput"
									onDoubleClick={()=>{
										setItems(items=>items.map(i=>{
											if(i.id===item.id){
												i.editing=true
											}
											else{
												delete i.editing
											}

											return i
										}))
									}}
									onBlur={()=>{
										setItems(items=>items.map(i=>{
											delete i.editing

											return i
										}))
									}}
									><input type="text" disabled={!item.editing}
									value={Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.delivered_quantity)} onChange={event=>{
										setItems(items=>items.map(i=>{
											if(i.id===item.id){
												i.delivered_quantity=parseFloat((event.target?.value||i.delivered_quantity).toString().replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1'))

												i.total=i.delivered_quantity*i.price
											}
											return i
										}))
									}}/>
									</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.price)}</td>
									<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.total)}</td>
								</tr>
								{item.composite_product===1?<>
									{item.compositions.length>0?
									item.compositions.map((c,index)=>{
										c.newTotal = 0

										switch (item.composition_type) {
											case 0:
												c.newTotal=c.total
												break
											case 1:
												const maxValue = Math.max(...item.compositions.map(comp=>parseFloat(comp.value)))
												if(parseFloat(c.value)===maxValue){
													c.newTotal=maxValue
												}
												break
											case 2:
												const totalCalculated=c.total/(item.compositions.map(comp=>parseFloat(comp.quantity)).reduce((a,b)=>a+b,0))
												if(index<(item.compositions?.length-1)){
													c.newTotal=totalCalculated
												}
												else{
													c.newTotal=totalItem
												}
												totalItem-=Math.round(totalCalculated*100)/100
												break

											default:
												break;
										}
										return <tr>
											<td style={{paddingLeft: '35px'}} colSpan={4}>
												<div className="comp_itens">
													<span className="left">
														{c.name}
													</span>
													<span className="right">{Intl.NumberFormat('pt-br', {minimumFractionDigits:2, maximumFractionDigits:2}).format(c.quantity)}</span>
													<span className="right">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(c.value)}</span>
													<span className="right">{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(c.newTotal)}</span>
												</div>
											</td>
										</tr>
									}):<></>}
								</>:<></>}
								</>
							)
						})}
						<tr>
							<td colSpan={3}>Frete</td>
							<td colSpan={3}>
								{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(frete)}
							</td>
						</tr>
						<tr>
							<td colSpan={3}>Total</td>
							<td>
								{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(total)+parseFloat(frete))}
							</td>
						</tr>
					</tbody>
				</table>
				{order.obs?<><span style={{display: 'flex', marginTop: 10}}>Obs:</span><div className="Obs">{order.obs}</div></>:<></>}
			</div>
		</div>
		<div className="footerAddForm">
			<button type="button" className="cancel" onClick={closeModalSearch}>Cancelar</button>
			<button type="submit" className="save">Salvar</button>
		</div>
		</form>
	</>)
}

export default EditOrderForm
