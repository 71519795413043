import React, {useState, useEffect, useRef} from 'react'
import { ReCaptcha } from 'react-recaptcha-google'
import {useHistory} from 'react-router-dom'
import {FiX} from 'react-icons/fi'
import './style.css';
import api from '../../services/api'

function Admin_login(){
	const captchaValidation = useRef({})

	const [empresa, setEmpresa] = useState({})
	const [login, setLogin] = useState('')    
	const [password, setPassword] = useState('')
	const [errorModal, setErrorModal] = useState('')
	const [validToken, setValidToken] = useState(false)
	const [token, setToken] = useState('')
	const [captchaComp, setCaptchaComp] = useState(false)
	const history = useHistory()

	useEffect(()=>{
		api.get('company').then(response=>{
			setEmpresa(response.data)
			document.title=response.data.fantasia
			setCaptchaComp(<ReCaptcha
				ref={captchaValidation}
				verifyCallback={verifyCallback}
				render="explicit"
				hl={"pt-br"} 
				sitekey={response.data.google_captcha_token}
			/>)
		})
	}, [])

	document.title="Clarion Pedido - Login"

	function verifyCallback(token){
		if(token){
			setValidToken(true)
			setToken(token)
			return token
		}
		return false
	}
	
	async function handleLogin(event){
		try{
			event.preventDefault()

			const response = await api.post('user/login',{
				login,
				password,
				...(token?{captchaToken:token}:{})
			})

			localStorage.setItem('auth', response.data.token)
			history.push('/admin/dashboard')
		}
		catch(error){
			const modal = document.querySelector("div#modalLogin")
			setErrorModal(()=>{
				return (<div className="errorMsg">Erro ao fazer login: {error.response?error.response.data.message:`${error}`}</div>)
			})
			modal.classList.add('active')
		}
	}

	function closeModal(){
		const modal = document.querySelector("div#modalLogin")
		modal.classList.remove('active')
	}

	return (
		<>
		<form onSubmit={handleLogin}>
		<div className="containerPgLogin">
				<div className="formLogin">
					<div className="logoContainer">
						<div className="logoImg">
							<img src={`${window.location.origin}/${empresa.logo}`} alt=""/>
						</div>
					</div>
					<label htmlFor="login">Login</label>
					<input required type="text" id="login" value={login} onChange={event=>setLogin(event.target.value)} placeholder="Login"/>
					<label htmlFor="senha">Senha</label>
					<input required type="password" id="senha" value={password} onChange={event=>setPassword(event.target.value)} placeholder="senha"/>
					<div className="reCaptchaContainer">
						{captchaComp}
					</div>
					<button className="login" disabled={!validToken}>Login</button>
				</div>
		</div>
		</form>
		<div className="modalContent" id="modalLogin">
			<div className="modal">{errorModal}
			  <button className="close" onClick={closeModal}>
				<FiX size="30" color="#ccc" />
			  </button>
			</div>
		</div>
		</>
	)
}

export default Admin_login