import {useEffect, useState} from 'react'
import api from '../../services/api'
export default function useProductList(page, category){
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [products, setProducts] = useState([])
    const [hasMore, setHasMore] = useState(false)

    useEffect(()=>{
        setProducts([])
    }, [category])


    useEffect(()=>{
        setLoading(true)
        setError(false)
        api.get(`products/storeList/${page}${category?'/?category='+category:''}`).then(response=>{
            setProducts(prevProducts=>{
                return [...new Set([...prevProducts, ...response.data])]
            })

            setHasMore(response.data.length>0)
            setLoading(false)
        }).catch(error=>{
            setError(error)
        })
    }, [page, category])
    return {loading,error,products,hasMore}
}