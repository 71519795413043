import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../../services/api'
import moment from 'moment'

function EditHollyday({item}){

	const [loading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [date, setDate] = useState('')

	useEffect(()=>{
		if(item){
			setName(item.name)
			setDate(item.date)
		}
		else{
			setName('')
			setDate('')
		}
	}, [item])

	const auth = localStorage.getItem('auth')

	function handleSave(event){
		setLoading(true)
		event.preventDefault()

		api.post('/hollyday/', {
			...(item?{id: item.id}:{}),
			name,
			date
		}, {
			headers: {
				auth
			}
		})
		.then(()=>{
			window.location.reload(true)
		})
		.catch(()=>{
			alert(`Erro ao cadastrar feriado!`)
			setLoading(false)
		})
	}

	return <>
		{loading?<div className="loaderModal">
			<div className="spinner"></div>
		</div>:<></>}
		<div className="headerCategory">
			<h1>Cadastro de feriado</h1>
		</div>
		<form action="" className="inputOpening" onSubmit={handleSave}>
			<div className="hollydayLine">
				<div className="input-group">
					<input type="text" value={name} required onChange={event=>setName(event.target.value)}/>
					<label htmlFor="">Nome</label>
				</div>
				<div className="input-group">
					<input type="text" value={date} required onChange={event=>{
						event.target.setCustomValidity('')
						setDate(event.target.value.replace(/\D/g, '').replace(/(\d{2})(\d{1,2})/g, '$1/$2'))
					}} maxLength={5} onBlur={event=>{
						const input = event.target
						if(!moment(`${input.value}/${(new Date()).getFullYear()}`, 'DD/MM/YYYY').isValid()){
							input.setCustomValidity('Insira uma data válida!')
							input.reportValidity()
						}
					}}/>
					<label htmlFor="">Data</label>
				</div>
			</div>
			<div className="footerAddForm">
				<div></div>
				<button type="submit" className="save">Salvar</button>
			</div>
		</form>
	</>
}

export default EditHollyday
