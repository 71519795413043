import React, {useState, useEffect, useRef, useCallback} from 'react';
import './style.css';
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import api from '../../services/api'
import useProductSearch from './useProductSearch'
import AdmFormAddProduct from './AdmFormAddProduct'
import printList from '../../generalFunctions/printTableAdm'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
const {orderByProp} = require('../../generalFunctions/orderByProp')

function ListProd(){

	const [modalProduct, setModalProduct] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [category, setCategory] = useState('')
	const [page, setPage] = useState(1)
	const [ordering, setOrdering] = useState('')

	const {
		loading,
		error,
		products,
		setProducts,
		hasMore
	} = useProductSearch(searchTerm, category, page)

	const observer = useRef()

	const lastProductShowed = useCallback(node=>{
		if(loading) return
		if(observer.current) observer.current.disconnect()
		observer.current = new IntersectionObserver(entries=>{
			if(entries[0].isIntersecting && hasMore){
				setPage(previousPage=>previousPage + 1)
			}
		})
		if(node) observer.current.observe(node)
	}, [loading, hasMore])

	async function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		const auth = localStorage.getItem('auth')

		if(window.confirm(`Deletar item ${id}?`)){
			try{
				await api.delete(`/products/${id}`, {
					headers: {
						'auth': auth
					}
				})
				setProducts(products.filter(product=>(parseInt(product.id)!==parseInt(id))))
				alert(`Item ${id} deletado com sucesso!`)
			}
			catch(error){
				alert(`Erro ao deletar item ${id}: ${error}!`)
			}
		}
	}

	function updateFilter(event){
		setSearchTerm(event.target.value)
		setPage(1)
	}

	function checkLine(event){

		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		if(table){
			var trItens = table.querySelectorAll('tbody tr')

			trItens = Array.from(trItens)

			trItens.forEach((item)=>{
				if(item.querySelector('input').checked){
					item.classList.add('selected')
				}
				else{
					item.classList.remove('selected')
				}
			})
		}
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/products/show/${id}`).then((response=>{
				const item = response.data

				const modal = document.querySelector("div#modalProductCadForm")
				setModalProduct(<AdmFormAddProduct item={item} sortedList={true} productAlter={updateProductListItem}/>)
				modal.classList.add('active')
			})).catch()
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function addItem(){
		if(window.confirm('Deseja adicionar novo produto?')){
			const auth = localStorage.getItem('auth')
			api.post('products/add/', {
				name: 'Produto Novo',
				description: '',
				reference: 0,
				quantity: 0,
				price: 0,
				status: 0,
				category: '0',
				destaque: 21,
				image: 'default_product.png'
			}, {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				const modal = document.querySelector("div#modalProductCadForm")
				const item=response.data
				item.extraImages=[]
				item.compositions=[]
				addProductListItem(item)
				setModalProduct(<AdmFormAddProduct item={item} productAlter={updateProductListItem} sortedList={false} productNew={addProductListItem}/>)
				modal.classList.add('active')
			}).catch(error=>{
				alert(`Erro ao adicionar novo produto: ${error}`)
			})

		}
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function updateProductListItem(productMod, sorted){
		if(sorted){
			setProducts(products=>{
				return products.map(product=>{
					if(parseInt(product.id)===parseInt(productMod.id)){
						return productMod
					}
					else{
						return product
					}
				}).sort(({destaque: destaque1}, {destaque: destaque2})=>{
					return destaque1-destaque2
				})
			})
		}
		else{
			setProducts(products=>{
				return products.map(product=>{
				if(parseInt(product.id)===parseInt(productMod.id)){
					return productMod
				}
				else{
					return product
				}
			})
		})
		}
	}

	function addProductListItem(productAdded){
		setProducts(products=>{
			return [productAdded, ...products]
		})
	}

	function SearchForm(){
		const [categories, setCategories] = useState([])

		useEffect(()=>{
			api.get(`/categories/?onlyActives=true`).then(response=>{
				setCategories(response.data)
			})
		}, [])

		return (
			<>
			<div className="modalSearch">
			<div className="headerModal">
				Pesquisar produtos
			</div>
			<div className="body">
				<form action="">
					<div className="bodySearch">
						<div className="input-group">
							<select name="category" id="category" value={category} onChange={event=>{
								setCategory(event.target.value)
								setPage(1)
								}}>
								<option value="">Selecione uma categoria</option>
								{categories.map(cat=>{
									return (
									<option value={cat.id}>{cat.name}</option>
									)
								})}
							</select>
							<label htmlFor="category">Categoria</label>
						</div>
					</div>
				</form>
			</div>
			</div>
			</>
		)
	}


	return (
		<>
		<div className="headerAdmPage">
			<div className="header">
				<div className="utilityBar">
					<div className="search">
						<h1>Lista de produtos</h1>
						<input type="text" value={searchTerm} onChange={updateFilter}/>
						<button className="search" onClick={()=>{
							const modal = document.querySelector('div#modalProductSearch')
							modal.classList.add('active')
						}}>
							<FiSearch size={28} color="#4287f5"/>
						</button>
					</div>
					<div className="buttons">
						<button className="utilityBtns" onClick={addItem}>Novo</button>
						<button className="utilityBtns" onClick={editItem}>Editar</button>
						<button className="utilityBtns" onClick={()=>{
							printList('Produtos', [
								{title: "Cód. Ref", dataKey: 'product_id_local'},
								{title: "Descrição", dataKey: 'name'},
								{title: "Estoque", dataKey: 'quantityValue'},
								{title: "Categoria", dataKey: 'category.name'},
								{title: "Valor", dataKey: 'priceValue'},
								{title: "Destaque", dataKey: 'destaque'},
								{title: "Status", dataKey: 'status_value'},
							], products.map(product=>{
								product.quantityValue = Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(product.quantity)
								product.priceValue = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(parseFloat(product.price))
								product.status_value=(product.status===1)?'Ativo':'Inativo'
								return product
							}), {
								title: "Total",
								values: [
									products.length
								]})
						}}>Imprimir</button>
					</div>
				</div>
				<div className="tblLegenda">
					<div className="legendaCtt">
						<div className="statusIndicator blue"></div> - Ativo
					</div>
					<div className="legendaCtt">
						<div className="statusIndicator yellow"></div> - Inativo
					</div>
				</div>
			</div>
		</div>
		<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'id', reverse: ordering!=='id:up'}))

							if(ordering==='id:up'){
								setOrdering('id:down')
							}
							else{
								setOrdering('id:up')
							}
						}}>ID {ordering==='id:up'?<FaChevronUp size={10}/>:(ordering==='id:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'product_id_local', reverse: ordering!=='product_id_local:up'}))

							if(ordering==='product_id_local:up'){
								setOrdering('product_id_local:down')
							}
							else{
								setOrdering('product_id_local:up')
							}
						}}>Cod. Ref {ordering==='product_id_local:up'?<FaChevronUp size={10}/>:(ordering==='product_id_local:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'name', reverse: ordering!=='name:up'}))

							if(ordering==='name:up'){
								setOrdering('name:down')
							}
							else{
								setOrdering('name:up')
							}
						}}>Descrição {ordering==='name:up'?<FaChevronUp size={10}/>:(ordering==='name:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products.map(p=>{
								p.quantity = parseFloat(p.quantity)

								return p
							}), prop:'quantity', reverse: ordering!=='quantity:up'}))

							if(ordering==='quantity:up'){
								setOrdering('quantity:down')
							}
							else{
								setOrdering('quantity:up')
							}
						}}>Estoque {ordering==='quantity:up'?<FaChevronUp size={10}/>:(ordering==='quantity:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'category.name', reverse: ordering!=='category.name:up'}))

							if(ordering==='category.name:up'){
								setOrdering('category.name:down')
							}
							else{
								setOrdering('category.name:up')
							}
						}}>Categoria {ordering==='category.name:up'?<FaChevronUp size={10}/>:(ordering==='category.name:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products.map(p=>{
								p.price = parseFloat(p.price)

								return p
							}), prop:'price', reverse: ordering!=='price:up'}))

							if(ordering==='price:up'){
								setOrdering('price:down')
							}
							else{
								setOrdering('price:up')
							}
						}}>Valor {ordering==='price:up'?<FaChevronUp size={10}/>:(ordering==='price:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'destaque', reverse: ordering!=='destaque:up'}))

							if(ordering==='destaque:up'){
								setOrdering('destaque:down')
							}
							else{
								setOrdering('destaque:up')
							}
						}}>Destaque {ordering==='destaque:up'?<FaChevronUp size={10}/>:(ordering==='destaque:down'?<FaChevronDown size={10}/>:'')}</th>
						<th onClick={()=>{
							setProducts(products=>orderByProp({array: products, prop:'status', reverse: ordering!=='status:up'}))

							if(ordering==='status:up'){
								setOrdering('status:down')
							}
							else{
								setOrdering('status:up')
							}
						}}>Status {ordering==='status:up'?<FaChevronUp size={10}/>:(ordering==='status:down'?<FaChevronDown size={10}/>:'')}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{products.map((product, index)=>{
						if(products.length===index+1){
							return (
								<>
									<tr ref={lastProductShowed} key={product.id} onClick={checkLine} onDoubleClick={editItem}>
										<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={product.id}/></td>
										<td>{product.id}</td>
										<td>{product.product_id_local}</td>
										<td>{product.name}</td>
										<td>{Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(product.quantity)}</td>
										<td>{product['category.name']}</td>
										<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(product.price)}</td>
										<td>{product.destaque}</td>
										<td><div className={product.status===1?"statusIndicator blue":"statusIndicator yellow"}></div></td>
										<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
									</tr>
								</>
								)
						}
						else{
							return (
								<>
									<tr key={product.id} onClick={checkLine} onDoubleClick={editItem}>
										<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={product.id}/></td>
										<td>{product.id}</td>
										<td>{product.product_id_local}</td>
										<td>{product.name}</td>
										<td>{Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(product.quantity)}</td>
										<td>{product['category.name']}</td>
										<td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(product.price)}</td>
										<td>{product.destaque}</td>
										<td><div className={(parseInt(product.status)===1)?"statusIndicator blue":"statusIndicator yellow"}></div></td>
										<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
									</tr>
								</>)
						}
					})}


				{(!error&&loading)?(<tr key={'carregando...'}>
					<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected"/></td>
					<td colSpan={9}>Carregando...</td>
				</tr>):''}
				{(error)?(<tr key={'erro'} style={{display: (error?'':'none')}}>
							<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected"/></td>
							<td colSpan={9}>Erro ao carregar produtos!</td>
						</tr>):''}

				<tr key={'Total'}>
					<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected"/></td>
					<td colSpan={7}>Total:</td>
					<td colSpan={2}>{products.length}</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div className="modalContent" id="modalProductCadForm">
			<div className="modal modalProduct">
			{modalProduct}
				<button className="close" id="closeProductModal" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>

		<div className="modalContent" id="modalProductSearch">
			<div className="modal modalSearch">
				<SearchForm/>
				<button className="close" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</>
	)
}

export default ListProd
