import React, { Suspense, useState, useEffect } from 'react'
import './style.css'
import api from '../../services/api'
const LoginCadForm = React.lazy(()=>import('./LoginCadForm'))
const UserInfo = React.lazy(()=>import('./UserInfo'))

function UserCadModal(){

	var userToken = localStorage.getItem('userToken')

	const [user, setUser] = useState()

	useEffect(()=>{
		if(userToken){
			api.get('/customer/validate', {
				headers: {
					auth: userToken
				}
			}).then(({data})=>{
				setUser(data)
			}).catch((error)=>{
				setUser()
				localStorage.removeItem('userToken')
			})
		}
		else{
			setUser()
		}
	}, [userToken])

    return (<>
        <div className="userModalBody">
			<Suspense fallback={()=>{}}>
				{user?<UserInfo user={user}/>:<LoginCadForm/>}
			</Suspense>
        </div>
    </>)
}

export default UserCadModal
