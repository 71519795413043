import React, {useState, useEffect} from 'react'
import './style.css'
import {FaBars} from 'react-icons/fa'
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import EditHollyday from './EditHollyday'
import printList from '../../generalFunctions/printTableAdm'
import api from '../../services/api'
const moment = require('moment')

function AdminFeriados(){

	const [searchTerm, setSearchTerm] = useState('')
	const [listHollydays, setListHollydays] = useState([])
	const [editedItem, setEditedItem] = useState()

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	useEffect(()=>{
		api.get(`/hollyday/${searchTerm?'?searchTerm='+searchTerm:''}`)
		.then(({data})=>{
			setListHollydays(data)
		})
	}, [searchTerm])

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		if(table){
			var trItens = table.querySelectorAll('tbody tr')

			trItens = Array.from(trItens)

			trItens.forEach((item)=>{
				if(item.querySelector('input').checked){
					item.classList.add('selected')
				}
				else{
					item.classList.remove('selected')
				}
			})
		}
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			const item = listHollydays.find(loh=>parseInt(loh.id)===parseInt(id))
			setEditedItem(item)
			const modal = document.querySelector("div#modalEditHollyday")
			modal.classList.add('active')
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function newItem(){
		setEditedItem()
		const modal = document.querySelector("div#modalEditHollyday")
		modal.classList.add('active')
	}

	async function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		const auth = localStorage.getItem('auth')

		if(window.confirm(`Deletar item ${id}?`)){
			try{
				await api.delete(`/hollyday/${id}`, {
					headers: {
						'auth': auth
					}
				})
				setListHollydays(listHollydays.filter(l=>(parseInt(l.id)!==parseInt(id))))
				alert(`Item ${id} deletado com sucesso!`)
			}
			catch(error){
				alert(`Erro ao deletar item ${id}: ${error}!`)
			}
		}
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	return (
	<>
		<div className="pageCompleteWithMenu">
			<aside className="menu active">
				<MenuToggleAdmin/>
			</aside>
			<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="body admBody" style={{marginTop: "50px"}}>
					<div className="headerAdmPage">
						<div className="header">
							<div className="utilityBar">
								<div className="search">
									<h1>Feriados</h1>
									<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
									<button className="search">
										<FiSearch size={28} color="#4287f5"/>
									</button>
								</div>
								<div className="buttons">
									<button onClick={newItem} className="utilityBtns">Novo</button>
									<button onClick={editItem} className="utilityBtns">Editar</button>
									<button className="utilityBtns">Imprimir</button>
								</div>
							</div>
						</div>
					</div>
					<div className="tableContainer">
					<table className="admItens">
						<thead>
							<tr>
								<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
								<th>Id</th>
								<th>Nome</th>
								<th>Data</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{listHollydays.map(lh=>{
								return <tr key={lh.id} onClick={checkLine} onDoubleClick={editItem}>
									<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={lh.id}/></td>
									<td>{lh.id}</td>
									<td>{lh.name}</td>
									<td>{lh.date}</td>
									<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
								</tr>
							})}
						</tbody>
					</table>
					</div>
				</div>
			</div>
			<div className="modalContent" id="modalEditHollyday">
			<div className="modal">
				<EditHollyday item={editedItem}/>
				<button className="close" id="btnCloseModalEditHollyday">
					<FiX size="30" color="#ccc" onClick={closeModal}/>
				</button>
			</div>
		</div>
		</div>
	</>
	)
}

export default AdminFeriados
