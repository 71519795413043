import React, {useState, useEffect} from 'react'
import './style.css';
import api from '../../services/api'
import {FiSearch, FiXCircle, FiX} from 'react-icons/fi'
import printList from '../../generalFunctions/printTableAdm'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'

import {FaBars} from 'react-icons/fa'

function Admin_dash(){
	const [formasPag, setFormasPag] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [item, setItem] = useState({})

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		api.get(`paymentmethod/search/?searchTerm=${searchTerm}`).then(response=>{
			setFormasPag(response.data)
		})
	}, [searchTerm])

	function updateFilter(event){
		setSearchTerm(event.target.value)
	}

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function newItem(){
		setItem('')

		const modal = document.querySelector("div#modalEditPaymentMethod")
		modal.classList.add('active')
	}

	async function deleteItem(event){
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		const auth = localStorage.getItem('auth')

		if(window.confirm(`Deletar forma de pagamento ${id}?`)){
			try{
				await api.delete(`/paymentmethod/${id}`, {
					headers: {
						'auth': auth
					}
				})
				setFormasPag(formasPag.filter(formaPag=>parseInt(formaPag.id)!==parseInt(id)))
				alert(`Forma de pagamento ${id} deletado com sucesso!`)
			}
			catch(error){
				alert(`${error.response.data.message}`)
			}
		}
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	function ItemModal({item}){

		const [nome, setNome] = useState(item?item.nome:'')
		const [status, setStatus] = useState(item?item.status:1)
		const [id] = useState(item?item.id:0)
		const [loading, setLoading] = useState(false)


		function handleSave(event){
			setLoading(true)
			event.preventDefault()

			api.post('/paymentmethod/', {
				id,
				nome,
				status
			}, {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				if(formasPag.findIndex(method=>method.id===id)!==-1){
					setFormasPag(formasPag=>formasPag.map(item=>{
						return (item.id===id)?response.data:item
					}))
				}
				else{
					setFormasPag(formasPag=>{
						return [...formasPag, response.data]
					})
				}
				setLoading(false)
				const modal = document.querySelector("div.modalContent")
				modal.classList.remove('active')
				alert(`Forma de pagamento ${response.data.id} salva com sucesso!`)
			}).catch(error=>{
				setLoading(false)
				alert(`${error.response.data.message}`)
			})
		}

		return (
		<>
		{loading?<div className="loaderModal">
			<div className="spinner"></div>
		</div>:<></>}
			<div className="headerCategory">
				<h1>Cadastro de forma de pagamento</h1>
			</div>
			<form action="" onSubmit={handleSave}>
			<div className="formPaymentMethod">
				<div className="input-group">
					<input type="text" disabled={(nome==='Dinheiro')} value={nome} onChange={event=>setNome(event.target.value)}/>
					<label htmlFor="">Nome</label>
				</div>
				<div className="input-group">
					<select id="status" value={status} onChange={event=>setStatus(event.target.value)}>
						<option value={1}>Ativo</option>
						<option value={0}>Inativo</option>
					</select>
					<label htmlFor="status">Status</label>
				</div>
			</div>
			<div className="footerAddForm">
				<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
				<button type="submit" className="save">Salvar</button>
			</div>
			</form>
		</>
		)
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/paymentmethod/${id}`, {
				headers: {
					'auth': auth
				}
			}).then((response=>{
				setItem(response.data)
				const modal = document.querySelector("div#modalEditPaymentMethod")
				modal.classList.add('active')
			})).catch()
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}


	return (
		<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="content">
			<div className="headerMenuAdm">
					<div className="containerAdmin">
						<div className="openMenu" onClick={toggleMenuActive}>
							<FaBars size={30} color="white"/>
						</div>
					</div>
			</div>
			<div className="body admBody" style={{marginTop: "50px"}}>
			<div className="headerAdmPage">
				<div className="header">
					<div className="utilityBar">
						<div className="search">
							<h1>Formas de pagamento</h1>
							<input type="text" value={searchTerm} onChange={updateFilter}/>
							<button className="search usefullBtn">
								<FiSearch size={28} color="#4287f5"/>
							</button>
						</div>
						<div className="buttons">
							<button className="utilityBtns" onClick={newItem}>Novo</button>
							<button className="utilityBtns" onClick={editItem}>Editar</button>
							<button className="utilityBtns" onClick={()=>{
								printList('Formas de pagamento', [
									{title: 'Código', dataKey: 'id'},
									{title: 'Forma de pagamento', dataKey: 'nome'}
								], formasPag)
							}}>Imprimir</button>
						</div>
					</div>
				</div>
			</div>
			<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th>Código</th>
						<th style={{minWidth: "85%"}}>Forma de Pagamento</th>
						<th style={{width: "75px"}}>Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{formasPag.map(formaPag=>{
						return <tr onClick={checkLine} onDoubleClick={editItem} key={formaPag.id}>
							<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={formaPag.id}/></td>
							<td>{formaPag.id}</td>
							<td>{formaPag.nome}</td>
							<td><div class={`statusIndicator ${formaPag.status===1?"blue":"yellow"}`}></div></td>
							<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
						</tr>
					})}
				</tbody>
			</table>
		</div>
		</div>
		<div className="modalContent" id="modalEditPaymentMethod">
			<div className="modal">
				<ItemModal item={item}/>
				<button className="close" id="btnCloseModalEditPaymentMethod" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</div>
		</div>
		</>
	)
}

export default Admin_dash
