import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import './style.css'

import {FaBars} from 'react-icons/fa'
import {FiSearch, FiX} from 'react-icons/fi'
import api from '../../services/api'
import printTable from '../../generalFunctions/printTableAdm'
const moment = require('moment')


function Admin_dash(){
	const location = useLocation()
	const [searchTerm, setSearchTerm] = useState('')
	const [orders, setOrders] = useState([])
	const [filteredOrders, setFilteredOrders] = useState(orders)
	const [dtInit, setDtInit] = useState((new URLSearchParams(location.search)).get('dtInit')?moment((new URLSearchParams(location.search)).get('dtInit')).format('YYYYMMDD'):moment().set('date', 1).format('YYYYMMDD'))
	const [dtEnd, setDtEnd] = useState((new URLSearchParams(location.search)).get('dtEnd')?moment((new URLSearchParams(location.search)).get('dtEnd')).format('YYYYMMDD'):moment().format('YYYYMMDD'))
	const auth = localStorage.getItem('auth')
	const [cliente, setCliente] = useState((new URLSearchParams(location.search)).get('cliente')?(new URLSearchParams(location.search)).get('cliente'):'')
	const [entregador, setEntregador] = useState(parseInt((new URLSearchParams(location.search)).get('entregador'))?(new URLSearchParams(location.search)).get('entregador'):'')

	const history = useHistory()

	useEffect(()=>{
		setDtInit((new URLSearchParams(location.search)).get('dtInit')?moment((new URLSearchParams(location.search)).get('dtInit')).format('YYYYMMDD'):moment().set('date', 1).format('YYYYMMDD'))
		setDtEnd((new URLSearchParams(location.search)).get('dtEnd')?moment((new URLSearchParams(location.search)).get('dtEnd')).format('YYYYMMDD'):moment().format('YYYYMMDD'))
		setCliente((new URLSearchParams(location.search)).get('cliente')?(new URLSearchParams(location.search)).get('cliente'):'')
		setEntregador(parseInt((new URLSearchParams(location.search)).get('entregador'))?(new URLSearchParams(location.search)).get('entregador'):'')
	}, [location])

	useEffect(()=>{
		setFilteredOrders(orders.filter(order=>(
			order['deliveryman.nome']
			.toLowerCase()
			.includes(searchTerm.toLowerCase())
			||`${order.firstname} ${order.lastname}`
			.toLowerCase()
			.includes(searchTerm.toLowerCase())
			)))
	}, [searchTerm, orders])

	useEffect(()=>{
		api.get(`/order/list/delivered/?dtInit=${dtInit}&dtEnd=${dtEnd}${entregador?'&entregador='+entregador:''}${cliente?'&searchTerm='+cliente:''}`, {
			headers: {
				'auth': auth
			}
		}).then(response=>{
			setOrders(response.data)
		})
	}, [auth, dtInit, dtEnd, entregador, cliente])

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')
		menuContainer.classList.toggle('active')
	}

	function SearchForm(){
		const [entregadores, setEntregadores] = useState([])
		const [editableDtInit, setEditableDtInit] = useState(dtInit)
		const [editableDtEnd, setEditableDtEnd] = useState(dtEnd)
		const [editableDeliveryMan, setEditableDeliveryMan] = useState(entregador)
		const [editableClient, setEditableClient] = useState(cliente)

		useEffect(()=>{
			api.get('/deliveryman/', {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				setEntregadores(response.data)
			})
		}, [])

		function search(event){
			event.preventDefault()
			setDtInit(moment(editableDtInit).format('YYYYMMDD'))
			setDtEnd(moment(editableDtEnd).format('YYYYMMDD'))
			history.push({
				pathname: '/admin/dashboard/relatorios/entrega',
				search: `dtInit=${editableDtInit}&dtEnd=${editableDtEnd}${(editableDeliveryMan)?'&entregador='+editableDeliveryMan:''}${editableClient?'&cliente='+editableClient:''}`
			})
			closeModal(event)
		}

		return (
		<>
			<div className="modalSearch">
			<div className="headerModal">
				Pesquisar pedidos finalizados
			</div>
			<div className="body">
				<form action="" onSubmit={search}>
					<div className="bodySearch">
						<div className="input-group">
							<select id="entregador" value={editableDeliveryMan} onChange={event=>setEditableDeliveryMan(event.target.value)}>
								<option value={0}>Selecione o entregador</option>
								{entregadores.map(entregador=>{
									return (
										<option key={entregador.id} value={entregador.id}>
											{entregador.nome}
										</option>
									)
								})}
							</select>
							<label htmlFor="entregador">Entregador</label>
						</div>
						<div className="input-group">
							<input type="text" id="cliente" value={editableClient} onChange={event=>setEditableClient(event.target.value)}/>
							<label htmlFor="cliente">Cliente</label>
						</div>
						<div className="dateInputs">
							<div className="input-group">
								<input type="date" value={moment(editableDtInit).format('YYYY-MM-DD')} onChange={event=>setEditableDtInit(moment(event.target.value).format('YYYYMMDD'))}/>
							</div>
							a
							<div className="input-group">
								<input type="date" value={
										moment(editableDtEnd)
										.format('YYYY-MM-DD')
									} onChange={
											event=>setEditableDtEnd(moment(event.target.value)
											.format('YYYYMMDD'))
										}/>
							</div>
						</div>
					</div>
				<div className="footerAddForm">
					<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
					<button type="submit" className="save">Pesquisar</button>
				</div>
				</form>
			</div>
			</div>
		</>
		)
	}

	return (
		<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
			<div className="body admBody" style={{marginTop: "50px"}}>
			<div className="headerAdmPage">
				<div className="header">
					<div className="utilityBar">
						<div className="search">
							<h1>Relatório de entregas</h1>
							<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
							<button className="search"  onClick={()=>{
									const modal = document.querySelector('div#modalSearchOrders')
									modal.classList.add('active')
								}}>
								<FiSearch size={28} color="#4287f5"/>
							</button>
						</div>
						<div className="buttons">
							<div></div>
							<div></div>
							<button className="utilityBtns" onClick={()=>{
								printTable('Relatório de entregas', [
									{title: 'Pedido', dataKey: 'id'},
									{title: 'Entregador', dataKey: 'deliveryman.nome'},
									{title: 'Data', dataKey: 'date_sanitized'},
									{title: 'Cliente', dataKey: 'cli_nome'},
									{title: 'Endereço', dataKey: 'shipping_address_1'},
									{title: 'Total', dataKey: 'total_sanitized'}
								], filteredOrders.map(order=>{
									order.date_sanitized = moment(order.createdAt).format('DD/MM/YYYY')
									order.total_sanitized = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(order.total)
									order.cli_nome  = `${order.firstname} ${order.lastname}`
									return order
								}))
							}}>Imprimir</button>
						</div>
					</div>
					</div>
			</div>
			</div>
			<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th>Pedido</th>
						<th className="halfFilled">Entregador</th>
						<th>Data</th>
						<th>Cliente</th>
						<th>Endereço</th>
						<th>Valor</th>
					</tr>
				</thead>
				<tbody>
					{filteredOrders.map(order=>{
						return (
							<tr key={order.id}>
								<td>{order.id}</td>
								<td>{order['deliveryman.nome']}</td>
								<td>{moment(order.createdAt).format('DD/MM/YYYY')}</td>
								<td>{order.firstname} {order.lastname}</td>
								<td>{order.shipping_address_1}</td>
								<td>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(order.total)}</td>
							</tr>
						)
					})}
					<tr>
						<td colSpan={5}>Total</td>
						<td>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(filteredOrders.reduce((t,o)=>t+parseFloat(o.total), 0))}</td>
					</tr>
				</tbody>
			</table>
		</div>
		</div>
		</div>

		<div className="modalContent" id="modalSearchOrders">
			<div className="modal modalSearch">
				<SearchForm/>
				<button className="close" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</>
	)
}

export default Admin_dash
