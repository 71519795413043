import React, {useState, useEffect} from 'react'
import './style.css'
import {FiXCircle, FiX} from 'react-icons/fi'
import api from '../../../../services/api'
import FormCadCompositionItem from './FormCadCompositionItem'

function AdmListCompositions({selectCompositionCallBack=()=>{}, productId, closeMySelf=(()=>{})}){

	const [compositions, setCompositions] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [editedComposition, setEditedComposition] = useState()

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		api.get(`/compositionItems/?searchTerm=${searchTerm}`, {
			headers: {
				auth
			}
		}).then(({data: comps})=>{
			setCompositions(comps)
		})
	}, [searchTerm, auth])

	function deleteComplement(event){
		event.preventDefault()
		const clicked = event.target
		const id = clicked.closest('tr').querySelector('input').dataset.id

		if(window.confirm(`Deseja realmente deletar item ${id}? Isso irá deletar todos os registros de composição de produto.`)){
			api.delete(`/compositionItem/${id}`, {
				headers: {
					auth
				}
			}).then(({data})=>{
				if(data){
					setCompositions(compositions.filter(c=>parseInt(c.id)!==parseInt(id)))
					alert(`Item ${id} deletado com sucesso!`)
				}
			})
		}
	}

	function checkLine(id){
		setCompositions(compositions.map(c=>{
			if(c.id===id){
				if(c.checked){
					c.checked=false
				}
				else{
					c.checked=true
				}
			}
			return c
		}))

	}

	function openFormCadCompotitionItem(id){

		if(id){
			const newComposition = compositions.find(c=>parseInt(c.id)===parseInt(id))

			setEditedComposition(newComposition)
		}
		else{
			setEditedComposition()
		}

		const modal = document.querySelector("div#modalAddCompositionItemContainer")
		modal.classList.add('active')
	}

	function editItem(event){
		event.preventDefault()
		const input = document.querySelector('table#compositionsList tr td input:checked')

		if(input){
			const id = input.dataset.id

			if(id){
				openFormCadCompotitionItem(id)
			}
			else{
				alert(`Nenhum item selecionado!`)
			}
		}
		else{
			alert(`Nenhum item selecionado!`)
		}
	}

	function newItem(event){
		event.preventDefault()
		openFormCadCompotitionItem()
	}

	function closeModalAddCompositionItem(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalAddCompositionItemContainer")
		modal.classList.remove('active')
	}

	function saveCallback(newComposition){
		const compositionFinded = compositions.find(c=>parseInt(c.id)===parseInt(newComposition.id))

		if(compositionFinded){
			setCompositions(compositions.map(c=>{
				if(parseInt(c.id)===parseInt(newComposition.id)){
					return newComposition
				}
				else{
					return c
				}
			}))
		}
		else{
			setCompositions([...compositions, newComposition])
		}

		setEditedComposition()
	}

	function selectionCallback(id){
		setCompositions(compositions.map(c=>{
			if(c.id===id){
				if(c.checked){
					c.checked=false
				}
				else{
					c.checked=true
				}
			}
			return c
		}))
	}

	function saveItems(event){
		event.preventDefault()

		compositions.filter(c=>c.checked).map((c,i)=>{
			api.post(`/product/composition/`, {
				compositionItemId: c.id,
				productId
			}, {
				headers: {
					auth
				}
			}).then(({data: newComp})=>{
				selectCompositionCallBack(newComp)
			})


			return true
		})

		setCompositions(compositions=>{
			return compositions.map(c=>{
				c.checked=false

				return c
			})
		})

		closeMySelf(event)


	}

	return (
		<>
			<h1 className="formLabel">Itens Adicionais</h1>
			<div className="containerBody">
				<div className="headerItems">
					<input className="searchTerm" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)} type="text"/>
					<div className="buttons">
						<button onClick={newItem} className="buttonNew">Novo</button>
						<button onClick={editItem} className="buttonEdit">Editar</button>
					</div>
				</div>
				<table className="admItens" id="compositionsList">
					<thead>
						<tr>
							<th></th>
							<th>Código</th>
							<th>Nome</th>
							<th>Valor</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{compositions.map(c=>{
							return <tr onClick={event=>{
									checkLine(c.id)
								}} className={c.checked?"selected":''}>
								<td key={c.id} className="checkbox"><input type="checkbox" name="selected" checked={c.checked} data-id={c.id} onChange={event=>{
									selectionCallback(c.id)
								}}/></td>
								<td>{c.id}</td>
								<td>{c.name}</td>
								<td>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(c.value)}</td>
								<td><button className="deleteBtn" onClick={deleteComplement}><FiXCircle size={16} color="red"/></button></td>
							</tr>
						})}
					</tbody>
				</table>
				<div class="footerAddForm" style={{padding: 0, paddingTop: 10}}>
					<div></div>
					<button type="button" onClick={saveItems} class="save">Inserir itens</button>
				</div>
			</div>
			<div className="modalContent" id="modalAddCompositionItemContainer">
				<div className="modal" id="modalAddCompositionItem">
					<FormCadCompositionItem saveCallback={saveCallback} composition={editedComposition}/>
					<button className="close" onClick={closeModalAddCompositionItem}>
						<FiX size={30} color="#ccc"/>
					</button>
				</div>
			</div>
		</>
	)
}

export default AdmListCompositions
