import {useEffect, useState} from 'react'
import api from '../../services/api'
import {CancelToken, isCancel} from 'axios'
export default function useProductSearch(query, category, page){
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [products, setProducts] = useState([])
    const [hasMore, setHasMore] = useState(false)

    useEffect(()=>{
        setProducts([])
    }, [query, category])

    useEffect(()=>{
        let cancel
        setLoading(true)
        setError(false)
        api.get(`products/search/${page}/?searchTerm=${encodeURI(query)}&categoryId=${category}`, {
            cancelToken: new CancelToken(c=>cancel=c)
        }).then(response=>{
            setProducts(prevProducts=>{
                return [...new Set([...prevProducts, ...response.data])].sort(({destaque: destaque1}, {destaque: destaque2})=>{
                    return destaque1-destaque2
                })
            })
            setHasMore(response.data.length>0)
            setLoading(false)
        }).catch(error=>{
            if (isCancel(error)) return
            setError(error)
        })
        return ()=>cancel()
    }, [query, category, page])

    return {
        loading,
        error,
        products,
        setProducts,
        hasMore
    }
}
