import React, {useState, useEffect} from 'react'
import api from '../../../../services/api'
import {FiX} from 'react-icons/fi'
import './style.css'
import axios from 'axios'

function EditCityRegion({region, cidadeId, callBackSave=r=>console.log(r)}){

    const [name, setName] = useState('')
    const [CEP, setCEP] = useState('')
    const [shipping_value, setShippingValue] = useState(0)

    const auth = localStorage.getItem('auth')

    useEffect(()=>{
        if(region){
            setName(region.name)
            setShippingValue(region.shipping_value)
        }
        else{
            setName('')
            setShippingValue(0)
        }
    }, [region])


    function closeModal(event){
		const modal = event.target.closest("div#modalEditCityRegion")
		modal.classList.remove('active')
    }

    function handleSave(event){
        event.preventDefault()

        api.post('/cityRegion/', {
            ...(region?{id: region.id}:{}),
            name,
            shipping_value,
            cidadeId
        }, {
            headers: {
                auth
            }
        }).then(({data: newRegion})=>{
            callBackSave(newRegion)
            alert(`Bairro ${newRegion.id} salvo com sucesso!`)
        })
    }

	function searchCEPAndReturnNeighbourhoodName(event){
		event.preventDefault()
		axios.get(`https://brasilapi.com.br/api/cep/v1/${CEP.replace(/\D/g, '')}`)
		.then(({data: cepData})=>{
			setName(cepData.neighborhood)
			const modal = document.querySelector("div#modalCEP")
			modal.classList.remove('active')
		})
		.catch(error=>{
			alert(`CEP ${CEP.replace(/(\d{5})(\d{3})/g, '$1-$2')} não encontrado!`)
		})
	}

    return (
        <>
        <div className="headerCategory">
			<h1>Cadastro de Bairro</h1>
		</div>
        <form action="" onSubmit={handleSave}>
            <div className="formCategory">
				<div className="neighborhoodName">
					<div className="input-group">
						<input type="text" value={name} onChange={event=>setName(event.target.value)}/>
						<label>Nome</label>
					</div>
					<button className="cepBtn" onClick={event=>{
						event.preventDefault()
						const modal = document.querySelector("div#modalCEP")
						modal.classList.add('active')
					}}>Inserir CEP</button>
				</div>
				<div className="input-group">
					<input type="text" value={Intl.NumberFormat('pt-br', {maximumFractionDigits:2, minimumFractionDigits:2}).format(shipping_value)} onChange={event=>setShippingValue(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
					<label>Frete</label>
				</div>
			</div>
            <div className="footerAddForm">
				<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
				<button type="submit" className="save">Salvar</button>
			</div>
        </form>
		<div className="modalContent" id="modalCEP">
			<div className="modal">
				<form action="" className="formCEP" onSubmit={searchCEPAndReturnNeighbourhoodName}>
					<div className="input-group">
						<input type="text" maxLength={9} value={CEP.replace(/(\d{5})(\d{1,3})/g, '$1-$2')} onChange={event=>setCEP(event.target.value.replace(/\D/g, ''))}/>
						<label>CEP</label>
					</div>
					<div className="footerAddForm">
						<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
						<button type="submit" className="save">Buscar</button>
					</div>
				</form>
				<button className="close" id="btnCloseModalCEP" onClick={event=>{
					event.preventDefault()
					const modal = document.querySelector("div#modalCEP")
					modal.classList.remove('active')
				}}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
        </>
    )
}

export default EditCityRegion
