import React, {useState, useEffect, useRef, useCallback, Suspense} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import './style.css'
import api from '../../services/api'
import {FiPlusCircle, FiX, FiAlertTriangle} from 'react-icons/fi'
import useProductList from '../useProductList'
import openDescriptionImg from '../../assets/mclique.png'
const ItemModal = React.lazy(()=>import('../ItemModal'))
function ItemList(){
	const [modalContent, setModalContent] = useState([])
	const [empresa, setEmpresa] = useState({})
	const [page, setPage] = useState(1)
	const [description, setDescription] = useState('')

	const [hasSecondPrice, setHasSecondPrice] = useState(false)
	var userToken = localStorage.getItem('userToken')
	const search = useLocation().search

	const history = useHistory()

	const {
		products,
		hasMore,
		loading,
		error
	} = useProductList(page)

	useEffect(()=>{
		if(userToken){
			api.get('/customer/validate', {
				headers: {
					auth: userToken
				}
			}).then(({data})=>{
				setHasSecondPrice(parseInt(data.customer_group.second_price)===1)
			}).catch(()=>{
				setHasSecondPrice(false)
				localStorage.removeItem('userToken')
			})
		}
		else{
			setHasSecondPrice(false)
		}

		const productId = (new URLSearchParams(search).get('productId'))

		if(productId){
			api.get(`products/show/${productId}`).then(({data})=>{
				if(data){
					addItemToCart(data)
				}
			})
		}
	}, [userToken, products, search])

	const observer = useRef()
	const lastProductDiv = useCallback(node=>{
		if (loading) return
		if(observer.current) observer.current.disconnect()
		observer.current=new IntersectionObserver(entries=>{
			if(entries[0].isIntersecting && hasMore){
				setPage(prevPage=>prevPage+1)
			}
		})
		if (node) observer.current.observe(node)
	}, [loading, hasMore])

	useEffect(()=>{

		api.get('/company').then(response=>{
			setEmpresa(response.data)
		})
	}, [])

	async function addItemToCart(item){
		if(!(parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0)){
			await api.get(`products/show/${item.id}`).then(response=>{
				const modal = document.querySelector("div#modalAdd")
				setModalContent(
					<Suspense fallback={()=>{}}>
						<ItemModal item={response.data}/>
					</Suspense>
				)
				modal.classList.add('active')
			})
		}
	}

	function closeModal(event){
		event.preventDefault()
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
		history.push('./')
	}

	function openDescription(event){
		const modal = document.querySelector("div#modalDetailsDirect")
		if(modal){
			modal.classList.add('active')
		}
	}

	function showStock(item){
		if(empresa.show_estoque===1){
			return (<p className="estoque">Qtd.: {Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.quantity)}</p>)
		}
		else{
			return (<></>)
		}
	}

	return (
		<>
		<div className="containerGen" style={{position: 'relative'}}>
		<div className="list">
			{products.map((item, index)=>{
				if((parseInt(empresa.show_out_of_stock)===0||parseInt(empresa.stock_control_type)===0)){
					if(products.length===(index+1)){
						return <div ref={lastProductDiv} className="item" key={item.id} onClick={()=>{
							addItemToCart(item)

							if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
								history.push(`?productId=${item.id}`)
							}

						}}>
							<div className="image">
								<img src={item.image?`./assets/${item.image}`:`./assets/default_product.png`} alt=""/>
							</div>
							<div className="bodyItem">
								<h1>{item.name}</h1>
								<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
									event.preventDefault()
									if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
										setDescription(item.description)
										openDescription(event)
									}
								}}>
									Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
								</div>
							</div>
							<div className="footerItem">
								{(parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0)?
								<>
								<div className="out_of_stock">
									Indisponível
								</div>
								</>:
								<>
								{(empresa.flag_show_price)?(
									<p className="price">{(parseInt(item.composite_product)===1
									&&
									parseInt(item.composition_type)!==0
									&&
									item.compositions?.length>0)
									?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</p>
								):''}
								{showStock(item)}
								<button className="add">
									<FiPlusCircle size={35} color="green"/>
								</button>
								</>
								}
							</div>
						</div>
					}
					else{
						return <div className="item" key={item.id} onClick={()=>{
							addItemToCart(item)

							if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
								history.push(`?productId=${item.id}`)
							}
						}}>
							<div className="image">
								<img src={item.image?`./assets/${item.image}`:`./assets/default_product.png`} alt=""/>
							</div>
							<div className="bodyItem">
								<h1>{item.name}</h1>
								<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
									event.preventDefault()
									if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
										setDescription(item.description)
										openDescription(event)
									}
								}}>
									Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
								</div>
							</div>
							<div className="footerItem">
								{(parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0)?
								<>
								<div className="out_of_stock">
									Indisponível
								</div>
								</>:
								<>
								{(empresa.flag_show_price)?(
									<p className="price">{(parseInt(item.composite_product)===1
									&&
									parseInt(item.composition_type)!==0
									&&
									item.compositions?.length>0)
									?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</p>
								):''}
								{showStock(item)}
								<button className="add">
									<FiPlusCircle size={35} color="green"/>
								</button>
								</>
								}
							</div>
						</div>
					}
				}
				else{
					if(item.quantity>0){
						if(products.length===(index+1)){
							return <div ref={lastProductDiv} className="item" key={item.id} onClick={()=>{
								addItemToCart(item)

								if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
									history.push(`?productId=${item.id}`)
								}
							}}>
								<div className="image">
									<img src={item.image?`./assets/${item.image}`:`./assets/default_product.png`} alt=""/>
								</div>
								<div className="bodyItem">
									<h1>{item.name}</h1>
									<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
										event.preventDefault()
										setDescription(item.description)
										openDescription(event)
									}}>
										Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
									</div>
								</div>
								<div className="footerItem">
									{(parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0)?
										<>
										<div className="out_of_stock">
											Indisponível
										</div>
										</>:
										<>
										{(empresa.flag_show_price)?(
											<p className="price">{(parseInt(item.composite_product)===1
											&&
											parseInt(item.composition_type)!==0
											&&
											item.compositions?.length>0)
											?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</p>
										):''}
										{showStock(item)}
										<button className="add">
											<FiPlusCircle size={35} color="green"/>
										</button>
										</>
										}
								</div>
							</div>
						}
						else{
							return <div className="item" key={item.id} onClick={()=>{
								addItemToCart(item)

								if(!((parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0))){
									history.push(`?productId=${item.id}`)
								}
							}}>
								<div className="image">
									<img src={item.image?`./assets/${item.image}`:`./assets/default_product.png`} alt=""/>
								</div>
								<div className="bodyItem">
									<h1>{item.name}</h1>
									<div style={{color: '#888', display: 'flex', alignItems: 'center', gap: 10, padding: 5}} onClick={event=>{
										event.preventDefault()
										setDescription(item.description)
										openDescription(event)
									}}>
										Detalhamento do item <img src={openDescriptionImg} width="15px" alt=""/>
									</div>
								</div>
								<div className="footerItem">
									{(parseInt(empresa.show_out_of_stock)===1&&parseInt(item.quantity)<=0)?
									<>
									<div className="out_of_stock">
										Indisponível
									</div>
									</>:
									<>
									{(empresa.flag_show_price)?(
										<p className="price">{(parseInt(item.composite_product)===1
										&&
										parseInt(item.composition_type)!==0
										&&
										item.compositions?.length>0)
										?`À partir de ${Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.min(...item.compositions.map(c=>c.composition_item.value)))}`:Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(hasSecondPrice?item.second_price:item.price)}</p>
									):''}
									{showStock(item)}
									<button className="add">
										<FiPlusCircle size={35} color="green"/>
									</button>
									</>
									}
								</div>
							</div>
						}
					}
				}
			})}
			<div className="loaderContainer" style={(loading&&!error)?{display: 'flex'}:{display: 'none'}}>
				<div className="loader"></div>
			</div>
			<div className="errorChargingItems" style={(error)?{display: 'flex'}:{display: 'none'}}>
				<FiAlertTriangle size={25} color="red"/> Erro ao carregar itens
			</div>
			<div className="modalContent" id="modalAdd">
				<div className="modal">{modalContent}
					<button className="close" onClick={closeModal}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
			<div className="modalContent" id="modalDetailsDirect" style={{zIndex:1080}}>
				<div className="modal">
					<div className="headerModal">
						Detalhamento do item
					</div>
					<div className="contentBodyProduct">
					<div className="body">
						<div dangerouslySetInnerHTML={{__html: description}} className="descriptionOfItem">
						</div>
					</div>
					</div>
					<button className="close" onClick={(event)=>{
						event.preventDefault()
						const modal = event.target.closest("div#modalDetailsDirect")
						modal.classList.remove('active')

					}}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
		</div>
		</div>
		</>
	)
}

export default ItemList
