import React, {useState, useEffect} from 'react'
import './style.css'
import api from '../../services/api'
import { FiSearch, FiX, FiXCircle } from 'react-icons/fi'
import {FaBars} from 'react-icons/fa'
import printList from '../../generalFunctions/printTableAdm'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'
import EditCustomerGroup from './EditCustomerGroup'

function Admin_customer_group(){

	const [customerGroups, setCustomerGroups] = useState([])
	const [groupEdited, setGroupEdited] = useState({})
	const [searchTerm, setSearchTerm] = useState('')

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		var query = ''
		if(searchTerm){
			query+=`?searchTerm=${searchTerm}`
		}

		api.get(`/customerGroup/${query}`, {
			headers:{
				'auth': auth
			}
		}).then(({data: groups})=>{
			setCustomerGroups(groups)
		})
	}, [auth, searchTerm])

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id

			const selectedItem = customerGroups.filter(group=>parseInt(group.id)===parseInt(id))[0]
			setGroupEdited(selectedItem)

			const modal = document.querySelector("div#modalEditCustomerGroup")
			modal.classList.add('active')
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function addItem(){
		setGroupEdited(undefined)

		const modal = document.querySelector("div#modalEditCustomerGroup")
		modal.classList.add('active')
	}

	function deleteItem(event){
		const clicked = event.target

		const id = clicked.closest('tr').querySelector('input').dataset.id

		if(window.confirm(`Deletar Grupo de Cliente ${id}?`)){
			api.delete(`/customerGroup/${id}`, {
				headers: {
					'auth': auth
				}
			}).then(()=>{
				alert(`Grupo de Cliente ${id} deletado com sucesso!`)
				window.location.reload()
			}).catch(error=>{
				alert(`Erro ao deletar grupo de Cliente ${id}: ${error}`)
			})
		}
	}

	return (
		<>
			<div className="pageCompleteWithMenu">
				<aside className="menu active">
					<MenuToggleAdmin/>
				</aside>
				<div className="headerMenuAdm">
					<div className="containerAdmin">
						<div className="openMenu" onClick={toggleMenuActive}>
							<FaBars size={30} color="white"/>
						</div>
					</div>
				</div>
				<div className="content">
				<div className="body admBody" style={{marginTop: "50px"}}>
				<div className="headerAdmPage">
					<div className="header">
						<div className="utilityBar">
							<div className="search">
								<h1>Grupos de cliente</h1>
								<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
								<button className="search">
									<FiSearch size={28} color="#4287f5"/>
								</button>
							</div>
							<div className="buttons">
								<button className="utilityBtns" onClick={addItem}>Novo</button>
								<button className="utilityBtns" onClick={editItem}>Editar</button>
								<button className="utilityBtns" onClick={()=>{
									printList('Grupo de clientes', [
										{title: 'Id', dataKey: 'id'},
										{title: 'Nome', dataKey: 'group_name'},
										{title: 'Segundo preço', dataKey: 'second_price_boolean_name'},
										{title: 'Status', dataKey: 'statusName'}
									], customerGroups.map(group=>{
										group.statusName = (group.status===1)?'Ativo':'Inativo'
										group.second_price_boolean_name = (group.second_price===1)?'Sim':'Não'
										return group
									}))
								}}>Imprimir</button>
							</div>
						</div>
					</div>
				</div>
				<div className="tableContainer">
				<table className="admItens">
					<thead>
						<tr>
							<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
							<th>Id</th>
							<th>Nome</th>
							<th>Preço promoç.</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{customerGroups.map(group=>{
							return <tr onClick={checkLine} onDoubleClick={()=>{
								editItem()
							}}>
								<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={group.id}/></td>
								<td>{group.id}</td>
								<td>{group.group_name}</td>
								<td>{group.second_price===1?'Sim':'Não'}</td>
								<td><div className={group.status===1?'statusIndicator blue':'statusIndicator yellow'}></div></td>
								<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
							</tr>
						})}
					</tbody>
				</table>
				</div>
				</div>
				</div>
			</div>
			<div className="modalContent" id="modalEditCustomerGroup">
				<div className="modal">
					<EditCustomerGroup group={groupEdited} saveCallback={closeModal}/>
					<button className="close" id="btnCloseModal" onClick={closeModal}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
		</>
	)
}

export default Admin_customer_group
