import React from 'react'
import './style.css'
import AdmListProducts from '../../Components/AdmListProducts'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'

import {FaBars} from 'react-icons/fa'

function Admin_dash(){

    function toggleMenuActive(){
        const menuContainer = document.querySelector('aside.menu')

        menuContainer.classList.toggle('active')
    }

    return (
        <>
        <div className="pageCompleteWithMenu">
        <aside className="menu active">
            <MenuToggleAdmin/>
        </aside>
        <div className="headerMenuAdm">
                <div className="containerAdmin">
                    <div className="openMenu" onClick={toggleMenuActive}>
                        <FaBars size={30} color="white"/>
                    </div>
                </div>
        </div>
        <div className="content">
            <div className="body admBody" style={{marginTop: "50px"}}>
                <AdmListProducts/>
            </div>
        </div>
        </div>
        </>
    )
}

export default Admin_dash