//const html2canvas = require('html2canvas')
import  jsPDF from 'jspdf'
import 'jspdf-autotable'
const moment = require('moment')

const printModel = {
	model58(element, objContent, id) {
		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [element.clientWidth, element.clientWidth*1200/48]
		})

		actualHeightPadding+=25

		const fontSize = 20
		const margin = 5

		// Add company name
		pdf.setFontSize(fontSize)
		const razao = objContent.empresa.razao.toUpperCase()
		const companyName = pdf.splitTextToSize(`${razao}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, companyName, 'center')

		actualHeightPadding+=pdf.getTextDimensions(companyName).h+5

		//Add company address
		pdf.setFontSize(fontSize)
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`${objContent.empresa.address.toUpperCase()}`, pdf.internal.pageSize.getWidth()-((margin*2)+4))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, addressText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(addressText).h+5

		//Add company whatsapp
		const wppText = pdf.splitTextToSize(`WHATSAPP: ${objContent.empresa.phone && objContent.empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, wppText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(wppText).h+5

		//Add date
		const dateText = pdf.splitTextToSize(`${objContent.pedido.data && moment(objContent.pedido.data).format('DD/MM/YYYY')} ${objContent.pedido.data && moment(objContent.pedido.data).format('HH:mm')}`)
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, dateText, 'center')
		actualHeightPadding+=25

		if(objContent.empresa.show_wait_time_printing===1){
			//Prints the company name on the left of screen
			const waitTimeText = pdf.splitTextToSize(`Espera: ${(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('HH:mm'))}`)
			pdf.text(margin, actualHeightPadding, waitTimeText, 'left')

			//Prints the order time on the right of screen
			const timeOrderText = pdf.splitTextToSize(`${(moment(objContent.pedido.data.replace(/.\d{3}Z$/g, ''), 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'))}`)
			pdf.text(pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, timeOrderText, 'right')
			actualHeightPadding+=20

			//Calculates and prints the arrival forecast

			// Adds seconds
			var waitTimeInSeconds = parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('ss'))
			// Adds minutes in seconds
			waitTimeInSeconds += (parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('mm'))*60)
			// Adds hours in seconds
			waitTimeInSeconds += (parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('HH'))*3600)

			const arrivalForecastText = `Previsão de chegada: ${moment(objContent.pedido.data.replace(/.\d{}3Z$/g, ''), 'YYYY/MM/DDTHH:mm:ss').add(waitTimeInSeconds, 'seconds').format('HH:mm')}`

			pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, arrivalForecastText, 'center')

		}
		else{
			pdf.setFontSize(fontSize)
			const orderNumberText = pdf.splitTextToSize(`${objContent.empresa.print_title.toUpperCase()}: ${id}`)
			pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, orderNumberText, 'center')
		}

		actualHeightPadding += 15

		//Get items from order and fill a table with it
		const itensArray = [].concat(...objContent.pedido.items.map(item=>{
			return [
				[{content: item.name.toUpperCase(), colSpan: 4, styles: { overflow: 'linebreak', fontSize}}],
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.quantity)), styles: {minCellWidth: 35, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: (Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.price)), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.total), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, halign: 'right'}},
				],
				((item.obs)?[{ content: item.obs, colSpan: 4, styles: {overflow: 'linebreak', fontSize, cellPadding: {top: 0, right: 0, bottom: 4, left:10}}}]:[{content: '', colSpan: 4, styles:{fontSize: 0}, cellPadding: 0}])
			]
		}))

		//Fill table with items
		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: margin, bottom: 0, left: margin},
			styles: {fontSize, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-(margin*2)),
			head: [['DESCRIÇÃO', {content: 'QTD.', styles: {halign: 'right'}}, {content: 'VR. UNIT.', styles: {halign: 'right'}}, {content: 'TOTAL', styles: {halign: 'right'}}]],
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return row.height
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			},
			body: [
				...itensArray,
				[{ content: 'TOTAL DOS ITENS', colSpan: 3, styles: {overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.subTotal), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}],
				[{ content: 'FRETE', colSpan: 3, styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.frete), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}],
				[{ content: 'TOTAL', colSpan: 3, styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.total), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}]
			]
		})

		actualHeightPadding+=55

		// Add Obs
		const obsText = pdf.splitTextToSize(`${objContent.pedido.obs.toUpperCase()}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, obsText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(obsText).h+10

		//Add client's name
		const clientText = pdf.splitTextToSize(`CLIENTE: ${objContent.cliente.nome.toUpperCase()}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, clientText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(clientText).h+10

		//Add client's phone
		const clientPhoneText = pdf.splitTextToSize(`TEL. CLIENTE: ${objContent.cliente.telefone.toUpperCase()}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, clientPhoneText, 'center')

		var output = pdf.output('blob')

		window.open(URL.createObjectURL(output))
	},
	model80(element, objContent, id){
		var actualHeightPadding = 0

		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: [element.clientWidth, element.clientWidth*550/80]
		})

		const fontSize = 14
		const margin = 15

		actualHeightPadding+=20

		// Add company name
		pdf.setFontSize(fontSize*1.8)
		pdf.setFontType('bold')
		const companyName = pdf.splitTextToSize(`${objContent.empresa.razao}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, companyName, 'center')

		actualHeightPadding+=pdf.getTextDimensions(companyName).h+5

		//Add company address

		pdf.setFontSize(fontSize)
		pdf.setFontType('normal')
		const addressText = pdf.splitTextToSize(`End.: ${objContent.empresa.address}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, addressText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(addressText).h+5

		//Add company whatsapp
		const wppText = pdf.splitTextToSize(`WhatsApp: ${objContent.empresa.phone && objContent.empresa.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}`)
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, wppText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(wppText).h+5
		//Add date
		const dateText = pdf.splitTextToSize(`Data: ${objContent.pedido.data && moment(objContent.pedido.data.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}`)
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, dateText, 'center')
		actualHeightPadding+=25

		//Conditionally add Order Number title, or wait_time and time of order
		if(objContent.empresa.show_wait_time_printing===1){
			//Prints the company name on the left of screen
			const waitTimeText = pdf.splitTextToSize(`Tempo de espera: ${(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('HH:mm'))}`)
			pdf.text(margin, actualHeightPadding, waitTimeText, 'left')

			//Prints the order time on the right of screen
			const timeOrderText = pdf.splitTextToSize(`Hora do pedido: ${(moment(objContent.pedido.data.replace(/.\d{3}Z$/g, ''), 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'))}`)
			pdf.text(pdf.internal.pageSize.getWidth()-margin, actualHeightPadding, timeOrderText, 'right')
			actualHeightPadding+=10

			//Calculates and prints the arrival forecast

			// Adds seconds
			var waitTimeInSeconds = parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('ss'))
			// Adds minutes in seconds
			waitTimeInSeconds += (parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('mm'))*60)
			// Adds hours in seconds
			waitTimeInSeconds += (parseInt(moment(objContent.empresa.wait_time, 'HH:mm:ss').format('HH'))*3600)

			const arrivalForecastText = `Previsão de chegada: ${moment(objContent.pedido.data.replace(/.\d{}3Z$/g, ''), 'YYYY/MM/DDTHH:mm:ss').add(waitTimeInSeconds, 'seconds').format('HH:mm')}`

			pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, arrivalForecastText, 'center')

		}
		else{
			pdf.setFontSize(fontSize*1.4)
			pdf.setFontType('bold')
			const orderNumberText = pdf.splitTextToSize(`${((objContent.empresa.print_title.charAt(0).toUpperCase()+objContent.empresa.print_title.slice(1).toLowerCase()))} ${id}`)
			pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, orderNumberText, 'center')
			pdf.setFontType('normal')
			pdf.setFontSize(fontSize)
		}

		actualHeightPadding += 15

		//Get items from order and fill a table with it
		const itensArray = [].concat(...objContent.pedido.items.map(item=>{
			return [
				[{content: item.name.toUpperCase(), colSpan: 4, styles: { overflow: 'linebreak', fontSize}}],
				[
					{content: ''},
					{content: (Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.quantity)), styles: {minCellWidth: 35, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: (Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.price)), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, halign: 'right'}},
					{content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(item.total), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, halign: 'right'}},
				],
				((item.obs)?[{ content: item.obs, colSpan: 4, styles: {overflow: 'linebreak', fontSize, cellPadding: {top: 0, right: 0, bottom: 4, left:10}}}]:[{content: '', colSpan: 4, styles:{fontSize: 0}, cellPadding: 0}])
			]
		}))


		//Fill table with items
		pdf.autoTable({
			theme: 'plain',
			startY: actualHeightPadding,
			margin: {top: 0, right: margin, bottom: 0, left: margin},
			styles: {fontSize: 14, cellPadding: 2},
			tableWidth: (pdf.internal.pageSize.getWidth()-(margin*2)),
			head: [['DESCRIÇÃO', {content: 'QTD.', styles: {halign: 'right'}}, {content: 'VR. UNIT.', styles: {halign: 'right'}}, {content: 'TOTAL', styles: {halign: 'right'}}]],
			didDrawPage: function (HookData) {
				actualHeightPadding += HookData.table.body.map(row=>{
					return row.height
				}).reduce((a,b)=>{
					return a+b
				}, 0)
			},
			body: [
				...itensArray,
				[{ content: 'TOTAL DOS ITENS', colSpan: 3, styles: {overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.subTotal), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}],
				[{ content: 'FRETE', colSpan: 3, styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.frete), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}],
				[{ content: 'TOTAL', colSpan: 3, styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold'}, cellPadding: {top: 4, right: 0, bottom: 0, left:0}}, {content: Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(objContent.total), styles: {minCellWidth: 60, overflow: 'hidden', fontSize, fontStyle: 'bold', halign: 'right'}}]
			]
		})

		actualHeightPadding+=35

		// Add Obs
		const obsText = pdf.splitTextToSize(`${objContent.pedido.obs}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, obsText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(obsText).h+margin

		//Add client's name
		const clientText = pdf.splitTextToSize(`Cliente: ${objContent.cliente.nome}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, clientText, 'center')

		actualHeightPadding+=pdf.getTextDimensions(clientText).h+margin

		//Add client's phone
		const clientPhoneText = pdf.splitTextToSize(`Telefone Cliente: ${objContent.cliente.telefone}`, pdf.internal.pageSize.getWidth()-(margin*2))
		pdf.text(pdf.internal.pageSize.getWidth()/2, actualHeightPadding, clientPhoneText, 'center')

		//pdf.save(`${objContent.empresa.print_title}_${id}.pdf`)
		var output = pdf.output('blob')

		window.open(URL.createObjectURL(output))
	}
}

export default function(element, objContent, id){

	const print_model = objContent.empresa.print_model

	if(print_model!==3){
		printModel.model80(element, objContent, id)
	}
	else{
		printModel.model58(element, objContent, id)
	}

}
