import React, {useState, useEffect, Suspense} from 'react';
import './style.css';
import InfoHeader from '../../Components/InfoHeader'
import ItemList from '../../Components/ItemList'
import CartModal from '../../Components/CartModal'
import {FiSearch, FiShoppingCart, FiX, FiUser} from 'react-icons/fi'
import api from '../../services/api'
const SearchModal = React.lazy(()=>import('../../Components/SearchModal'))
const UserCadModal = React.lazy(()=>import('../../Components/UserCadModal'))

function Home() {
	const [empresa, setEmpresa] = useState({})
	const [totalBuyed, setToTalBuyed] = useState(0)

	useEffect(()=>{
		api.get('company').then(response=>{
			setEmpresa(response.data)
			document.title=response.data.fantasia
		})

		const itens = JSON.parse(localStorage.getItem('itens'))
		document.querySelector('head').append()
		if(Array.isArray(itens)){
			var totalValue = itens.map(item=>{
						return item.qtd*item.price
				}).reduce((a, b)=>{
						return a+b
				}, 0)
			setToTalBuyed(totalValue)
		}
		else{
			setToTalBuyed(0)
		}
	}, [])

		function openModalCart(){
			const modal = document.querySelector("div#modalCart")
			modal.classList.add('active')
		}

		function closeModalCart(){
			const modal = document.querySelector("div#modalCart")
			modal.classList.remove('active')
		}

		function closeModalSearch(){
			const modal = document.querySelector("div#modalSearch")
			modal.classList.remove('active')
		}

		function closeModalUser(){
			const modal = document.querySelector('div#modalUser')
			modal.classList.remove('active')
		}

		function openSearch(){
			const modal = document.querySelector("div#modalSearch")
			modal.classList.add('active')
		}

		function openUserCad(){
			const modal = document.querySelector("div#modalUser")
			modal.classList.add('active')
		}

	return (
		<div className={empresa.theme}>
			<div className="header">
				<div className={`topMenu`}>
						<div className="container">
								<h1>{empresa.fantasia}</h1>
								<div className="buttons">
									<button>
										<FiUser size={30} color="white" onClick={openUserCad}/>
									</button>
									<button className="searchBtn" onClick={event=>{
										openSearch()
										}}>
										<FiSearch size={30} color="white"/>
									</button>
									<button id="cartIcon" onClick={openModalCart}>
										<FiShoppingCart size={30}/>
										{localStorage.getItem('itens')?<div className="cartCounter">{JSON.parse(localStorage.getItem('itens'))?.length}</div>:<></>}
									</button>
								</div>
						</div>
				</div>
				<div className="banner" style={{marginTop: (empresa?empresa.top_size:0)}}>
					<img src={`./${empresa.banner}`} alt=""/>
				</div>
				<InfoHeader/>
			</div>
			<div className="body">
				<ItemList/>
			</div>
			<div className="footer">
				<div className="footerCtt">
					<div>
						<h1>{empresa.extra_field_title_1}</h1>
						<p>{empresa.extra_field_1}</p>
						<img src={'./'+empresa.extra_image_1} alt=""/>
					</div>
					<div>
						<h1>{empresa.extra_field_title_2}</h1>
						<p>{empresa.extra_field_2}</p>
						<img src={'./'+empresa.extra_image_2} alt=""/>
					</div>
				</div>
			</div>
			<button className="cartBtn" onClick={openModalCart}>
				<div>
				<FiShoppingCart size={30} color="white"/>
				Carrinho
				</div>
				<div className="total">
					{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(totalBuyed)}
				</div>
			</button>
			<div className="modalContent" id="modalCart">
				<div className="modal">
					<CartModal itens={JSON.parse(localStorage.getItem('itens'))} setToTalBuyed={setToTalBuyed} total={totalBuyed}/>
					<button className="close" onClick={closeModalCart}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
			<div className="modalContent" id="modalSearch">
				<div className="modal">
					<Suspense fallback={()=>{}}>
						<SearchModal/>
					</Suspense>
					<button className="close" onClick={closeModalSearch}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
			<div className="modalContent" id="modalUser">
				<div className="modal">
					<Suspense fallback={()=>{}}>
						<UserCadModal/>
					</Suspense>
					<button className="close" onClick={closeModalUser}>
						<FiX size="30" color="#ccc" />
					</button>
				</div>
			</div>
		</div>
	);
}

export default Home;
