import React, {useState, useEffect} from 'react'
import {Editor, EditorState, RichUtils} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import {stateFromHTML} from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import './style.css';
import {FiImage, FiEye, FiX, FiXCircle, FiItalic, FiBold} from 'react-icons/fi'
import api from '../../../services/api'
import AdmListCompositions from './AdmListCompositions'
const moment = require('moment')

function AdmFormAddProduct(props){
	const productAlter = props.productAlter
	const productNew = props.productNew
	const sorted = props.sortedList

	const [loader, setLoader] = useState(false)
	const [id, setid] = useState(props.item?props.item.id:'')
	const [name, setName] = useState(props.item?props.item.name:'')
	const [quantity, setQuantity] = useState(props.item?Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2}).format(props.item.quantity):'')
	const [price, setPrice] = useState(props.item?props.item.price:0)
	const [secondPrice, setSecondPrice] = useState(props.item?props.item.second_price:0)
	const [description, setDescription] = useState(()=>EditorState.createEmpty())
	const [status, setStatus] = useState(props.item?props.item.status:0)
	const [category, setCategory] = useState(props.item?props.item.categoryId:0)
	const [productLocal, setProductLocal] = useState(props.item?props.item.product_id_local:'')
	const [image, setImage] = useState(props.item?`${window.location.origin.toString()}/assets/${props.item.image}`:'')
	const [imageLoading, setImageLoading] = useState(false)
	const [imageExtra1, setImageExtra1] = useState('')
	const [image1Loading, setImage1Loading] = useState(false)
	const [imageExtra2, setImageExtra2] = useState('')
	const [image2Loading, setImage2Loading] = useState(false)
	const [imageExtra3, setImageExtra3] = useState('')
	const [image3Loading, setImage3Loading] = useState(false)
	const [destaque, setDestaque] = useState(21)
	const [composite_product, setCompositeProduct] = useState(0)
	const [composition_type, setCompositeType] = useState(0)
	const [min_composition_itens, setMinCompositionItens] = useState(0)
	const [max_composition_itens, setMaxCompositionItens] = useState(0)
	const [categories, setCategories] = useState([])
	const [imageDisplayed, setImageDisplayed] = useState('')
	const [productLogs, setProductLogs] = useState([])
	const [compositions, setCompositions] = useState([])
	const [enable_selling_decimal, setEnableSellingDecimal] = useState(0)

	//const history=useHistory()

	useEffect(()=>{
		api.get(`/categories/?onlyActives=true`).then(response=>{
			setCategories(response.data)
		})
		if(props.item===null){
			setid('')
			setName('')
			setQuantity(0)
			setPrice(0)
			setSecondPrice(0)
			setDescription(()=>EditorState.createEmpty())
			setProductLocal('')
			setStatus('0')
			setCategory(0)
			setImage('')
			setImageExtra1('')
			setImageExtra2('')
			setImageExtra3('')
			setDestaque(21)
			setCompositeProduct(0)
			setMinCompositionItens(0)
			setMaxCompositionItens(0)
			setCompositeType(0)
			setEnableSellingDecimal(0)
			setProductLogs([])
			setCompositions([])
		}
		else{
			setid(props.item.id)
			setName(props.item.name?props.item.name.substring(0,50):'')
			setQuantity(props.item.quantity)
			setPrice(props.item.price)
			setSecondPrice(props.item.second_price)
			setDescription(EditorState.createWithContent(stateFromHTML(props.item.description)))
			setProductLocal(props.item.product_id_local)
			setImage(()=>{
				if(props.item.image){
					return `${window.location.origin.toString()}/assets/${props.item.image}`
				}
				else{
					return ''
				}
				})
			setStatus(props.item.status)
			setCategory(props.item.categoryId)
			props.item.extraImages[0]?setImageExtra1(`${window.location.origin.toString()}/assets/${props.item.extraImages[0].image}`):setImageExtra1('')
			props.item.extraImages[1]?setImageExtra2(`${window.location.origin.toString()}/assets/${props.item.extraImages[1].image}`):setImageExtra2('')
			props.item.extraImages[2]?setImageExtra3(`${window.location.origin.toString()}/assets/${props.item.extraImages[2].image}`):setImageExtra3('')
			setDestaque(props.item.destaque)
			setCompositeProduct(props.item.composite_product)
			setMinCompositionItens(props.item.min_composition_itens)
			setMaxCompositionItens(props.item.max_composition_itens)
			setCompositeType(props.item.composition_type)
			setCompositions(props.item.compositions)
			setEnableSellingDecimal(props.item.enable_selling_decimal)

			api.get(`/products/logging/${id}`).then(response=>{
				setProductLogs(response.data)
			})
		}
		const input = document.querySelector('input#name')
		input.focus()
		input.select()
	}, [props, id])

	function getImageBase64(file){
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		})
	}


	function showLogging(event){
		const modal = document.querySelector('#modalTableLog')

		api.get(`/products/logging/${id}`).then(response=>{
			setProductLogs(response.data)
			modal.classList.add('active')
		})
	}

	function changePhoto(event){
		const input = event.target
		getImageBase64(input.files[0]).then(data=>{
			setImageLoading(true)
			const auth = localStorage.getItem('auth')
			const imageFileName = data
			const imageCtn = document.querySelector('img#imageDisplay')
			const label = input.closest('.fotoContainer').querySelector('.inputFileShowed')
			imageCtn.classList.add('displayedImage')
			setImage(imageFileName)
			if(label){
				label.classList.add('overImage')
			}
			api.post('products/default/image/add', {
				id,
				name,
				image: imageFileName
			}, {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				productAlter({
					...props.item,
					image: response.image
				})
				setImageLoading(false)
			}).catch(error=>{
				setImageLoading(false)
				alert(`Não é possível adicionar imagem: ${error}`)
			})
		})
	}

	function changePhotoExtra(input, idImage, nextFunction, loadingFunction){
		const auth = localStorage.getItem('auth')
		const imageName = input.files[0].name
		getImageBase64(input.files[0]).then(data=>{
			loadingFunction(true)
			const imageData = data
			const imageCtn = input.closest('div.extraImage').querySelector('img#imageDisplay')
			const label = input.closest('div.extraImage').querySelector('.inputFileShowed')
			nextFunction(imageData)
			if(label){
				label.classList.add('overImage')
			}
			imageCtn.classList.add('displayedImage')
			api.post('products/images/add', {
				id: id,
				index: idImage,
				imageData,
				imageName
			}, {
				headers: {
					'auth': auth
				}
			}).then(()=>{
				loadingFunction(false)
			}).catch(error=>{
				loadingFunction(false)
				alert(`Não é possível adicionar imagem: ${error}`)
			})
		})
	}

	function handleSave(event){
		event.preventDefault()
		setLoader(true)
		const auth = localStorage.getItem('auth')
		if(id===''){

			api.post('products/add/', {
				name,
				description: stateToHTML(description.getCurrentContent()).replace("'", '&quot;'),
				reference: productLocal,
				quantity,
				price,
				second_price: secondPrice,
				composite_product,
				composition_type,
				min_composition_itens,
				max_composition_itens,
				status,
				category,
				destaque,
				enable_selling_decimal
			}, {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				setLoader(false)
				alert(`Produto ${response.data.id} salvo com sucesso!`)
				response.data.product_id_local=productLocal
				if(categories.filter(categoryFiltered=>parseInt(categoryFiltered.id)===parseInt(response.data.categoryId))[0]){
					response.data['category.name']=categories.filter(categoryFiltered=>parseInt(categoryFiltered.id)===parseInt(response.data.categoryId))[0].name
				}
				productNew(response.data)
				if(window.confirm('Deseja continuar editando o item?')){
					setid(response.data.id)
				}
				else{
					closeModal(event)
				}
			}).catch(error=>{
				if(error.response){
					setLoader(false)
					alert(`Erro ao inserir produto: ${error.response.status}\n${error.response.data.message}`)
				}
				else{
					setLoader(false)
					alert(error)
				}
			})
		}
		else{
			api.post('products/add/', {
				id: id,
				name,
				description: stateToHTML(description.getCurrentContent()).replace("'", '&quot;'),
				reference: productLocal,
				quantity,
				price,
				second_price: secondPrice,
				composite_product,
				min_composition_itens,
				max_composition_itens,
				composition_type,
				status,
				category,
				destaque,
				enable_selling_decimal,
				image: ((/^https?:\/\//g).test(image))?'':image
			}, {
				headers: {
					'auth': auth
				}
			}).then(response=>{
				setLoader(false)
				alert(`Produto ${response.data.id} salvo com sucesso!`)
				response.data.product_id_local=productLocal
				if(categories.filter(categoryFiltered=>parseInt(categoryFiltered.id)===parseInt(response.data.categoryId))[0]){
					response.data['category.name']=categories.filter(categoryFiltered=>parseInt(categoryFiltered.id)===parseInt(response.data.categoryId))[0].name
				}
				productAlter(response.data, sorted)
				closeModal(event)
			}).catch(error=>{
				if(error.response){
					setLoader(false)
					alert(`Erro ao alterar produto: ${error.response.status}\n${error.response.data.message}`)
				}
				else{
					setLoader(false)
					alert(error+"here")
				}
			})
		}
	}

	function closeModal(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalProductCadForm")
		modal.classList.remove('active')
	}

	function closeModalLog(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalTableLog")
		modal.classList.remove('active')
	}

	function closeModalComposition(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalCompositionContainer")
		modal.classList.remove('active')
	}

	function seePictureInModal(event){
		event.preventDefault()
		if(image){
			setImageDisplayed(image)
			const modal = document.querySelector("div#modalImage")
			modal.classList.add('active')
		}
	}

	function seeComplPicInModal(event, imageToDisplay){
		event.preventDefault()
		if(imageToDisplay){
			setImageDisplayed(imageToDisplay)
			const modal = document.querySelector("div#modalImage")
			modal.classList.add('active')
		}
	}

	function closeModalImage(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalImage")
		modal.classList.remove('active')
	}

	function deletePic(event){
		const auth = localStorage.getItem('auth')
		event.preventDefault()
		if(id){
			api.delete(`/products/image/delete/${id}`, {
				headers: {
					'auth': auth
				}
			})
		}
		setImage('')
	}

	function deleteComplementaryPic(event, number, next){
		const auth = localStorage.getItem('auth')
		event.preventDefault()
		if(id){
			api.delete(`/products/image/delete/${id}/comp/${number}`, {
				headers: {
					'auth': auth
				}
			})
		}
		next('')
		if(props.item) props.item.extraImages[(number-1)]=''
	}

	function deleteDeleteComplement(event){
		event.preventDefault()
		const auth = localStorage.getItem('auth')

		const btnClick =  event.target
		const compositionId = btnClick.closest(`tr`).dataset.id

		api.delete(`/product/composition/${compositionId}`, {
			headers: {
				auth
			}
		}).then(()=>{
			setCompositions(compositions.filter(c=>parseInt(c.id)!==parseInt(compositionId)))
		})
	}

	function checkLineComp(event){
		event.preventDefault()

		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		if(table){
			var trItens = table.querySelectorAll('tbody tr')

			trItens = Array.from(trItens)

			trItens.forEach((item)=>{
				if(item.querySelector('input').checked){
					item.classList.add('selected')
				}
				else{
					item.classList.remove('selected')
				}
			})
		}
	}

	function createComplement(event){
		event.preventDefault()

		const modal = document.querySelector("div#modalCompositionContainer")
		modal.classList.add('active')
	}

	function saveCompositionItemCallback(newComposition){

		setCompositions(compositions=>[...compositions, newComposition])
	}

	return (
		<form action="" onSubmit={handleSave} className="prodForm">
			{loader?<div className="loaderProduct">
				<div className="loader"></div>
			</div>:<></>}
			<div className="containerAddForm">
				<div>
					<div className="fotoContainer">
						{imageLoading?<div className="loadingContainer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:10, width: '100%', height: '100%', position: 'absolute', background: '#50505055', borderRadius: '5px'}}>
							<div className="loading"></div>
						</div>:<></>}
						<img id="imageDisplay" className={image?"displayedImage":""} src={`${image}`} alt=""/>
						{(!imageLoading)?<label htmlFor="imageFile" className={image?"inputFileShowed overImage":"inputFileShowed"} onMouseEnter={event=>{
							event.target.classList.add('hovered')
						}} onMouseLeave={event=>{
							event.target.classList.remove('hovered')
						}}>
							<FiImage size={60}/>
							{props.item?"Alterar foto":"Adicionar foto"}
						</label>:<></>}
						<input type="file" id="imageFile" className="inputFileHidden" value="" onChange={changePhoto} accept=".jpg,.png"/>
					</div>
					<button type="button" className="seePicture" onClick={seePictureInModal}>
						<FiEye size={16} color="white"/>
					</button>
					<button type="button" className="deletePic" onClick={deletePic}>
						<FiX size={16} color="white"/>
					</button>
					<div className="obsFoto" style={{paddingRight:0,paddingLeft:0}}>
						<p className="title">Observações:</p>
						<br/>
						<p>Máximo:</p>
						<p>Dimensões: 400x400</p>
						<p>Tam. do arq.: 100kb</p>
						<br/>
						<p>Recomendável:</p>
						<p>Dimensões: 300x300</p>
						<p>Tam. do arq.: 50kb</p>
					</div>
					<div className="input-group">
						<select id="destaque" value={destaque} onChange={event=>setDestaque(event.target.value)}>
							<option value={21}>Sem destaque</option>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4}>4</option>
							<option value={5}>5</option>
							<option value={6}>6</option>
							<option value={7}>7</option>
							<option value={8}>8</option>
							<option value={9}>9</option>
							<option value={10}>10</option>
							<option value={11}>11</option>
							<option value={12}>12</option>
							<option value={13}>13</option>
							<option value={14}>14</option>
							<option value={15}>15</option>
							<option value={16}>16</option>
							<option value={17}>17</option>
							<option value={18}>18</option>
							<option value={19}>19</option>
							<option value={20}>20</option>
						</select>
						<label htmlFor="destaque">Produto em destaque</label>
					</div>
					<div className="input-group">
						<select name="status" id="status" value={status} onChange={event=>setStatus(event.target.value)}>
							<option value="1">Ativo</option>
							<option value="0">Inativo</option>
						</select>
						<label htmlFor="status">Status</label>
					</div>
					<div className="input-group" style={{display: 'grid', gridTemplateColumns: '25px 1fr', gridColumnStart: 2, gridColumnEnd: 4, justifyContent: 'center', alignItems: 'center'}}>
						<input type="checkbox" name="checkSellDecimal" id="checkSellDecimal" checked={parseInt(enable_selling_decimal)===1} onChange={event=>setEnableSellingDecimal(event.target.checked?1:0)}/>
						<label htmlFor="checkSellDecimal">Venda com decimais</label>
					</div>
					<div className="input-group" style={{display: 'grid', gridTemplateColumns: '25px 1fr', gridColumnStart: 2, gridColumnEnd: 4, justifyContent: 'center', alignItems: 'center'}}>
						<input name="checkComposition" checked={(composite_product===1)} onChange={event=>{
							setCompositeProduct(event.target.checked?1:0)
							const formProd = document.querySelector('form.prodForm')
							formProd.scrollTop = formProd.scrollHeight
						}} id="checkComposition" type="checkbox" style={{padding: 0, margin: 0}}/>
						<label htmlFor="checkComposition">Item adicional</label>
					</div>
					<div className="input-group">
						<select disabled={(composite_product!==1)} value={composition_type} onChange={event=>setCompositeType(event.target.value)} name="" id="">
							<option value={0}>Soma</option>
							<option value={1}>Maior valor</option>
							<option value={2}>Média</option>
						</select>
						<label htmlFor="selectCompositeType">Regra de cálc.</label>
					</div>
					<div className="logging">
						<button type="button" onClick={showLogging}>Logs</button>
					</div>
				</div>
				<div className="input-form-container">
					<div className="input-group spaced">
						<input id="name" type="text" value={name} required="true" onChange={event=>setName(event.target.value)} maxLength={50}/>
						<label htmlFor="name">Descrição</label>
					</div>
					<div className="input-group spaced">
						<select name="category" id="category" value={category} onChange={event=>setCategory(event.target.value)}>
							<option value={0}>Nenhuma</option>
							{categories.map(category=>{
								return <option value={category.id}>{category.name}</option>
							})}
						</select>
						<label htmlFor="">Categoria</label>
					</div>
					<div className="input-group spaced">
						<Editor editorState={description} onChange={editorState=>{
							setDescription(editorState)
						}}/>
						<div className="optionsEditorDraft">
							<button  onClick={event=>{
								event.preventDefault()
								setDescription(RichUtils.toggleInlineStyle(description,'ITALIC'))
							}}><FiItalic/></button>
							<button onClick={event=>{
								event.preventDefault()
								setDescription(RichUtils.toggleInlineStyle(description,'BOLD'))
							}}><FiBold/></button>
						</div>
						<label htmlFor="">Detalhamento</label>
					</div>
					<div className="input-group">
						<input type="text" value={Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(quantity)} required="true" onChange={event=>setQuantity(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
						<label htmlFor="">Quant.</label>
					</div>
					<div className="input-group">
						<input type="text" value={Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(price)} required="true" onChange={event=>setPrice(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
						<label htmlFor="">Valor</label>
					</div>
					<div className="input-group">
						<input type="text" value={Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(secondPrice)} onChange={event=>setSecondPrice(parseFloat(event.target.value.replace(/[^\d]/g, '').padStart(3,'0').replace(/(\d{2}$)/, '.$1')))}/>
						<label htmlFor="">Preço promocional</label>
					</div>
					<div className="input-group">
						<input type="text" value={productLocal} onChange={event=>setProductLocal(event.target.value.replace(/\D/g, ''))}/>
						<label htmlFor="">Cod. Ref.</label>
					</div>
					<div className="spaced">
						<label htmlFor="">Fotos extras: </label>
						<div className="extraImages">

							<div>
								<div className="extraImage" id="extraImg1">
									{image1Loading?<div className="loadingContainer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:10, width: '100%', height: '100%', position: 'absolute', background: '#50505055', borderRadius: '5px'}}>
								<div className="loading"></div>
							</div>:<></>}
									<img id="imageDisplay" className={imageExtra1?"displayedImage":""} src={`${imageExtra1}`} alt=""/>
									{!image1Loading?<label htmlFor="imageFileExtra1" onClick={()=>{
										if(!id){
											alert("Não é possivel inserir imagens extras em produto não salvo!")
										}
									}} className={imageExtra1?"inputFileShowed overImage"+(!id?' disabled':''):"inputFileShowed"+(!id?' disabled':'')} onMouseEnter={event=>{
								event.target.classList.add('hovered')
							}} onMouseLeave={event=>{
								event.target.classList.remove('hovered')
							}}>
										<FiImage size={60}/>
										{imageExtra1?"Alterar Foto":"Adicionar foto"}
									</label>:<></>}
									<input type="file" id="imageFileExtra1" disabled={!id} className="inputFileHidden" value="" onChange={(event)=>{
										event.preventDefault()
										changePhotoExtra(event.target, 1, setImageExtra1, setImage1Loading)
									}} accept=".jpg,.png"/>
								</div>
								<button type="button" className="seePicture" onClick={event=>{
									seeComplPicInModal(event, imageExtra1)
								}}>
									<FiEye size={16} color="white"/>
								</button>
								<button type="button" className="deletePic" onClick={(event)=>{
									deleteComplementaryPic(event, 1, setImageExtra1)
								}}>
									<FiX size={16} color="white"/>
								</button>
							</div>
							<div>
								<div className="extraImage" id="extraImg2">
									{image2Loading?<div className="loadingContainer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:10, width: '100%', height: '100%', position: 'absolute', background: '#50505055', borderRadius: '5px'}}>
									<div className="loading"></div>
								</div>:<></>}
									<img id="imageDisplay" className={imageExtra2?"displayedImage":""} src={`${imageExtra2}`} alt=""/>
									{!image2Loading?<label htmlFor="imageFileExtra2" onClick={()=>{
										if(!id){
											alert("Não é possivel inserir imagens extras em produto não salvo!")
										}
									}} className={imageExtra2?"inputFileShowed overImage"+(!id?' disabled':''):"inputFileShowed"+(!id?' disabled':'')} onMouseEnter={event=>{
								event.target.classList.add('hovered')
							}} onMouseLeave={event=>{
								event.target.classList.remove('hovered')
							}}>
										<FiImage size={60}/>
										{imageExtra2?"Alterar Foto":"Adicionar foto"}
									</label>:<></>}
									<input type="file" id="imageFileExtra2" disabled={!id} className="inputFileHidden" value="" onChange={(event)=>{
										event.preventDefault()
										changePhotoExtra(event.target, 2, setImageExtra2, setImage2Loading)
									}} accept=".jpg,.png"/>
								</div>
								<button type="button" className="seePicture" onClick={event=>{
									seeComplPicInModal(event, imageExtra2)
								}}>
									<FiEye size={16} color="white"/>
								</button>
								<button type="button" className="deletePic" onClick={(event)=>{
									deleteComplementaryPic(event, 2, setImageExtra2)
								}}>
									<FiX size={16} color="white"/>
								</button>
							</div>
							<div>
								<div className="extraImage" id="extraImg3">
									{image3Loading?<div className="loadingContainer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:10, width: '100%', height: '100%', position: 'absolute', background: '#50505055', borderRadius: '5px'}}>
										<div className="loading"></div>
									</div>:<></>}
									<img id="imageDisplay" className={imageExtra3?"displayedImage":""} src={`${imageExtra3}`} alt=""/>
									{!image3Loading?<label htmlFor="imageFileExtra3" onClick={()=>{
										if(!id){
											alert("Não é possivel inserir imagens extras em produto não salvo!")
										}
									}} className={imageExtra3?"inputFileShowed overImage"+(!id?' disabled':''):"inputFileShowed"+(!id?' disabled':'')} onMouseEnter={event=>{
								event.target.classList.add('hovered')
							}} onMouseLeave={event=>{
								event.target.classList.remove('hovered')
							}}>
										<FiImage size={60}/>
										{imageExtra3?"Alterar Foto":"Adicionar foto"}
									</label>:<></>}
									<input type="file" id="imageFileExtra3" disabled={!id} className="inputFileHidden" value="" onChange={(event)=>{
										event.preventDefault()
										changePhotoExtra(event.target, 3, setImageExtra3, setImage3Loading)
									}} accept=".jpg,.png"/>
								</div>
								<button type="button" className="seePicture" onClick={event=>{
									seeComplPicInModal(event, imageExtra3)
								}}>
									<FiEye size={16} color="white"/>
								</button>
								<button type="button" className="deletePic" onClick={(event)=>{
									deleteComplementaryPic(event, 3, setImageExtra3)
								}}>
									<FiX size={16} color="white"/>
								</button>
							</div>
						</div>
					</div>
					<div style={{...((composite_product===1)?{}:{display: 'none'})}} className="spaced">
						<div className="headerComposition">
							<div className="minMax">
								<div className="input-group">
									<input type="text" value={min_composition_itens} onChange={event=>setMinCompositionItens(parseInt(event.target.value.replace(/\D/g, '').padStart(1, '0')))} disabled={((composite_product!==1)||(parseInt(composition_type)===0))}/>
									<label htmlFor="">Minimo</label>
								</div>
								<div className="input-group">
									<input type="text" value={max_composition_itens} onChange={event=>setMaxCompositionItens(parseInt(event.target.value.replace(/\D/g, '').padStart(1, '0')))} disabled={((composite_product!==1)||(parseInt(composition_type)===0))}/>
									<label htmlFor="">Máximo</label>
								</div>
							</div>
							<div className="btnsItens">
								<button onClick={createComplement} className="utilityBtns">Novo</button>
							</div>
						</div>
						<table class="admItens" id="tableItensProductComposition">
							<thead>
								<tr>
									<th className="checkbox" style={{display: "none"}}></th>
									<th>Name</th>
									<th>Valor</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{compositions.map(c=>{
									return (
									<tr key={c.id} data-id={c.id} onClick={checkLineComp}>
										<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={c.id}/></td>
										<td>{c.composition_item.name}</td>
										<td>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(c.composition_item.value)}</td>
										<td><button className="deleteBtn" onClick={deleteDeleteComplement}><FiXCircle size={16} color="red"/></button></td>
									</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="footerAddForm">
				<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
				<button type="submit" className="save">Salvar</button>
			</div>
			<div className="modalContent" id="modalImage">
				<div className="divModalImage">
					<img src={imageDisplayed} alt=""/>
					<button className="close" style={{mixBlendMode:'difference',opacity:'100%',cursor:'pointer',position:'absolute',top:'2px',right:'2px'}} onClick={closeModalImage}>
						<FiX size={30}/>
					</button>
				</div>
			</div>
			<div className="modalContent" id="modalCompositionContainer">
				<div className="modal" id="modalComposition">
					<AdmListCompositions selectCompositionCallBack={saveCompositionItemCallback} productId={id} closeMySelf={closeModalComposition}/>
					<button className="close" onClick={closeModalComposition}>
						<FiX size={30} color="#ccc"/>
					</button>
				</div>
			</div>
			<div className="modalContent" id="modalTableLog">
				<div className="modal" id="modalLog">
					<div className="titleTableLog">
						Produto {id}
					</div>
					<div className="bodyModalLog">
						<table className="tableLog">
							<thead>
								<tr>
									<th>Id</th>
									<th>Campos</th>
									<th>Ip</th>
									<th>Data</th>
									<th>Hora</th>
								</tr>
							</thead>
							<tbody>
								{productLogs.map(log=>{
									return (
										<tr>
											<td>{log.id}</td>
											<td>{log.fields}</td>
											<td>{log.ip}</td>
											<td>{moment(log.createdAt).format('DD/MM/YYYY')}</td>
											<td>{moment(log.createdAt).format('HH:mm')}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<button className="close" onClick={closeModalLog}>
						<FiX size={30} color="white"/>
					</button>
				</div>
			</div>
		</form>
	)
}
export default AdmFormAddProduct
