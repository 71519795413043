import React, {useState, useEffect} from 'react'
import api from '../../../services/api'
import './style.css'

function EditCustomerGroup({group}){

	const [group_name, setGroupName] = useState((group?group.group_name:''))
	const [second_price, setSecondPrice] = useState((group?group.second_price:1))
	const [status, setStatus] = useState((group?group.status:1))
	const [loading, setLoading] = useState()

	const auth = localStorage.getItem('auth')

	useEffect(()=>{
		if(group){
			setGroupName(group.group_name)
			setSecondPrice(group.second_price)
			setStatus(group.status)
		}
		else{
			setGroupName('')
			setSecondPrice(0)
			setStatus(0)
		}
	}, [group])

	function saveGroup(event){
		event.preventDefault()
		setLoading(true)

		api.post('/customerGroup/', {
			...(group?{id: group.id}:{}),
			group_name,
			second_price,
			status
		}, {
			headers: {
				'auth': auth
			}
		}).then(response=>{
			alert(`Grupo de cliente ${response.data.id} salvo com sucesso!`)
			window.location.reload()
		})
		.catch((error)=>{
			setLoading(false)
			alert(`Erro ao salvar grupo: ${error}`)
		})
	}

	return (
		<>
		{loading?<div className="loaderModal">
			<div className="spinner"></div>
		</div>:<></>}
		<form action="" onSubmit={saveGroup}>
			<h1 id="titleGroup">Editar Grupo de Cliente</h1>
			<div className="groupBody">
				<div className="input-group">
					<input type="text" id="groupName" value={group_name} required onChange={event=>setGroupName(event.target.value)}/>
					<label htmlFor="groupName">Nome</label>
				</div>
				<div className="input-group">
					<select id="groupSecondPrice" value={second_price} required onChange={event=>setSecondPrice(event.target.value)}>
						<option value={1}>Sim</option>
						<option value={0}>Não</option>
					</select>
					<label htmlFor="groupSecondPrice">Preço promoç.</label>
				</div>
				<div className="input-group">
					<select id="groupSecondPrice" value={status} required onChange={event=>setStatus(event.target.value)}>
						<option value={1}>Ativo</option>
						<option value={0}>Inativo</option>
					</select>
					<label htmlFor="groupSecondPrice">Status</label>
				</div>
			</div>
			<div className="buttonsModal">
				<button className="cancelBtn" onClick={(event)=>{
					const modal = document.querySelector("div#modalEditCustomerGroup")
					modal.classList.remove('active')
					event.preventDefault()
				}}>Cancelar</button>
				<button className="saveBtn">Salvar</button>
			</div>
		</form>
		</>
	)
}

export default EditCustomerGroup
