import React, {useState, useEffect} from 'react'
import api from '../../../services/api'
import {FiImage, FiEye, FiX} from 'react-icons/fi'
import './style.css'

function AdmFormAddNewCategory(props){
	const item = props.item

	const [id, setId] = useState(item?item.id:'')
	const [name, setName] = useState(item?item.name:'')
	const [status, setStatus] = useState(item?item.status:0)
	const [loading, setLoading] = useState(false)
	const [image, setImage] = useState('')
	useEffect(()=>{
		if(item){
			setId(item.id)
			setName(item.name)
			setStatus(item.status)
			setImage(item.image?`${window.location.origin.toString()}/assets/categories/${item.image}`:'')
		}
		else{
			setId(undefined)
			setName('')
			setStatus(0)
			setImage(``)
		}
	}, [item])

	async function handleSave(event){
		const auth = localStorage.getItem('auth')
		event.preventDefault()
		setLoading(true)

		try{
			var response = ''
			if(item){
				response = await api.post('/categories/add', {
					id,
					name,
					status,
					image: ((/^https?:\/\//g).test(image))?'':image
				}, {
					headers: {
						'auth': auth
					}
				})
			}
			else{
				response = await api.post('/categories/add', {
					name,
					status,
					image: ((/^https?:\/\//g).test(image))?'':image
				}, {
					headers: {
						'auth': auth
					}
				})
			}

			setLoading(false)
			alert(`Categoria ${response.data.id} salvo com sucesso!`)

			window.location.reload()
		}
		catch(error){
			setLoading(false)
			alert(`Erro ao salvar categoria: ${error.message}`)
		}
	}
	function getImageBase64(file){
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		})
	}

	function changePhoto(event){
		const input = event.target
		getImageBase64(input.files[0]).then(data=>{
			const imageFileName = data
			const imageCtn = document.querySelector('img#imageDisplay')
			const label = input.closest('.imgContainer').querySelector('.inputFileShowed')
			imageCtn.classList.add('displayedImage')
			setImage(imageFileName)
			label.classList.add('overImage')
		})
	}

	function closeModalImage(event){
		event.preventDefault()
		const modal = document.querySelector("div#modalImage")
		modal.classList.remove('active')
	}

	function deletePhoto(event){
		const auth = localStorage.getItem('auth')
		event.preventDefault()
		if(id){
			api.delete(`/categories/image/delete/${id}`, {
				headers: {
					'auth': auth
				}
			}).then().catch(error=>{
				alert(error.response.data.message)
			})
		}
		setImage('')
	}

	function seePictureInModal(event){
		event.preventDefault()
		if(image){
			const modal = document.querySelector("div#modalImage")
			modal.classList.add('active')
		}
	}

	return (
		<>
			{loading?<div className="loaderModal">
				<div className="spinner"></div>
			</div>:<></>}
			<div className="headerCategory">
				<h1>Categoria</h1>
			</div>
			<form action="" onSubmit={handleSave}>
				<div className="containerFormCategory">
					<div className="left">
						<div className="imgContainer">
						<img id="imageDisplay" className={image?"displayedImage":""} src={`${image}`} alt=""/>
						<label htmlFor="imageFile" className={image?"inputFileShowed overImage":"inputFileShowed"} onMouseEnter={event=>{
							event.target.classList.add('hovered')
						}} onMouseLeave={event=>{
							event.target.classList.remove('hovered')
						}}>
							<FiImage size={60}/>
							{image?"Alterar foto":"Adicionar foto"}
						</label>
						<input type="file" id="imageFile" className="inputFileHidden" value="" onChange={changePhoto} accept=".jpg,.png"/>
						</div>
						<button type="button" className="seePicture" onClick={seePictureInModal}>
							<FiEye size={16} color="white"/>
						</button>
						<button className="deletePic" onClick={deletePhoto}>
							<FiX size={16} color="white"/>
						</button>
					</div>
					<div className="formCategory">
						<div className="input-group">
							<input type="text" value={name} onChange={event=>setName(event.target.value)}/>
							<label htmlFor="">Descrição</label>
						</div>
						<div className="input-group">
							<select name="status" id="status" value={status} onChange={event=>setStatus(event.target.value)}>
								<option value={1}>Ativo</option>
								<option value={0}>Inativo</option>
							</select>
							<label htmlFor="status">Status</label>
						</div>
					</div>
				</div>
				<div className="modalContent" id="modalImage">
					<div className="divModalImage">
						<img src={image} alt=""/>
						<button className="close" style={{mixBlendMode:'difference',opacity:'100%',cursor:'pointer',position:'absolute',top:'2px',right:'2px'}} onClick={closeModalImage}>
							<FiX size="30" color="white" />
						</button>
					</div>
				</div>
				<div className="footerAddForm">
					<button type="button" className="cancel" onClick={(event)=>{
					const modal = document.querySelector("div#modalCatCadForm")
					modal.classList.remove('active')
					event.preventDefault()
				}}>Cancelar</button>
					<button type="submit" className="save">Salvar</button>
				</div>
			</form>
		</>
	)
}

export default AdmFormAddNewCategory
