import React, {useState, useEffect} from 'react';
import './style.css';
import api from '../../services/api'
import {FiSearch, FiX, FiXCircle} from 'react-icons/fi'
import printList from '../../generalFunctions/printTableAdm'
import MenuToggleAdmin from '../../Components/menuToggleAdmin'

import {FaBars} from 'react-icons/fa'
function Admin_dash(){

	const [searchTerm, setSearchTerm] = useState('')
	const [entregadores, setEntregadores] = useState([])
	const [editedItem, setEditedItem] = useState()

	const auth = localStorage.getItem('auth')


	useEffect(()=>{
		api.get(`/deliveryman/?searchTerm=${searchTerm}`, {
			headers: {
				'auth': auth
			}
		}).then(response=>{
			setEntregadores(response.data)
		}).catch(error=>{
			if(error.response.data){
				alert(`${error.response.data.message}`)
			}
			else{
				alert(error)
			}
		})
	}, [searchTerm, auth])

	function closeModal(event){
		const modal = event.target.closest("div.modalContent")
		modal.classList.remove('active')
	}

	function checkLine(event){
		const tr = event.target.closest('tr')
		const checkbox = tr.querySelector('input')

		if(!checkbox.checked){
			checkbox.checked=true
		}

		const table = tr.closest('table.admItens')

		var trItens = table.querySelectorAll('tbody tr')

		trItens = Array.from(trItens)

		trItens.forEach((item)=>{
			if(item.querySelector('input').checked){
				item.classList.add('selected')
			}
			else{
				item.classList.remove('selected')
			}
		})
	}

	function newItem(){
		setEditedItem('')

		const modal = document.querySelector("div#modalEditDeliveryMan")
		modal.classList.add('active')
	}

	function editItem(){
		const input = document.querySelector('table tr td input:checked')

		if(input){
			const id = input.dataset.id
			api.get(`/deliveryman/${id}`, {
				headers: {
					'auth': auth
				}
			}).then((response=>{
				setEditedItem(response.data)
				const modal = document.querySelector("div#modalEditDeliveryMan")
				modal.classList.add('active')
			})).catch(error=>{
				if(error.response){
					alert(`${error.response.data.message}`)
				}
				else{
					alert(`${error}`)
				}
			})
		}
		else{
			alert("Nenhum item selecionado!")
		}
	}

	function EditItem({item}){
		const [id] = useState(item?item.id:'')
		const [nome, setNome] = useState(item?item.nome:'')
		const [telefone, setTelefone] = useState(item?item.telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3'):'')
		const [whatsapp, setWhatsapp] = useState(item?item.whatsapp.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3'):'')
		const [loading, setLoading] = useState(false)
		const [endereco, setEndereco] = useState(item?item.endereco:'')
		const [obs, setObs] = useState(item?item.obs:'')

		function handleSaveEntregador(event){
			setLoading(true)
			event.preventDefault()
			if(id){
				api.post('/deliveryman/', {
					id,
					nome,
					telefone: telefone.replace(/\D/g, ''),
					whatsapp: whatsapp.replace(/\D/g, ''),
					endereco,
					obs
				}, {
					headers: {
						'auth': auth
					}
				}).then(response=>{
					setLoading(false)
					const selectedItem = entregadores.filter(entregador=>parseInt(entregador.id)===parseInt(id))
					if(selectedItem){
						setEntregadores(entregadores=>{
							return entregadores.map(entregador=>{
								return (parseInt(entregador.id)===parseInt(id))?response.data:entregador
							})
						})
					}
				}).catch(error=>{
					setLoading(false)
					alert(`Erro ao atualizar entregador: ${(error.response)?error.response.data.message:error}`)
					return false
				})
			}
			else{
				api.post('/deliveryman/', {
					nome,
					telefone,
					whatsapp,
					endereco,
					obs
				}, {
					headers: {
						'auth': auth
					}
				}).then(response=>{
					setLoading(false)
					setEntregadores([...entregadores, response.data])
				}).catch(error=>{
					setLoading(false)
					alert(`Erro ao cadastrar entregador: ${(error.response)?error.response.data.message:error}`)
					return false
				})
			}
			closeModal(event)
		}

		return (<>
			{loading?<div className="loaderModal">
				<div className="spinner"></div>
			</div>:<></>}
			<div className="headerCategory">
				<h1>Cadastro de entregador</h1>
			</div>
			<form action="" onSubmit={handleSaveEntregador}>
				<div className="formCadEntregador">
					<div className="input-group fullsized">
						<input type="text" value={nome} required={true} onChange={event=>setNome(event.target.value)}/>
						<label htmlFor="">Nome</label>
					</div>
					<div className="input-group">
						<input type="telefone" pattern="\(\d{2}\) \d{4,5}-\d{4}" value={telefone} onChange={event=>setTelefone(event.target.value.replace(/\D/g, '')
						.replace(/^(\d{2})$/g, '($1')
						.replace(/^(\d{2})(\d{1,4})$/g, '($1) $2')
						.replace(/^(\d{2})(\d{1,4})(\d{1,4})$/g, '($1) $2-$3')
						.replace(/^(\d{2})(\d{5})(\d{1,4})$/g, '($1) $2-$3'))}
						maxLength={15}/>
						<label htmlFor="">Telefone</label>
					</div>
					<div className="input-group">
						<input type="whatsapp" pattern="\(\d{2}\) \d{4,5}-\d{4}" required={true} value={whatsapp} onChange={event=>setWhatsapp(event.target.value.replace(/\D/g, '')
						.replace(/^(\d{2})$/g, '($1')
						.replace(/^(\d{2})(\d{1,4})$/g, '($1) $2')
						.replace(/^(\d{2})(\d{1,4})(\d{1,4})$/g, '($1) $2-$3')
						.replace(/^(\d{2})(\d{5})(\d{1,4})$/g, '($1) $2-$3'))}
						maxLength={15}/>
						<label htmlFor="">Whatsapp</label>
					</div>
					<div className="input-group fullsized">
						<input type="text" value={endereco} required={true} onChange={event=>setEndereco(event.target.value)}/>
						<label htmlFor="">Endereço</label>
					</div>
					<div className="input-group fullsized">
						<textarea name="observacao" id="observacao" value={obs} onChange={event=>setObs(event.target.value)}/>
						<label htmlFor="observacao">Observação</label>
					</div>
				</div>
				<div className="footerAddForm">
					<button type="button" className="cancel" onClick={closeModal}>Cancelar</button>
					<button type="submit" className="save">Salvar</button>
				</div>
			</form>
		</>)
	}

	async function deleteItem(event){
		const clicked = event.target

		const id = clicked.closest('tr').querySelector('input').dataset.id

		const auth = localStorage.getItem('auth')

		if(window.confirm(`Deletar entregador ${id}?`)){
			try{
				await api.delete(`/deliveryman/${id}`, {
					headers: {
						'auth': auth
					}
				})
				setEntregadores(entregadores.filter(entregador=>parseInt(entregador.id)!==parseInt(id)))
				alert(`Entregador ${id} deletado com sucesso!`)
			}
			catch(error){
				alert(`Erro ao deletar entregador ${id}: ${error}!`)
			}
		}
	}

	function toggleMenuActive(){
		const menuContainer = document.querySelector('aside.menu')

		menuContainer.classList.toggle('active')
	}

	return (
		<>
		<div className="pageCompleteWithMenu">
		<aside className="menu active">
			<MenuToggleAdmin/>
		</aside>
		<div className="headerMenuAdm">
				<div className="containerAdmin">
					<div className="openMenu" onClick={toggleMenuActive}>
						<FaBars size={30} color="white"/>
					</div>
				</div>
		</div>
		<div className="content">
		<div className="body admBody" style={{marginTop: '50px'}}>
		<div className="headerAdmPage">
			<div className="header">
				<div className="utilityBar">
					<div className="search">
						<h1>Entregadores</h1>
						<input type="text" value={searchTerm} onChange={event=>setSearchTerm(event.target.value)}/>
						<button>
							<FiSearch size={28} color="#4287f5"/>
						</button>
					</div>
					<div className="buttons">
						<button className="utilityBtns" onClick={newItem}>Novo</button>
						<button className="utilityBtns" onClick={editItem}>Editar</button>
						<button className="utilityBtns" onClick={()=>{
							printList('Entregadores', [
								{title: 'Código', dataKey: 'id'},
								{title: 'Nome', dataKey: 'nome'},
								{title: 'Telefone', dataKey: 'telefone'},
								{title: 'Whatsapp', dataKey: 'whatsapp'},
								{title: 'Endereço', dataKey: 'endereco'}
							], entregadores.map(entregador=>{
								entregador.whatsapp = entregador.whatsapp.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')
								entregador.telefone = entregador.telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')
								return entregador
							}))
						}}>Imprimir</button>
					</div>
				</div>
				</div>
		</div>
		<div className="tableContainer">
			<table className="admItens">
				<thead>
					<tr>
						<th className="checkbox" style={{display: "none"}}><input type="radio" disabled/></th>
						<th>Código</th>
						<th className="halfFilled">Nome</th>
						<th>Telefone</th>
						<th>Whatsapp</th>
						<th>Endereço</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{entregadores.map(entregador=>{
						return (
							<tr onClick={checkLine} onDoubleClick={editItem} key={entregador.id}>
								<td className="checkbox" style={{display: "none"}}><input type="radio" name="selected" data-id={entregador.id} disabled/></td>
								<td>{entregador.id}</td>
								<td>{entregador.nome}</td>
								<td style={{whiteSpace: 'nowrap'}}>{entregador.telefone.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}</td>
								<td style={{whiteSpace: 'nowrap'}}>{entregador.whatsapp.replace(/^(\d{2})(\d{4,5})(\d{4})$/g, '($1) $2-$3')}</td>
								<td style={{whiteSpace: 'nowrap'}}>{entregador.endereco}</td>
								<td><button className="deleteBtn" onClick={deleteItem}><FiXCircle size={16} color="red"/></button></td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
		</div>
		<div className="modalContent" id="modalEditDeliveryMan">
			<div className="modal">
				<EditItem item={editedItem}/>
				<button className="close" id="btnCloseModal" onClick={closeModal}>
					<FiX size="30" color="#ccc" />
				</button>
			</div>
		</div>
		</div>
		</div>
		</>
		)
}

export default Admin_dash