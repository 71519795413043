import {useEffect, useState} from 'react'
import api from '../../../services/api'
import {CancelToken, isCancel} from 'axios'

export default function useProductList(page, searchTerm, category){
	const [loading, setLoading] = useState(false)
	const [error, setError]		= useState(false)
	const [hasMore, setHasMore]	= useState(false)
	const [items, setItems]		= useState([])

	useEffect(()=>{
        setItems([])
    }, [category, searchTerm])

	useEffect(()=>{
		let cancel
		setLoading(true)
        setError(false)

		api.get(`/products/storeSearch/${page}?searchTerm=${encodeURI(searchTerm)}&categoryId=${category}`, {
			cancelToken: new CancelToken(c=>cancel=c)
		}
		)
		.then(response=>{
			setItems(prevItems=>{
                return [...new Set([...prevItems, ...response.data])]
            })
			setHasMore(response.data.length>0)
            setLoading(false)
		})
		.catch(error=>{
			if (isCancel(error)) return
            setError(error)
        })
		return ()=>cancel()
	}, [searchTerm, category, page])

	return {loading,error,items,hasMore}
}
