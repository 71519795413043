import React, { useState, useEffect } from 'react'
import './style.css'
import api from '../../../services/api'

function AddNewOpeningHour({saveCallback=(()=>{}),editedOH,listOpeningHours}){
	const [loading, setLoading] = useState(false)
	const [day_of_week, setDayOfWeek] = useState(editedOH?editedOH.week_day:'')
	const [time_start, setTimeStart] = useState(editedOH?editedOH.time_start:'')
	const [time_end, setTimeEnd] = useState(editedOH?editedOH.time_end:'')

	const auth = localStorage.getItem('auth')

	useEffect(() => {
		if(editedOH){
			setDayOfWeek(editedOH.week_day)
			setTimeStart(editedOH.time_start)
			setTimeEnd(editedOH.time_end)
		}
		else{
			setDayOfWeek('')
			setTimeStart('')
			setTimeEnd('')
		}
	}, [editedOH]);

	function handleSaveOpeningHour(event){
		event.preventDefault()
		if(time_start<time_end){
			setLoading(true)
			api.post(`/openingDays/`, {
				...(editedOH?{id: editedOH.id}:{}),
				week_day: day_of_week,
				time_start,
				time_end
			}, {
				headers: {
					auth
				}
			}).then(()=>{
				window.location.reload(true)
			})
			.catch((error)=>{
				setLoading(false)
				alert(`Não foi possível salvar item! Verifique os horários! ${error}`)
			})
		}
		else{
			alert('Hora inicial não pode ser maior que hora final!')
		}
	}


	return (
	<>
		{loading?<div className="loaderModal">
			<div className="spinner"></div>
		</div>:<></>}
			<div className="headerCategory">
				<h1>Cadastro de Horário de funcionamento</h1>
			</div>
			<form action="" className="inputOpening" onSubmit={handleSaveOpeningHour}>
				<div className="input-group">
					<select value={day_of_week} required onChange={event=>setDayOfWeek(event.target.value)}>
						<option value="">Selecione um dia da semana...</option>
						<option value={0}>Domingo</option>
						<option value={1}>Segunda</option>
						<option value={2}>Terça</option>
						<option value={3}>Quarta</option>
						<option value={4}>Quinta</option>
						<option value={5}>Sexta</option>
						<option value={6}>Sábado</option>
						<option value={7}>Feriado</option>
					</select>
					<label htmlFor="">Dia da semana</label>
				</div>
				<div className="intervalDate">
					<div className="input-group">
						<input type="time" value={time_start} required onChange={event=>setTimeStart(event.target.value)} id="initialDate"/>
						<label htmlFor="initialDate">Hora Inicial</label>
					</div>
					<div className="input-group">
						<input type="time" value={time_end} required onChange={event=>setTimeEnd(event.target.value)} id="endDate"/>
						<label htmlFor="endDate">Hora Final</label>
					</div>
				</div>
				<div className="footerAddForm" style={{padding: 0, marginTop: 15}}>
					<div></div>
					<button className="save">Salvar</button>
				</div>
			</form>
	</>
	)
}

export default AddNewOpeningHour
